<template>
  <div class="video-new main-padding">
    <CProductSelector :current_user="current_user" v-if="projectStep == 1"></CProductSelector>
    <CVideoNew :current_user="current_user" v-else></CVideoNew>
  </div>
</template>

<script>
import CVideoNew from '../../components/video/new/CVideoNew'
import CProductSelector from '../../components/video/new/CProductSelector'

export default {
  props: ["current_user"],
  computed: {
    projectStep() {
      return this.$store.getters['newProjects/project_step']
    }
  },
  beforeMount() {
    this.$store.commit('newProjects/RESET_NEW_PROJECT')
  },
  components: {
    CProductSelector,
    CVideoNew,
}
}
</script>

<style scoped>
</style>