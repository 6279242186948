$(document).on("turbolinks:load", function() {
});

$(document).ready(function() {
  if(sessionStorage.redirectToInvoices) {
    document.getElementById('myOrdersTab').click();
    sessionStorage.removeItem('redirectToInvoices');
  }
})

$(document).on("click", ".send_ticket_message_button", function(e) {
  e.preventDefault();
  $.ajax($('.new_ticket_message')[0].action, {
    type: 'POST',
    dataType: 'json',
    async: true,
    data: $('.new_ticket_message').serialize(),
    success: function(data) {
      let message = data.message.replaceAll("\r\n", "<br/>");
      $('.message-box-list').append("<div class='user-message'><i class='huge grey user circle icon'></i><p class='user-message-content'>" + message + "</p></div>")
      $('.message-box-list').animate({
        scrollTop: $('.scroll-height').height(),
        'slow': 'slow'
      });
      $('body').toast({
        showIcon: 'check circle outline icon big green',
        message: t(''),
        class : 'ui toast authot',
      });
      $('#ticket_message_message').val('');
    },
    error: function(data) {
      $('body').toast({
        "class": 'error',
        showProgress: 'bottom',
        message: t('layout.shared.errors.error')
      });
    }
  });
});