<template>
  <div class="shop" v-if="products">
    <div class="shop-products">
      <h3>{{ t('order.service.title') }}</h3>
      <p>{{ t('order.service.description') }}</p>
      <div class="shop-products-list">
          <div v-for="(product, i) in topProducts" :key="`top-product${i}`" class="card-container-top">
            <div class="product-card top" v-if="product.position == 1" :class="{ 'disabled': !product.active, 'selected': productSelected == product.id}">
              <div class="product-card-details">
                <img v-if="!product.active" :src="require(`../../assets/img/products/${product.key}-unavailable.svg`)" alt="">
                <img v-else-if="productSelected == product.id" :src="require(`../../assets/img/products/${product.key}-selected.svg`)" alt="">
                <img v-else :src="require(`../../assets/img/products/${product.key}-not-selected.svg`)" alt="">
                <span>{{ product.name }}</span>
              </div>
              <label :for="product.key"></label>
              <input :value="product.id" @change="setProduct($event)" type="radio" name="choosedProduct" :id="product.key" :data-key="product.key">
              <span v-if="!product.active" class="unavailable">{{ t('order.service.product_soon_available') }}</span>
            </div>

            <div class="product-card top" v-if="product.position == 2" :class="{ 'disabled': !product.active, 'selected': productSelected == product.id }">
              <div class="product-card-details">
                <img v-if="!product.active" :src="require(`../../assets/img/products/${product.key}-unavailable.svg`)" alt="">
                <img v-else-if="productSelected == product.id" :src="require(`../../assets/img/products/${product.key}-selected.svg`)" alt="">
                <img v-else :src="require(`../../assets/img/products/${product.key}-not-selected.svg`)" alt="">
                <span>{{ product.name }}</span>
              </div>
              <label :for="product.key"></label>
              <input :value="product.id" @change="setProduct($event)" type="radio" name="choosedProduct" :id="product.key" :data-key="product.key">
              <span v-if="!product.active" class="unavailable">{{ t('order.service.product_soon_available') }}</span>
            </div>
          </div>
      </div>
    </div>
    
    <!-- <CSeparator :text="t('authot_elements.separator.or')"></CSeparator>
    
    <div class="shop-products">
      <p>{{ t('order.service.description_pack') }}</p>
      <div class="shop-products-list">

        <div v-for="(product, i) in otherProducts" :key="`other-product${i}`" class="card-container-bottom">

          <div 
            class="product-card bottom" 
            v-if="product.position == 3" 
            :class="{ 'disabled': !product.active, 'selected': productSelected == product.id}"
          >
            <div class="product-card-details">
              <img v-if="!product.active" :src="require(`../../assets/img/products/${product.key}-unavailable.svg`)" alt="">
                <img v-else-if="productSelected == product.id" :src="require(`../../assets/img/products/${product.key}-selected.svg`)" alt="">
                <img v-else :src="require(`../../assets/img/products/${product.key}-not-selected.svg`)" alt="">
              <span>{{ product.name }}</span>
            </div>
            <label :for="product.key"></label>
            <input :value="product.id" @change="setProduct($event)" type="radio" name="choosedProduct" :id="product.key" :data-key="product.key">
            <span v-if="!product.active" class="unavailable">{{ t('order.service.product_soon_available') }}</span>
          </div>

          <div
            class="product-card bottom" 
            v-if="product.position == 4" 
            :class="{ 'disabled': !product.active, 'selected': productSelected == product.id}"
          >
            <div class="product-card-details">
              <img v-if="!product.active" :src="require(`../../assets/img/products/${product.key}-unavailable.svg`)" alt="">
              <img v-else-if="productSelected == product.id" :src="require(`../../assets/img/products/${product.key}-selected.svg`)" alt="">
              <img v-else :src="require(`../../assets/img/products/${product.key}-not-selected.svg`)" alt="">
              <span>{{ product.name }}</span>
            </div>
            <label :for="product.key"></label>
            <input :value="product.id" @change="setProduct($event)" type="radio" name="choosedProduct" :id="product.key" :data-key="product.key">
            <span v-if="!product.active" class="unavailable">{{ t('order.service.product_soon_available') }}</span>
          </div>

          <div
            class="product-card bottom" 
            v-if="product.position == 5" 
            :class="{ 'disabled': !product.active, 'selected': productSelected == product.id}"
          >
            <div class="product-card-details">
              <img v-if="!product.active" :src="require(`../../assets/img/products/${product.key}-unavailable.svg`)" alt="">
              <img v-else-if="productSelected == product.id" :src="require(`../../assets/img/products/${product.key}-selected.svg`)" alt="">
              <img v-else :src="require(`../../assets/img/products/${product.key}-not-selected.svg`)" alt="">
              <span>{{ product.name }}</span>
            </div>
            <label :for="product.key"></label>
            <input :value="product.id" @change="setProduct($event)" type="radio" name="choosedProduct" :id="product.key" :data-key="product.key">
            <span v-if="!product.active" class="unavailable">{{ t('order.service.product_soon_available') }}</span>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import CSeparator from '../authotElements/CSeparator.vue'

export default {
  data() {
    return {
      productSelected: null,
      unavailable: true
    }
  },
  computed: {
    topProducts() {
      return this.$store.getters['products/top_products']
    },
    otherProducts() {
      return this.$store.getters['products/other_products']
    },
    products() {
      return this.$store.getters['products/studio_products']
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    setProduct(e) {
      const product = this.products.find(product => product.id == e.target.value);
      this.productSelected = product.id;
      this.$store.commit('order/SET_PRODUCT', product);
      product.key.includes('proofreading') ? this.$store.commit('order/SET_QUANTITY', 5) : this.$store.commit('order/SET_QUANTITY', 50);
      this.getOrderAmount();
    },
    getOrderAmount() {
      this.$store.dispatch('order/getAmount');
    }
  },
  created() {
    this.$store.dispatch('products/getAllProducts');
  },
  components: {
    CSeparator
  }
}
</script>