import axiosClient from "../axios-config";

export default {
  postProject(videoData) {
    return axiosClient.post('/video', { video: videoData });
  },
  getProjectState(videoId) {
    return axiosClient.get('/video/' + videoId);
  }
}
