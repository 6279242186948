<template>
  <div class="main-padding ">
    <CTicketForm :current_user="current_user"></CTicketForm>
  </div>
</template>

<script>
import CTicketForm from '../../components/ticket/CTicketForm'

export default {
  props: [
    "current_user"
  ],
  components: {
    CTicketForm,
  }
}
</script>