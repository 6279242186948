<template>
  <div>
    <vue-good-table
      ref="videoTable"
      mode="remote"
      :isLoading.sync="isLoading"
      :columns="columns"
      :rows="videos"
      :select-options="{ 
        enabled: true, 
        selectOnCheckboxOnly: true, 
        selectionText: t('project.projects.projects_tab.projects_selected'),
        selectionInfoClass: 'head-choices',
      }"
      :pagination-options="{ 
        enabled: true, 
        perPage: 5,
      }"
      styleClass="vgt-table videos"
      @update:isLoading="initDomElements()"
      @on-selected-rows-change="initDomElements(arguments[0])"
      @on-page-change="initDomElements()"
      @on-per-page-change="initDomElements()"
    >
      <div slot="selected-row-actions">
        <div class="ui mini selection video actions dropdown">
          <input type="hidden" name="video-actions">
          <i class="dropdown icon"></i>
          <div class="text"></div>
          <div class="menu">
            <div class="item" data-value="export">{{ t('layout.shared.actions.export') }}</div>
            <div class="item" data-value="inscrust">{{ t('layout.shared.actions.incrust') }}</div>
            <div class="item" data-value="delete">{{ t('layout.shared.actions.delete') }}</div>
          </div>
        </div>
        <button class="mini ui button selected actions" @click="manageVideosSelected()">{{ t('layout.shared.actions.confirm') }}</button>
        <div class="ui modal delete tiny" id="modal-delete-videos-">
          <div class="header">
            <i class="large trash alternate icon"></i>
            {{ t('authot_elements.modal.delete_project_title') }}
          </div>
          <div class="content">
            <p v-html="t('authot_elements.modal.delete_project_description')"></p>
          </div>
          <div class="actions">
            <button class="dark_grey ui button" @click="closeDeleteVideosModal()">{{ t('layout.shared.actions.cancel') }}</button>
            <button class="green authot ui button" @click="deleteVideosSelected()">{{ t('layout.shared.actions.confirm') }}</button>
          </div>
        </div>
      </div>
      <template slot="loadingContent">
        <span class="vgt-table-loader">{{ t('layout.shared.states.loading') }}</span>
      </template>
      <template slot="table-row" slot-scope="props">
        <span class="span-video-title" v-if="props.column.field == 'title'">
          <a 
            target="_blank" :href="'/video/' + props.row.id + '/subtitle/' + props.row.subtitles[0].id + '/edit'"
            :class="checkStateVideo(props.row)"
            :title="props.row.title"
          >{{ truncateString(props.row.title) }}</a>
          <CTooltip :isDynamic="true" :text="t('layout.shared.actions.copy')" :textDynamic="t('layout.shared.actions.copied')">
            <i class="clipboard outline link icon" @click="copyTitle(props.row.title)"></i>
          </CTooltip>
        </span>
        <span class="span-language" v-else-if="props.column.field == 'flag_iso'">
          <img
            class="mini round-flag"
            :src="require(`../../../assets/img/flags/${props.row.flag_iso}.png`)"
          >
          <br>
          <p style="margin-right:5%;">{{ props.row.flag_iso_639 }}</p>
        </span>
        <span v-else-if="props.column.field == 'subtitles'">
          <div class="span-dropdown-subtitles">
            <div class="subtitles-list">
              <div class="subtitles-list-header" @click="displaySubtitleDropdown(props.row.id)">
                <div :class="`subtitles-list-header-container ${props.row.id}`">
                  <!-- Se renseigne dynamiquement -->
                </div>
                <i class="small chevron down icon"></i>
              </div>

              <div :class="'subtitles-list-items ' + props.row.id">
                <div 
                  class="subtitle-option"
                  :id="'subtitle-option-' + props.row.id + s.id"
                  v-for="(s, i) in props.row.subtitles"
                  v-bind:key="i"
                  :data-value="s.id"
                  :data-state="s.state"
                  :data-authot-status="s.authot_status"
                  :data-studio-status="s.status"
                  >
                  <div class="subtitle-option-container" @click="selectSubtitleItem(props.row.id, s.id, props.row.state)">
                    <div class="subtitle-option-container-left" :title="s.title" :id="'subtitle-infos-' + props.row.id + s.id">
                      <img class="mini round-flag" :src="require(`../../../assets/img/flags/${s.flag_iso}.png`)">
                      {{ truncateString(s.title) }}
                    </div>
                    <div class="ui checkbox small-border">
                      <input type="checkbox" :checked="props.row.subtitles[0].id == s.id" :id="'subtitle-checkbox-' + props.row.id + s.id">
                      <label></label>
                    </div>
                  </div>
                  <CTooltip :isDynamic="true" :text="t('layout.shared.actions.copy')" :textDynamic="t('layout.shared.actions.copied')">
                    <i class="clipboard outline link icon" @click="copyTitle(s.title)"></i>
                  </CTooltip>
                </div>
              </div>
            </div>

            <div class="ui dropdown icon subtitles settings dropdown">
              <i class="ellipsis vertical icon"></i>
              <div class="menu">
                <div class="item" @click="openNewVideoProjectModal(props.row.subtitles)">
                  <i class="file video icon"></i>
                  {{ t('project.projects.projects_tab.add_video') }}
                </div>
                <div class="item" @click="createSubtitle(props.row.id)">
                  <i class="closed captioning icon"></i>
                  {{ t('project.projects.projects_tab.add_subtitles') }}
                </div>
                <!-- v-if="props.current_user != 2"    #### Ajouter sur un bouton Valider ( pour rendre ça visible que a certaines personnes ! )-->
                <div class="item" @click="launchSubtitleAction(props.row.id, 'export')">
                  <i class="download icon"></i>
                  {{ t('layout.shared.actions.export') }}
                </div>
                <div class="item" @click="launchSubtitleAction(props.row.id, 'translate')">
                  <i class="globe africa icon"></i>
                  {{ t('layout.shared.actions.translate') }}
                </div>
                <div class="item" @click="checkIfDeletePossible(props.row.id, props.row.subtitles.length)" :class="props.row.subtitles.length == 1 ? 'disabled' : ''">
                  <i class="trash red alternate icon"></i>
                  <span class="ui red text">
                    {{ t('layout.shared.actions.delete') }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="ui modal export-subtitles tiny" :id="'formats-modal' + props.row.id">
            <i class="close icon" @click="closeMultiExportModal(props.row.id)"></i>
            <div class="header">
              <i class="big download file icon"></i>
              <h3>{{ t('authot_elements.modal.export_subtitles_title') }}</h3>
            </div>
            <div class="content">
              <div class="export-subtitles-form">
                <h4>{{ t('authot_elements.modal.export_subtitles_description') }}</h4>
                <div class="formats-list">
                  <div>
                    <div class="ui checkbox format">
                      <input type="checkbox" id="ass-export" name="format" value="ass">
                      <label for="ass-export">{{ t('authot_elements.modal.export_label_ass') }}</label>
                    </div>
                    <div class="ui checkbox format">
                      <input type="checkbox" id="srt-export" name="format" value="srt">
                      <label for="srt-export">{{ t('authot_elements.modal.export_label_srt') }}</label>
                    </div>
                    <div class="ui checkbox format">
                      <input type="checkbox" id="txt-export" name="format" value="txt">
                      <label for="txt-export">{{ t('authot_elements.modal.export_label_txt') }}</label>
                    </div>
                    <div class="ui checkbox format">
                      <input type="checkbox" id="ttml-export" name="format" value="ttml">
                      <label for="ttml-export">{{ t('authot_elements.modal.export_label_ttml') }}</label>
                    </div>
                    <div class="ui checkbox format">
                      <input type="checkbox" id="avid-export" name="format" value="avid">
                      <label for="avid-export">{{ t('authot_elements.modal.export_label_avid') }}</label>
                    </div>
                  </div>
                  <div>
                    <div class="ui checkbox format">
                      <input type="checkbox" id="vtt-export" name="format" value="vtt">
                      <label for="vtt-export">{{ t('authot_elements.modal.export_label_vtt') }}</label>
                    </div>
                    <div class="ui checkbox format">
                      <input type="checkbox" id="webvtt-export" name="format" value="webvtt">
                      <label for="webvtt-export">{{ t('authot_elements.modal.export_label_webvtt') }}</label>
                    </div>
                    <div class="ui checkbox format">
                      <input type="checkbox" id="vtt-vod-export" name="format" value="vtt_vod">
                      <label for="vtt-vod-export">{{ t('authot_elements.modal.export_label_vtt-vod') }}</label>
                    </div>
                    <div class="ui checkbox format">
                      <input type="checkbox" id="docx-export" name="format" value="docx">
                      <label for="docx-export">{{ t('authot_elements.modal.export_label_docx') }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <button @click="getMultiExport(props.row.id)" class="authot ui button">{{ t('layout.shared.actions.export') }}</button>
            </div>
          </div>

          <div class="ui modal delete tiny" :id="'modal-delete-subtitle-' + props.row.id">
            <div class="header">
              <i class="large trash alternate icon"></i>
              {{ t('authot_elements.modal.delete_subtitle_title') }}
            </div>
            <div class="content">
              <p v-html="t('authot_elements.modal.delete_subtitle_description')"></p>
            </div>
            <div class="actions">
              <button class="dark_grey ui button" @click="closeDeleteSubtitleModal(props.row.id)">{{ t('layout.shared.actions.cancel') }}</button>
              <button class="green authot ui button" @click="launchSubtitleAction(props.row.id, 'delete')">{{ t('layout.shared.actions.confirm') }}</button>
            </div>
          </div>
        </span>
        <span v-else-if="props.column.field == 'project'" class="span-project" :text="props.row.project">
          <p v-html="props.row.project"></p>
          <CTooltip :isDynamic="true" :text="t('layout.shared.actions.copy')" :textDynamic="t('layout.shared.actions.copied')">
            <i class="clipboard outline link icon" @click="copyTitle(props.row.project)"></i>
          </CTooltip>
        </span>
        <span v-else-if="props.column.field == 'state'" :class="`span-state-${props.row.id}`">
          <span :class="`span-state-child ${props.row.id} state-tooltip-${props.row.id}`">
            <CTooltip class="" :isDynamic="false" :text="t('project.projects.tooltip_messages.select_subtitle_state')">
              <i class="icon link question circle outline"></i>
            </CTooltip>
          </span>
          <span :class="`span-state-child ${props.row.id} state-done-${props.row.id}`">
            <i class="green tiny circle icon"></i>
            <span class="content"></span>
          </span>
          <span :class="`span-state-child ${props.row.id} state-progress-${props.row.id}`">
            <i class="orange tiny circle icon"></i>
            <span class="content"></span>
          </span>
          <span :class="`span-state-child ${props.row.id} state-error-${props.row.id}`">
            <i class="red tiny circle icon"></i>
            <span class="content"></span>
          </span>
        </span>
        <span v-else-if="props.column.field == 'actions'">
          <i :class="`edit green link outline icon edit-icon ${props.row.id}`" @click="redirectToEditor(props.row.id)"></i>
          <CTooltip :class="`forbidden edit-icon ${props.row.id}`" :isDynamic="false" :text="t('project.projects.tooltip_messages.subtitle_pending')">
            <i class="icon link question circle outline"></i>
          </CTooltip>
          <CTooltip :class="`not-selected edit-icon ${props.row.id}`" :isDynamic="false" :text="t('project.projects.tooltip_messages.select_subtitle')">
            <i class="icon link question circle outline"></i>
          </CTooltip>
          <i class="cog orange link icon" @click="openEditModal(props.row)"></i>
          <i class="trash red link alternate icon" @click="openDeleteVideoModal(props.row.id)"></i>
          
          <div class="ui modal delete tiny" :id="'modal-delete-video-' + props.row.id">
            <div class="header">
              <i class="large trash alternate icon"></i>
              {{ t('authot_elements.modal.delete_project_title') }}
            </div>
            <div class="content">
              <p v-html="t('authot_elements.modal.delete_project_description')"></p>
            </div>
            <div class="actions">
              <button class="dark_grey ui button" @click="closeDeleteVideoModal(props.row.id)">{{ t('layout.shared.actions.cancel') }}</button>
              <button class="green authot ui button" @click="deleteVideo(props.row.id)">{{ t('layout.shared.actions.confirm') }}</button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <!-- <CTablePagination
          :total="props.total"
          :page-changed="props.pageChanged"
          :per-page-changed="props.perPageChanged"
          dropdownId="style-pagination"
        >
        </CTablePagination> -->
        <CVideoPagination
          :total="total_videos"
          :page-changed="props.pageChanged"
          :per-page-changed="props.perPageChanged"
          dropdownId="video-pagination"
        >
        </CVideoPagination>
      </template>
      <div slot="emptystate" class="empty-data">
        {{ t('project.projects.projects_tab.no_project') }}
      </div>
    </vue-good-table>
    
    <div class="ui modal video-index-modal tiny" id="new-subtitle-modal">
      <i class="close icon" @click="emptyNewSubtitle()"></i>
      <div class="header">
        <i class="import file icon big orange"></i>
        <h3>{{ t('project.new_project.studio_manual.btn_add_sub') }}</h3>
      </div>
      <form v-on:submit.prevent="uploadNewSubtitle()" class="content ui form">
        <CUploader
          ref="uploader-new-subtitle"
          id="uploader-new-subtitle"
          dropId="dropSub-new-subtitle"
          browseId="browseSub-new-subtitle"
          :fileAccepted="['srt', 'ass', 'vtt', 'webvtt']"
          @emitFilename="newSubtitle.title = arguments[0]"
          @emitFileDestroy="newSubtitle.title = null"
          @emitFilepath="postNewSubtitle(arguments[0])"
        >
        </CUploader>
        <div class="selector-languages">
          <div class="required">
            <label class="form light label">{{ t('project.new_project.new_video.language_description') }}</label>
            <span>*</span>
          </div>
          <div class="languages-grid" :class="{'opened': languageGridOpened}">
            <div v-for="language in languages" :key="language.id" class="languages-grid-item" id="languages-grid-new-subtitle">
              <div class="languages-grid-item-container" @click="getChoosedLanguage(language.id, language.flag_iso)">
                <div 
                  class="languages-grid-item-container-top languages-grid-new-subtitle"
                  :id="language.flag_iso + '-new-subtitle'" 
                >
                  <img
                    class="languages-grid-item-container-top-flag languages-grid-new-subtitle"
                    :src="require(`../../../assets/img/flags/${language.flag_iso}.png`)"
                  >
                </div>
                <p class="languages-grid-item-container-name">{{ language.name }}</p>
              </div>
            </div>
          </div>
          <div class="language-show-action" v-show="!languageGridOpened" @click="languageGridOpened = !languageGridOpened">
            <i class="plus square outline orange large icon"></i>
            <p>{{ t('project.new_project.new_video.see_more') }}</p>
          </div>
          <div class="language-show-action" v-show="languageGridOpened" @click="languageGridOpened = !languageGridOpened">
            <i class="minus square outline orange large icon"></i>
            <p>{{ t('project.new_project.new_video.see_less') }}</p>
          </div>
        </div>
        <div class="required">
          <label class="form light label">{{ t('project.projects.projects_tab.new_subtitle_title') }}</label>
          <span>*</span>
        </div>
        <input v-model="newSubtitle.title" :placeholder="t('project.projects.projects_tab.new_subtitle_title_placeholder')">
        <input type="submit" class="ui authot green button" :value="t('layout.shared.actions.send')">
      </form>
    </div>
    
    <CEditModal
      :project="projectToModify"
    ></CEditModal>
    <CMultiIncrustModal>
    </CMultiIncrustModal>
    <CTranslateModal
      ref="translateModal"
    >
    </CTranslateModal>
    <CNewVideoProjectModal 
      ref="newVideoProjectModal"
    ></CNewVideoProjectModal>
  </div>
</template>

<script>
import AxiosVideoService from "../../../services/video/video-index"
// import AxiosLanguagesService from '../../../services/languages/languages';
import CUploader from "../../uploader/CUploader"
import CVideoPagination from "./CVideoPagination"
import CEditModal from "./CEditModal"
import CMultiIncrustModal from './CMultiIncrustModal.vue'
import CTranslateModal from '../modals/CTranslateModal.vue'
import CTooltip from "../../authotElements/CTooltip.vue"
import CNewVideoProjectModal from "../modals/CNewVideoProjectModal.vue"
import { VueGoodTable } from "vue-good-table"
import { EventBus } from "../../../packs/application"
import { displayToast } from '../../../utils/functions'

export default {
  props: ["videos", "total_videos", "current_user"],
  data() {
    return {
      isLoading: false,
      filters: false,
      selectedVideo: null,
      selectedFormats: [],
      selectedSubtitles: [],
      // languages: [],
      languageGridOpened: false,
      newSubtitle: {
        title: null,
        original_file_path: null,
        language_id: null
      },
      projectToModify: null,
      maxTitleLength: 25,
      copyActive: false,
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.t('project.projects.projects_tab.label_date'),
          field: 'created_at',
          sortable: false
        },
        {
          label: this.t('project.projects.projects_tab.label_video'),
          field: 'title',
          sortable: false
        },
        {
          label: this.t('project.projects.projects_tab.label_languages'),
          field: 'flag_iso',
          sortable: false
        },
        {
          label: this.t('project.projects.projects_tab.label_subtitles'),
          field: 'subtitles',
          sortable: false
        },
        {
          label: this.t('project.projects.projects_tab.label_projects'),
          field: 'project',
          sortable: false
        },
        {
          label: this.t('project.projects.projects_tab.label_state'),
          field: 'state',
          sortable: false,
        },
        {
          label: this.t('project.projects.projects_tab.label_actions'),
          field: 'actions',
          sortable: false,
        },
      ]
    },
    t() {
      return this.$store.getters['locales/t']
    },
    languages() {
      return this.$store.getters['projects/languages']
    }
  },
  methods: {
    // Fomantic - Style
    initDomElements(projectsSelected) {
      if(projectsSelected) projectsSelected.selectedRows.length == 0 || projectsSelected == undefined ? this.removeVgtTableClass() : this.addVgtTableClass();
      setTimeout(() => {
        $('tbody th.vgt-checkbox-col').addClass('ui checkbox');
        const checkboxes = Array.from($('tbody th.vgt-checkbox-col'));
        checkboxes.forEach(checkbox => {
          if(!$(checkbox).find('label').length) $(checkbox).append('<label></label>')
        });

        $('option').mousedown(e => {
          e.preventDefault();
        })
        $('.subtitles.settings.dropdown').dropdown();
        $('.question.tooltip.conversion').popup({
          content: this.t('project.projects.projects_tab.project_encoding'),
        });
        $('.modal.delete').modal({
          inverted: true,
          closable: false
        });
        $('.modal.video-index-modal').modal({
          inverted: true,
          closable: false,
          allowMultiple: false
        });
        $('.video.actions.dropdown').dropdown('set selected', 'export');
        this.initSubtitlesDropdown();
      }, 1);
    },
    initSubtitlesDropdown() {
      const subtitlesSelected = Array.from(document.getElementsByClassName('subtitle-option selected'))
      const allSubtitles = Array.from(document.getElementsByClassName('subtitles-list-items'))
      subtitlesSelected.map(subtitle => {
        $(subtitle).children('.subtitle-option-container')[0].click();
      });
      allSubtitles.map(subtitle => {
        $(subtitle).children('.subtitle-option')[0].children[0].click()
      })
    },
    initMainCheckbox() {
      $('thead th.vgt-checkbox-col').addClass('ui checkbox');
      $('thead th.vgt-checkbox-col').append('<label></label>');
    },
    addVgtTableClass() {
      $('.vgt-table.videos').addClass("header-choices-active");
    },
    removeVgtTableClass() {
      $('.vgt-table.videos').removeClass("header-choices-active");
    },

    //Languages
    // getLanguages() {
    //   AxiosLanguagesService.getLanguages()
    //   .then(response => {
    //     response.data.languages.map(language => { this.languages.push(language) });
    //   }).catch(error => {
    //     console.log(error);
    //   });
    // },

    // Projects
    openEditModal(project) {
      this.projectToModify = project;
      EventBus.$emit('any-modal-opened');
      $('#edit-project-modal').modal('show');
      EventBus.$emit('open-edit-modal');
    },

    // Videos
    copyTitle(title) {
      let copyContent = title;
      $('<input>').val(copyContent).appendTo('body').select();
      document.execCommand('copy');
      this.copyActive = true;
    },
    truncateString(stringData) {
      return stringData.length > this.maxTitleLength ? 
      stringData.substr(0, 10) + '...' + stringData.substr(stringData.length-10, stringData.length) : 
      stringData;
    },
    manageVideosSelected() {
      const actionSelected = $('.video.actions.dropdown').dropdown('get value');
      actionSelected == "export" ? 
        this.exportVideosSelected() : 
        actionSelected == "delete" ? 
        this.openDeleteVideosModal() :
        this.manageMultiIncrustVideosSelected();
    },
    openDeleteVideosModal() {
      EventBus.$emit('any-modal-opened');
      $('#modal-delete-videos-').modal('show');
    },
    closeDeleteVideosModal() {
      EventBus.$emit('any-modal-closed');
      $('#modal-delete-videos-').modal('hide');
    },
    manageMultiIncrustVideosSelected() {
      this.$store.commit('projects/UPDATE_PROJECTS_SELECTED', this.$refs['videoTable'].selectedRows);
      this.openMultiIncrustModal();
    },
    openMultiIncrustModal() {
      EventBus.$emit('any-modal-opened');
      this.$store.commit('projects/PRESELECT_SUBTITLES_TO_INCRUST');
      $('#multi-incrust-modal').modal('show');
    },
    exportVideosSelected() {
      this.selectedSubtitles = [];
      this.$refs['videoTable'].selectedRows.forEach(row => {
        row.subtitles.forEach(subtitle => {
          this.selectedSubtitles.push(subtitle.id);
        })
      });
      this.openMultiExportModal(this.$refs['videoTable'].selectedRows[0].id);
    },
    checkStateVideo(row) {
      let visible = true
      if(row.state != 10) {
        visible = false
      }
      row.subtitles.forEach(subtitle => {
        if(subtitle.state != 10 && !(subtitle.state > 100)) visible = false
      })
      if(visible == false) {
        return "link disabled"
      } else {
        return "authot link"
      }
    },
    checkStateActions(row) {
      let visible = true
      if(row.state != 10) {
        visible = false
      }
      row.subtitles.forEach(subtitle => {
        if(subtitle.state != 10) {
          visible = false
        }
      })
      if(visible == false) {
        return "display: none;"
      } else {
        return ""
      }
    },
    openDeleteVideoModal(id) {
      EventBus.$emit('any-modal-opened');
      $('#modal-delete-video-' + id).modal('show');
    },
    closeDeleteVideoModal(id) {
      EventBus.$emit('any-modal-closed');
      $('#modal-delete-video-' + id).modal('hide');
    },
    deleteVideo(id) {
      AxiosVideoService.deleteVideo(id)
      .then(response => {
        $(`#modal-delete-video-${id}`).modal('hide');
        this.$refs.videoTable.unselectAllInternal();
        EventBus.$emit('update-projects');
        EventBus.$emit('any-modal-closed');
        displayToast('success', this.t('video.actions.delete'));
      })
    },
    convertArrayParams(aName, aValues) {
      let i, len, param, result;
      result = '';
      for (i = 0, len = aValues.length; i < len; i++) {
        param = aValues[i];
        result = result + aName + '[]=' + param + '&';
      }
      return result.slice(0, -1);
    },
    async deleteVideosSelected() {
      const videosSelected = this.$refs.videoTable.selectedRows;
      this.removeVideo(videosSelected);
    },
    async removeVideo(videos) {
      $('#modal-delete-videos-').modal('hide');
      await Promise.all(videos.map(async video => {
        await AxiosVideoService.deleteVideo(video.id)
      }));
      this.$refs.videoTable.unselectAllInternal();
      EventBus.$emit('update-projects');
      EventBus.$emit('any-modal-closed');
      displayToast('success', this.t('video.actions.delete'));
    },
    openNewVideoProjectModal(subtitles) {
      this.$store.commit('projects/SET_SUBTITLES_FOR_NEW_VIDEO', subtitles)
      this.$refs["newVideoProjectModal"].openModal();
    },

    // Multi export
    openMultiExportModal(videoId) {
      EventBus.$emit('any-modal-opened');
      $('#formats-modal' + videoId).modal({
        inverted: true,
        closable: false
      });
      $('#formats-modal' + videoId).modal('show');
    },
    async getMultiExport(videoId) {
      let formats = document.querySelectorAll('input[name="format"]:checked');
      if(formats.length == 0) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('authot_elements.modal.export_error'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      } else {
        await formats.forEach(format => {
          this.selectedFormats.push(format.value);
        })
        window.location.href = '/video/' + videoId + '/subtitle/multi_export.zip?' + this.convertArrayParams('subtitles_ids', this.selectedSubtitles) + '&' + this.convertArrayParams('formats_export', this.selectedFormats);
        this.closeMultiExportModal(videoId)
      }
    },
    closeMultiExportModal(videoId) {
      EventBus.$emit('any-modal-closed');
      $('#formats-modal' + videoId).modal('hide');
      this.selectedSubtitles = [];
      this.selectedFormats = [];
      let formats = document.querySelectorAll('input[name="format"]');
      formats.forEach(format => {
        format.checked = false
      })
    },

    // Subtitles
    displaySubtitleDropdown(videoId) {
      const element = $(`.subtitles-list-items.${videoId}`);
      if(element.hasClass('visible')) {
        element.removeClass('visible')
      } else if($('.subtitles-list-items.visible').length != 0) {
        const dropdownElement = $('.subtitles-list-items.visible')[0];
        $(dropdownElement).removeClass('visible');
        element.addClass('visible');
      } else {
        element.addClass('visible')
      };
    },
    selectSubtitleItem(videoId, subtitleId, videoState) {
      const subtitleOption = document.getElementById(`subtitle-option-${videoId}${subtitleId}`);
      subtitleOption.className == "subtitle-option" ? 
        subtitleOption.className = "subtitle-option selected" : 
        subtitleOption.className = "subtitle-option";
      subtitleOption.className == "subtitle-option" ? 
        document.getElementById(`subtitle-checkbox-${videoId}${subtitleId}`).checked = false : 
        document.getElementById(`subtitle-checkbox-${videoId}${subtitleId}`).checked = true;
      this.displaySelectedSub(videoId, videoState);
    },
    displaySelectedSub(videoId, videoState) {
      const selectedSubtitle = $(`.subtitles-list-items.${videoId}`).children(".subtitle-option.selected")[0];
      const displayContainer = $(`.subtitles-list-header-container.${videoId}`);
      this.getSubtitleSelectedState(videoId, selectedSubtitle, videoState);
      return selectedSubtitle ? 
      displayContainer.html(selectedSubtitle.children[0].children[0].innerHTML) :
      displayContainer.html(this.t('authot_elements.dropdown.no_file'));
    },
    checkIfDeletePossible(videoId, subtitlesLength) {
      const filteredSubtitles = Array.from($(`.subtitles-list-items.${videoId}`)[0].children).filter(c => c.className == "subtitle-option selected");
      const subtitlesSelected = filteredSubtitles.map(s => s.dataset.value);
      if(subtitlesLength == subtitlesSelected.length) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('project.projects.projects_tab.delete_subtitles_error'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      } else if(subtitlesSelected.length == 0) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('authot_elements.modal.select_subtitle_error'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      } else {
        this.openDeleteSubtitleModal(videoId);
      }
    },
    openDeleteSubtitleModal(id) {
      EventBus.$emit('any-modal-opened');
      $('#modal-delete-subtitle-' + id).modal('show');
    },
    closeDeleteSubtitleModal(id) {
      EventBus.$emit('any-modal-closed');
      $('#modal-delete-subtitle-' + id).modal('hide');
    },
    getSubtitleSelectedState(videoId, subtitle, videoState) {
      $(`.span-state-child.${videoId}.active`).removeClass('active');
      $(`.edit-icon.${videoId}.active`).removeClass('active');
      if(subtitle) {
        this.manageSubtitleState(videoId, subtitle, videoState);
      } else {
        $(`.state-tooltip-${videoId}`).addClass('active');
        $(`.not-selected.edit-icon.${videoId}`).addClass('active');
      }
    },
    manageSubtitleState(videoId, subtitle, videoState) {
      switch (true) {
        case (+subtitle.dataset.state === 10 || +subtitle.dataset.state > 100) && videoState === 10 :
          this.displaySubtitleState(videoId, subtitle, 'done')
          break;
        case (+subtitle.dataset.state >= 0 && +subtitle.dataset.state < 10) || (+subtitle.dataset.state > 10 && +subtitle.dataset.state < 100)  || (videoState >= 0 && videoState < 10) :
          this.displaySubtitleState(videoId, subtitle, 'progress')
          break;
        case +subtitle.dataset.state < 0 || videoState < 0 :
          this.displaySubtitleState(videoId, subtitle, 'error')
          break;
      } 
    },
    displaySubtitleState(videoId, subtitle, state) {
      state == "done" ?
        $(`.edit.edit-icon.${videoId}`).addClass('active') :
        $(`.forbidden.edit-icon.${videoId}`).addClass('active');
      if(subtitle.dataset.authotStatus) {
        $(`.state-${state}-${videoId}`).addClass('active');
        subtitle.dataset.authotStatus.includes('.') ?
        $(`.state-${state}-${videoId}`).children('.content').html(this.t(`${subtitle.dataset.authotStatus}`)) :
        $(`.state-${state}-${videoId}`).children('.content').html(subtitle.dataset.authotStatus)
      } else {
        $(`.state-${state}-${videoId}`).addClass('active');
        $(`.state-${state}-${videoId}`).children('.content').html(this.t(`${subtitle.dataset.studioStatus}`));
      }
    },
    openTranslateModal(videoId, subtitleId) {
      this.$store.commit('projects/UPDATE_SUBTITLE_TO_TRANSLATE', { video_id: videoId, subtitle_id: subtitleId })
      this.$refs["translateModal"].openTranslateModal();
    },
    async launchSubtitleAction(videoId, action) {
      const filteredSubtitles = Array.from($(`.subtitles-list-items.${videoId}`)[0].children).filter(c => c.className == "subtitle-option selected");
      const subtitlesSelected = filteredSubtitles.map(s => s.dataset.value);
      if(action === 'export') {
        this.selectedSubtitles = subtitlesSelected;
        subtitlesSelected.length == 0 ?
          displayToast('error', this.t('authot_elements.modal.select_subtitle_error')) :
          this.openMultiExportModal(videoId)
      } else if(action === 'translate') {
        subtitlesSelected.length == 0 || subtitlesSelected.length > 1 ?
          displayToast('error', this.t('project.translation.translation_error_filenumber')) :
          this.openTranslateModal(videoId, subtitlesSelected[0]);
      } else if(action === 'delete') {
        this.removeSubtitle(videoId, subtitlesSelected);
      }
    },
    redirectToEditor(videoId) {
      const filteredSubtitles = Array.from($(`.subtitles-list-items.${videoId}`)[0].children).filter(c => c.className == "subtitle-option selected");
      if(filteredSubtitles.length != 0) {
        const firstSubtitleSelected = filteredSubtitles[0].dataset.value;
        window.open(`/video/${videoId}/subtitle/${firstSubtitleSelected}/edit`, '_blank').focus();
      } else {
        // Faire une fonction générique pour les toasts
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('authot_elements.modal.select_subtitle_error'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      }
    },
    createSubtitle(videoId) {
      this.selectedVideo = videoId;
      EventBus.$emit('any-modal-opened');
      $('#new-subtitle-modal').modal('show');
      $('.ui.dropdown.new-subtitle.languages').dropdown();
    },
    closeNewSubtitleModal() {
      this.emptyNewSubtitle();
      EventBus.$emit('any-modal-closed');
      $('#new-subtitle-modal').modal('hide');
    },
    getChoosedLanguage(languageId, languageFlag) {
      this.newSubtitle.language_id = languageId;
      if($('.active.languages-grid-new-subtitle').length != 0) {
        $('.active.languages-grid-new-subtitle')[0].nextElementSibling.className = "languages-grid-item-container-name";
      }
      $('.active.languages-grid-new-subtitle').removeClass("active");
      $('#' + languageFlag + '-new-subtitle').addClass("active");
      $('.active.languages-grid-new-subtitle')[0].nextElementSibling.className = "languages-grid-item-container-name active";
    },
    getLanguageId(e) {
      this.newSubtitle.language_id = e.target.value;
    },
    checkEmptyUploader(element) {
      if(element.documents.length == 0) {
        return true
      }
    },
    async uploadNewSubtitle() {
      let uploader = this.$refs["uploader-new-subtitle"]
      if(await this.checkEmptyUploader(uploader)) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('project.projects.projects_tab.new_subtitle_error_file'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      } else if(this.newSubtitle.language_id == null || this.newSubtitle.language_id == "") {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('project.projects.projects_tab.new_subtitle_error_language'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      } else if(this.newSubtitle.title == null || this.newSubtitle.title == "") {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('project.projects.projects_tab.new_subtitle_error_title'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      } else {
        uploader.upload();
      };
    },
    postNewSubtitle(filePath) {
      this.newSubtitle.original_file_path = filePath
      AxiosVideoService.addSubtitle(this.selectedVideo, this.newSubtitle)
      .then(response => {
        this.closeNewSubtitleModal()
        EventBus.$emit('update-projects')
      }).catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class : 'ui toast authot',
          className: {
            toast: 'ui toast authot'
          }
        });
      })
    },
    emptyNewSubtitle(i) {
      EventBus.$emit('any-modal-closed');
      this.newSubtitle.title = null;
      this.newSubtitle.original_file_path = null;
      this.newSubtitle.language_id = null;
      let uploader = this.$refs["uploader-new-subtitle"];
      if(uploader.documents[0]) {
        let file = uploader.documents[0].uniqueIdentifier;
        let fileToRemove = uploader.r.getFromUniqueIdentifier(file);
        uploader.r.removeFile(fileToRemove);
        uploader.documents = [];
      }
      if($('.active.languages-grid-new-subtitle').length != 0) {
        $('.active.languages-grid-new-subtitle')[0].nextElementSibling.className = "languages-grid-item-container-name";
      }
      $('.active.languages-grid-new-subtitle').removeClass("active");
      this.languageGridOpened = false;
      this.selectedVideo = null;
    },
    async removeSubtitle(videoId, subtitles) {
      await Promise.all(subtitles.map(async subId => {
        await AxiosVideoService.deleteSubtitle(videoId, subId);
      }))
      window.location.reload();
    }
  },
  mounted() {
    this.initDomElements();
    this.initMainCheckbox();
    EventBus.$on('filter-changed', () => {
      this.initDomElements();
    });
    this.$store.dispatch('projects/getLanguages');
    this.$store.dispatch('projects/getTranslationLanguages');
  },
  components: {
    VueGoodTable,
    CUploader,
    CVideoPagination,
    CEditModal,
    CTooltip,
    CMultiIncrustModal,
    CTranslateModal,
    CNewVideoProjectModal
  }
}
</script>

<style scoped>
</style>