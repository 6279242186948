<template>
    <div class="ui vertical menu">
      <h4>{{ t('layout.footer.choose_language') }}</h4>
      <div class="ui selection locale footer dropdown tiny">
        <input type="hidden" name="locale">
        <div class="default text"></div>
        <i class="dropdown icon"></i>
        <div class="menu">
          <div
            class="item"
            v-for="locale in locales" 
            :key="`locale-${locale}`"
            :data-value="locale"
          >
            <img 
              class="mini round-flag"
              :src="require(`../../assets/img/locales/${locale}_locale_flag.png`)"
            >
            {{ t(`locale_name_${locale}`) }}
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
    locales() {
      return this.$store.getters['locales/available_locales']
    },
    locale() {
      return this.$store.getters['locales/locale']
    }
  },
  mounted() {
    $('.ui.selection.locale.footer.dropdown.tiny').dropdown('set selected', this.locale);
    $('.ui.selection.locale.footer.dropdown.tiny').dropdown({
      onChange: (locale) => window.location.href = `${window.location.pathname}?locale=${locale}`
    });
  }
}
</script>