<template>
  <div class="user-style-container-styles">
    <VueGoodTable
      ref="styleTable"
      :rows="userStyles"
      :columns="columns"
      :pagination-options="{ 
        enabled: true, 
        perPage: 10,
      }"
      styleClass="user-style-container-styles"
    >
      <template slot="table-row" slot-scope="props">
        <span class="user-style-container-styles_card" v-if="props.column.field == 'name_style'">
          <p>{{ props.row.name_style }}</p>
          <div class="user-style-container-styles_card-actions">
            <CTooltip :text="t('layout.shared.actions.edit')" position="bottom">
              <i class="edit link icon" @click="editStyle(props.row.id, props.row.style)"></i>
            </CTooltip>
            <CTooltip :text="t('layout.shared.actions.duplicate')" position="bottom">
              <i class="paste link icon" @click="duplicateStyle(props.row.style)"></i>
            </CTooltip>
            <CTooltip :text="t('layout.shared.actions.delete')" position="bottom">
              <i class="trash alternate link icon" @click="initStyleToDelete(props.row.id, props.row.name_style)"></i>
            </CTooltip>
          </div>
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <CTablePagination
          :total="props.total"
          :page-changed="props.pageChanged"
          :per-page-changed="props.perPageChanged"
          dropdownId="style-pagination"
        >
        </CTablePagination>
      </template>
      <div slot="emptystate" class="empty-data">
        {{ t('user.edit.user_styles.unavailable_style') }}
      </div>
    </VueGoodTable>
    <CModalDeleteStyle :current_user_id="currentUserId"></CModalDeleteStyle>
  </div>
</template>

<script>
import { EventBus } from '../../packs/application';
import { VueGoodTable } from "vue-good-table";
import AxiosStyleService from '../../services/userStyle/user_style';

import CModalDeleteStyle from './CModalDeleteStyle';
import CTablePagination from '../authotElements/CTablePagination.vue';
import CTooltip from '../authotElements/CTooltip';

export default {
  props: [
    "current_user"
  ],
  data() {
    return {
      currentUserId: this.current_user.id,
      userStyles: [],
      columns: [
        {
          field: 'name_style'
        }
      ]
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    initStyleToDelete(id, name) {
      EventBus.$emit('open-delete-modal', id, name);
    },
    editStyle(styleId, styleData) {
      EventBus.$emit('open-style-form', styleId, styleData, false);
    },
    duplicateStyle(styleData) {
      EventBus.$emit('open-style-form', null, styleData, true);
    },
    getUserStyles(userId, styleName) {
      AxiosStyleService.getStyles(userId, styleName)
      .then(response => {
        this.userStyles = response.data.user_styles;
      }).catch(error => {
        console.log("error", error)
      })
    },
  },
  created() {
    this.getUserStyles(this.currentUserId, "");
  },
  mounted() {
    EventBus.$on('back-to-styles', () => this.getUserStyles(this.currentUserId, ""));
    EventBus.$on('style-deleted', () => this.getUserStyles(this.currentUserId, ""));
    EventBus.$on('styles-filtered', (styleName) => this.getUserStyles(this.currentUserId, styleName));
  },
  components: {
    VueGoodTable,
    CModalDeleteStyle,
    CTablePagination,
    CTooltip
  }
}
</script>