<template>
  <div class="ui modal new-video tiny" id="subtitle-translate-modal">
    <i class="close inside icon" @click="closeTranslateModal()"></i>
    <div class="header">
      <i class="big globe africa orange icon"></i>
      <h3>{{ t('project.translation.title') }}</h3>
    </div>
    <div class="content ui form">
      <div class="video-language">
        <div class="required">
          <label>{{ t('project.translation.description') }}</label>
          <span>*</span>
        </div>
        <div class="languages-grid" :class="{'opened': languageGridOpened}">
          <div v-for="language in languages" :key="language.id" class="languages-grid-item">
            <div class="languages-grid-item-container" @click="getChoosedLanguage(language.id)">
              <div 
                class="languages-grid-item-container-top languages-grid"
                :class="{ 'active' : translationLanguageSelected.includes(language.id) }"
              >
                <img 
                  class="languages-grid-item-container-top-flag"
                  :src="require(`../../../assets/img/flags/${language.flag_iso}.png`)"
                >
              </div>
              <p 
                class="languages-grid-item-container-name"
                :class="{ 'active' : translationLanguageSelected.includes(language.id) }"
              >
                {{ language.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="language-show-action" v-show="!languageGridOpened" @click="languageGridOpened = !languageGridOpened">
          <i class="plus square outline orange large icon"></i>
          <p>{{ t('project.new_project.new_video.see_more') }}</p>
        </div>
        <div class="language-show-action" v-show="languageGridOpened" @click="languageGridOpened = !languageGridOpened">
          <i class="minus square outline orange large icon"></i>
          <p>{{ t('project.new_project.new_video.see_less') }}</p>
        </div>
      </div>
      <div>
        <CTooltip :text="t('project.new_project.studio_manual.cpl_tooltip')" position="bottom">
          <p class="dark_grey text required translation-settings-paragraph">
            {{ t('project.new_project.studio_manual.cpl') }} 
            <i class="icon link question circle outline"></i>
            <span>*</span>
          </p>
        </CTooltip>
        <input type="number" min="20" max="120" v-model="cplSelected" required>
        
        <CTooltip :text="t('project.new_project.studio_manual.line_tooltip')" position="bottom">
          <p class="dark_grey text required translation-settings-paragraph">
            {{ t('project.new_project.studio_manual.line') }}
            <i class="icon link question circle outline"></i>
            <span>*</span>
          </p>
        </CTooltip>
        <input type="number" min="1" max="2" v-model="lineSelected" required>
      </div>
      <div class="actions">
        <div></div>
        <button class="authot ui button" @click="postTranslation()">{{ t('layout.shared.actions.valid') }}</button>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../packs/application'
import { displayToast  } from '../../../utils/functions'
import CTooltip from '../../authotElements/CTooltip.vue'

export default {
  data() {
    return {
      languageGridOpened: false,
      maxTitleLength: 25
    }
  },
  computed: {
    translationLanguageSelected() {
      return this.$store.getters['projects/translation_language_selected']
    },
    languages() {
      return this.$store.getters['projects/translation_languages']
    },
    cplSelected: {      
      get() {
        return this.$store.getters['projects/cpl_selected']
      },
      set(value) {
        this.$store.commit('projects/SET_CPL', value);
      }
    },
    lineSelected: {
      get() {
        return this.$store.getters['projects/line_selected']
      },
      set(value) {
        this.$store.commit('projects/SET_LINE', value);
      }
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    openTranslateModal() {
      EventBus.$emit('any-modal-opened');
      $("#subtitle-translate-modal").modal('show');
    },
    closeTranslateModal() {
      EventBus.$emit('any-modal-closed');
      $("#subtitle-translate-modal").modal('hide');
      this.languageGridOpened = false;
    },
    getChoosedLanguage(languageId) {
      this.$store.commit('projects/UPDATE_LANGUAGE_TO_TRANSLATE', { language_id: languageId })
    },
    postTranslation() {
      if(this.translationLanguageSelected.length == 0) {
        displayToast('error', this.t('project.projects.projects_tab.new_subtitle_error_language'))
      } else {
        this.$store.dispatch('projects/postTranslation');
        this.closeTranslateModal();
      }
    }
  },
  mounted() {
    $("#subtitle-translate-modal").modal({
      inverted: true,
      closable: false,
      onHide: () => this.$store.commit('projects/EMPTY_SUBTITLES_TO_TRANSLATE')
    });
  },
  components: {
    CTooltip
  }
}
</script>

<style scoped>
.translation-settings-paragraph {
  display: flex;
  align-items: center;
  margin: 10px 0px;
}
</style>