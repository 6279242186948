import AxiosDemonstration from '../../services/ticket/demonstration'
import axios from "axios";
let axiosClient;

export const demonstrationStore = {
  namespaced: true,
  actions: {
    demonstrationPostForm({ commit }, payload) {   
      // axiosClient = axios.create({
      //   headers: {
      //     Accept: 'text/html',
      //     'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      //     'Content-Type': 'text/html'
      // }})
      // axiosClient.post('/ticket/demonstration', payload)
      //   .then((response) => {
      //     window.location = response['data']['redirect_url'];
      //   }).catch(error => {
      //     console.log(error);
      //   });
      AxiosDemonstration.postDemonstration(payload)
        .then((response) => {
          $('body').toast({
            showIcon: 'times circle outline icon big green',
            message: "Message envoyé",
            class : 'ui toast authot',
          });
          setTimeout(() => {
            // console.log("HERE ! RESPONSE");
            // console.log(response);
            window.location = response['data']['redirect_url'];
          }, 3000);
        }).catch(error => {
          console.log(error);
        });
    },
  }
}  


