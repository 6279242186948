<template class="ui segment orders">
  <div>
    <div class="orders-header">
      <h3>
        {{ t('user.edit.invoices.title') }}
        <span class="ui light_green circular label">{{ orders.length }}</span>
      </h3>
    </div>
    <VueGoodTable
      ref="styleTable"
      :rows="orders"
      :columns="columns"
      :pagination-options="{ 
        enabled: true, 
        perPage: 10,
      }"
      styleClass="vgt-table orders-list"
    >
      <template slot="table-row" slot-scope="props">
        <span class="" v-if="props.column.field == 'created_at'">
          {{ props.row.created_at }}
        </span>
        <span class="" v-else-if="props.column.field == 'id'">
          #{{ props.row.id }}
        </span>
        <span class="" v-else-if="props.column.field == 'actions'">
          <button class="authot little ui button">
            <a :href="'/orders/'+ props.row.id + '.pdf'" target="_blank" style="text-decoration:none;color:white;">{{ t('user.edit.invoices.btn_download') }}</a>
            <i class="white small download icon"></i>
          </button>
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <CTablePagination
          :total="props.total"
          :page-changed="props.pageChanged"
          :per-page-changed="props.perPageChanged"
          dropdownId="orders-pagination"
        >
        </CTablePagination>
      </template>
      <div slot="emptystate" class="empty-data">
        {{ t('user.edit.invoices.unavailable_invoice') }}
      </div>
    </VueGoodTable>
  </div>
</template>

<script>
import AxiosOrderService from '../../../services/order/order-api'
import { VueGoodTable } from "vue-good-table";
import CTablePagination from '../../../components/authotElements/CTablePagination.vue'

export default {
  props: [
    "account_id"
  ],
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
    columns() {
      return [
        {
          label: this.t('user.edit.invoices.month'),
          field: 'created_at',
          sortable: false
        },
        {
          label: this.t('user.edit.invoices.invoice_number'),
          field: 'id',
          sortable: false
        },
        {
          label: this.t('user.edit.invoices.download'),
          field: 'actions',
          sortable: false,
        }
      ]
    }
  },
  data() {
    return {
      orders: [],
    }
  },
  methods: {
    getUserOrders() {
      AxiosOrderService.getOrders(this.account_id)
      .then(response => {
        response.data.orders.map(order => this.orders.push(order));
      }).catch(error => {
        console.log(error)
      })
    },
    getOrder(orderId) {
      AxiosOrderService.getPdfOrder(orderId);
    }
  },
  mounted() {
    this.getUserOrders();
  },
  components: {
    VueGoodTable,
    CTablePagination
  }
}
</script>