<template>
  <div id="styleForm" class="style-form">
      <div class="ui modal style tiny" id="style-form-modal">
        <i class="times link large inside icon" @click="isFormModified()"></i>
        <div class="header">
          <i class="tools large icon"></i>
          {{ t('editor.tooltips.display_settings') }}
        </div>
        <div class="content ui form">
          <div class="style-form-top">
            <div class="style-form-top-items">
              <div class="style-form-top-items-inline">
                <div class="required">
                  <label class="form label">{{ t('user.edit.user_styles.style') }}</label>
                  <span>*</span>
                </div>
                <div class="video-project">
                  <div class="ui mini search selection dropdown select_style_form margin_top_editor">
                    <i class="dropdown icon"></i>
                    <div class="default text">{{ t('user.edit.user_styles.style_placeholder') }}</div>
                  </div>
                </div>
              </div>
              <div class="style-form-top-items-inline">
                <label class="form light label" for="color">{{ t('user.edit.user_styles.text_color') }}</label> 
                <input v-model="style.primary_color" name="color" style="display: none;">
                <el-color-picker :value="primaryColorStyle()" @change="getPrimaryColor($event)" @input="FormModified = true" size="small" color-format="hex">
                </el-color-picker>
              </div>
              <div class="style-form-top-items-inline">
                <label class="form light label" for="spacing">{{ t('user.edit.user_styles.character_spacing') }}</label>
                <input v-model="style.spacing" type="number" class="light input styleInput" name="spacing">
              </div>
              <div class="style-form-top-items-inline">
                <label class="form light label" for="outline">{{ t('user.edit.user_styles.border') }}</label>
                <div class="ui radio">
                  <input type="radio" id="outline" v-model="style.border_style" value="1">
                  <div class="authot-radio"></div>
                </div>
                <label class="form light label" for="opaqueBox">{{ t('user.edit.user_styles.opaque_box') }}</label>
                <div class="ui radio">
                  <input type="radio" id="opaqueBox" v-model="style.border_style" value="3">
                  <div class="authot-radio"></div>
                </div>
              </div>             
              <div class="style-form-top-items-inline">
                <label class="form light label" for="outlineColor">{{ t('user.edit.user_styles.border_color') }}</label>
                <input v-model="style.outline_color" name="outlineColor" style="display: none;">
                <el-color-picker :value="outlineColorStyle()" @change="getOutlineColor($event)" @input="FormModified = true" size="small" color-format="hex"></el-color-picker>
              </div>
              <!-- <div class="style-form-top-items-inline">
                <label class="form light label" for="outlineSize">Taille de bordure</label>
                <input type="number" disabled id="outlineSize" v-model="style.outline" class="light input">
              </div> -->
              <div class="style-form-top-items-inline">
                <label class="form light label">{{ t('user.edit.user_styles.cpl') }}</label>
                <input type="number" min="0" v-model="style.cpl" class="light input styleInput">
              </div>
            </div>
            <div class="style-form-top-items">
              <div class="style-form-top-items-inline">
              <label class="form light label">{{ t('user.edit.user_styles.font') }}</label>
                <div class="ui mini search selection dropdown font-dropdown styleInput">
                  <input type="hidden" name="font-dropdown">
                  <i class="dropdown icon"></i>
                  <div class="default text">{{ style.font_name }}</div>
                  <div class="menu">
                    <div class="item" v-for="(font, i) in fontsList" :key="i" :data-id="font.id" :data-value="font.name">{{ font.name }}</div>
                  </div>
                </div>
              </div>
              <div class="style-form-top-items-inline">
                <label class="form light label">{{ t('user.edit.user_styles.font_size') }}</label>
                <input v-model="style.font_size" type="number" class="light input styleInput">
              </div>              
              <div class="style-form-top-items-inline">
                <label class="form light label">{{ t('user.edit.user_styles.text_style') }}</label>
                <div>
                  <div class="ui checkbox style bold">
                    <input type="checkbox" v-model="style.bold" true-value="-1" false-value="0" class="styleInput">
                    <label></label>
                  </div>
                  <div class="ui checkbox style italic">
                    <input type="checkbox" v-model="style.italic" true-value="-1" false-value="0" class="styleInput">
                    <label></label>
                  </div>
                  <div class="ui checkbox style underline">
                    <input type="checkbox" v-model="style.underline" true-value="-1" false-value="0" class="styleInput">
                    <label></label>
                  </div>
                  <div class="ui checkbox style line">
                    <input type="checkbox" v-model="style.strike_out" true-value="-1" false-value="0" class="styleInput">
                    <label></label>
                  </div>
                </div>
              </div>
              <div class="style-form-top-items-inline">
                <label class="form light label">{{ t('user.edit.user_styles.bloc_position') }}</label>
                <div class="style-form-top-items-square">
                  <div class="position-line">
                    <div class="ui radio">
                      <input type="radio" v-model="style.alignment" value="7" class="styleInput">
                      <div class="square-radio"></div>
                    </div>
                    <div class="ui radio">
                      <input type="radio" v-model="style.alignment" value="8" class="styleInput">
                      <div class="square-radio"></div>
                    </div>
                    <div class="ui radio">
                      <input type="radio" v-model="style.alignment" value="9" class="styleInput">
                      <div class="square-radio"></div>
                    </div>
                  </div>
                  <div class="position-line">
                    <div class="ui radio">
                      <input type="radio" v-model="style.alignment" value="4" class="styleInput">
                      <div class="square-radio"></div>
                    </div>
                    <div class="ui radio">
                      <input type="radio" v-model="style.alignment" value="5" class="styleInput">
                      <div class="square-radio"></div>
                    </div>
                    <div class="ui radio">
                      <input type="radio" v-model="style.alignment" value="6" class="styleInput">
                      <div class="square-radio"></div>
                    </div>
                  </div>
                  <div class="position-line">
                    <div class="ui radio">
                      <input type="radio" v-model="style.alignment" value="1" class="styleInput">
                      <div class="square-radio"></div>
                    </div>
                    <div class="ui radio">
                      <input type="radio" v-model="style.alignment" value="2" class="styleInput">
                      <div class="square-radio"></div>
                    </div>
                    <div class="ui radio">
                      <input type="radio" v-model="style.alignment" value="3" class="styleInput">
                      <div class="square-radio"></div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="style-form-top-items-inline">
                <label class="form light label">Couleur de l'ombre</label>
                <input v-model="style.background_color" style="display: none;">
                <el-color-picker disabled :value="backgroundColorStyle()" @change="getShadowColor($event)" size="small" color-format="hex"></el-color-picker>
              </div>
              <div class="style-form-top-items-inline">
                <label class="form light label" for="shadowSize">Position de l'ombre</label>
                <input type="number" disabled class="light input" id="shadowSize" v-model="style.shadow">
              </div>               -->
              <div class="style-form-top-items-inline">
                <div class="ui checkbox full-count">
                  <input type="checkbox" id="fullCount" true-value="true" false-value="false" v-model="style.full_count_cpl" class="styleInput">
                  <label class="label-full-count" for="fullCount">{{ t('user.edit.user_styles.space_punctuation') }}</label>
                </div>
              </div>
              <div class="style-form-top-items-inline">
                <label class="form light label">{{ t('user.edit.user_styles.cps') }}</label>
                <input type="number" min="0" v-model="style.cps" class="light input styleInput">
              </div>
            </div>
          </div>
          <div class="style-form-margins">
            <div>Marges</div>
            <div class="style-form-margins-inputs">
              <div class="style-form-margins-inputs-input">
                <label class="form light label" for="marginLeft">Gauche</label>
                <input type="number" id="marginLeft" min="0" v-model="style.margin_l" class="light input">
              </div>
              <div class="style-form-margins-inputs-input">
                <label class="form light label" for="marginRight">Droite</label>
                <input type="number" id="marginRight" min="0" v-model="style.margin_r" class="light input">
              </div>
              <div class="style-form-margins-inputs-input">
                <label class="form light label" for="marginVertical">Verticale</label>
                <input type="number" id="marginVertical" min="0" v-model="style.margin_v" class="light input">
              </div>
            </div>
          </div>
          <div class="style-form-bottom">
            <div class="preview">
              <div id="video-edit-style-preview">
                {{ t('user.edit.user_styles.preview') }}
              </div>
            </div>
            <button class="authot ui green button" @click="manageStyle()">{{ t('user.edit.user_styles.save') }}</button>
          </div>
        </div>
      </div>
      <CWarningModal
        ref="warningEditorStyle"
        modalId="editorStyle"
        :modalHeader="t('authot_elements.modal.warning_editor_style_title')"
        :modalContent="t('authot_elements.modal.warning_editor_style_description')"
        :modalAbort="t('layout.shared.actions.cancel')"
        :modalValid="t('layout.shared.actions.continue')"
        @warning-modal-aborted="managePointerModal(true)"
        @warning-modal-validated="closeModal()"
      ></CWarningModal>
  </div>
</template>

<script>
import { EventBus } from '../../packs/application';
import AxiosStyleService from '../../services/userStyle/user_style';
import CWarningModal from '../authotElements/CWarningModal.vue';

export default {
  props: [
    "current_user",
    "json_styles",
    "user_styles"
  ],
  data() {
    return {
      style: null,
      opaqueBox: "",
      userId: this.current_user,
      userStyles: this.user_styles,
      jsonStyles: this.json_styles,
      FormModified: false
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
    fontsList() {
      return this.$store.getters['userFonts/all_fonts']
    }
  },
  methods: {
    openModal() {
      $('#style-form-modal').modal('show');

      const stylesFiltered = this.jsonStyles.map(style => {
        return { name: style.name_style, value: style.name_style }
      });

      $('.select_style_form').dropdown({
        allowAdditions: true,
        hideAdditions: false,
        clearable: true,
        forceSelection: false,
        showOnFocus: false,
        values: stylesFiltered,
        message: {
          addResult: this.t('authot_elements.dropdown.click_to_add'),
        },
        onChange: (style) => {
          this.displaySelectedStyle(style);
          this.FormModified = false;
        },
      });

      $('.ui.dropdown.font-dropdown').dropdown({
        value: this.style.font_name,
        showOnFocus: false,
        onChange: (value, text, $selectedItem) => {
          this.manageChoosedFont($selectedItem[0].dataset.id);
          this.style.font_name = value;
        }
      });
    },
    closeModal() {
      this.FormModified = false;
      this.managePointerModal(true);
      this.getStyles();
      $('.select_style_form').dropdown('restore defaults');
      $('#style-form-modal').modal('hide');
    },
    managePointerModal(active) {
      if(active) {
        this.$refs.warningEditorStyle.$el.style.display = "none";
        $('#style-form-modal').css('pointer-events', 'auto');
        $('#style-form-modal').css('opacity', '1');
      } else {
        $('#style-form-modal').css('pointer-events', 'none');
        $('#style-form-modal').css('opacity', '0.7');
      }
    },
    displaySelectedStyle(styleName) {
      const styleToDisplay = this.jsonStyles.find(style => {
        return style.name_style == styleName;
      });
      if(styleToDisplay == undefined) {
        this.style = this.initDefaultStyle();
        this.style.name_style = styleName;
      } else {
        this.style = styleToDisplay;
      }
      $('.ui.dropdown.font-dropdown').dropdown('set selected', this.style.font_name);
    },
    initDefaultStyle() {
      return {
        alignment:"2",
        angle:"0",
        background_color:"0",
        bold:"0",
        border_style:"1",
        encoding:"0 ",
        font_name:"Arial",
        font_size:"19",
        italic:"0",
        margin_l:"10",
        margin_r:"10",
        margin_v:"10",
        name_style:"",
        outline:"1",
        outline_color:"000000",
        primary_color:"ffffff",
        scale_x:"100",
        scale_y:"100",
        secondary_color:"ffffff",
        shadow:"0",
        spacing:"0",
        strike_out:"0",
        underline:"0",
        cpl: 40,
        cps: 20,
        full_count_cpl: 0
      }
    },
    initFontsList() {
      if(this.fontsList.length == 0) this.$store.dispatch('userFonts/getFonts', this.current_user);
    },
    manageChoosedFont(id) {
      this.$store.commit('userFonts/SET_REQUESTED_FONT', id);
      this.$store.commit('userFonts/ADD_FONT_FACE');
    },
    initStyles() {
      this.userStyles.forEach(userStyle => {
        this.jsonStyles.forEach((jsonStyle, index) => {
          if(userStyle.style["name_style"] == jsonStyle["name_style"]) this.jsonStyles.splice(index, 1);
        });
        this.jsonStyles.push(userStyle.style);
      });
    },
    manageStyle() {
      if(this.style.name_style == "" || this.style.name_style == null ) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('user.edit.user_styles.error_name_message'),
          class : 'ui toast authot',
        });
      } else if(this.style.name_style == "Default") {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('user.edit.user_styles.error_default_name_message'),
          class : 'ui toast authot',
        });
      } else {
        this.saveStyle();
      }
    },
    isFormModified() {
      if(this.FormModified) {
        this.managePointerModal(false);
        // this.$refs.warningEditorStyle.openModal()
        // console.log(this.$refs.warningEditorStyle);
        this.$refs.warningEditorStyle.$el.style.display = "block";
        this.$refs.warningEditorStyle.$el.style.zIndex = "1002";
      } else {
        this.closeModal()
      }
    },
    saveStyle() {
      let styleToManage = this.userStyles.find(us => { if(us.style.name_style == this.style.name_style) return us });
      if (styleToManage == undefined){
        this.postNewStyle();
        this.FormModified = false;
      } else {
        this.updateStyle(styleToManage.id);
        this.FormModified = false;
      }
    },
    getStyles() {
      AxiosStyleService.getStyles(this.userId)
      .then(response => {
        this.userStyles = response.data.user_styles;
        this.initStyles();
      }).catch(error => {
        console.log(error);
      });
    },
    postNewStyle() {
      AxiosStyleService.postStyle(this.userId, this.style)
      .then(response => {
        $('body').toast({
          showIcon: 'check circle outline icon big green',
          message: this.t('user.edit.user_styles.style_saved'),
          class : 'ui toast authot',
        });
        this.getStyles();
      }).catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class : 'ui toast authot',
        });
      })
    },
    updateStyle(styleId) {
      AxiosStyleService.putStyle(this.userId, styleId, this.style)
      .then(response => {
        $('body').toast({
          showIcon: 'check circle outline icon big green',
          message: this.t('user.edit.user_styles.style_updated'),
          class : 'ui toast authot',
        });
        this.getStyles();
      }).catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class : 'ui toast authot',
        });
      })
    },
    primaryColorStyle() {
      if(this.style.primary_color == "0") {
        return null
      } else {
        return '#' + this.style.primary_color;
      }
    },
    outlineColorStyle() {
      if(this.style.outline == "0") {
        return null
      } else {
        return '#' + this.style.outline_color;
      }
    },
    backgroundColorStyle() {
      if(this.style.background_color == "0") {
        return null
      } else {
        return '#' + this.style.background_color
      }
    },
    getPrimaryColor(color) {
      if(color == null) {
        this.style.primary_color = "0";
      } else {
        let assColor = color.replace("#", "");
        this.style.primary_color = assColor;
      }
    },
    getOutlineColor(color) {
      if(color == null) {
        this.style.outline = 0;
        this.style.outline_color = "0";
      } else {
        this.style.outline = 1;
        let assColor = color.replace("#", "");
        this.style.outline_color = assColor;
      }
    },
    getShadowColor(color) {
      if(color == null) {
        this.style.background_color = "0";
      } else {
        let assColor = color.replace("#", "");
        this.style.background_color = assColor;
      }
    }
  },
  watch: {
    style: {
      deep: true,
      handler() {
        EventBus.$emit("style-changed", this.style, "video-edit-style-preview");
      }
    }
  },
  beforeMount() {
    this.style = this.initDefaultStyle();
  },
  mounted() {
    $('#style-form-modal').modal({
      closable: false,
      inverted: true,
      allowMultiple: true
    });
    this.initStyles();
    this.initFontsList();
    $('.styleInput').change(() => this.FormModified = true);
    EventBus.$on('display-style-form', () => this.openModal());
  },
  components: {
    CWarningModal
  }
}
</script>