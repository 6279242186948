<template>
  <div class="ui modal tiny new-video" id="uploader-languages-modal">
    <div class="header">
      <h3>Configurez votre langue</h3>
    </div>

    <div class="content">
      <div class="video-language">
        <div class="required">
          <label>{{ t('project.new_project.new_video.language_description') }}</label>
          <span>*</span>
        </div>
        <div class="languages-grid" :class="{'opened': languageGridOpened}">
          <div v-for="language in languages" :key="language.id" class="languages-grid-item">
            <div class="languages-grid-item-container" @click="languageSubtitleSelected = language.id">
              <div 
                class="languages-grid-item-container-top languages-grid"
                :class="{ 'active' : language.id == languageSubtitleSelected }"
              >
                <img 
                  class="languages-grid-item-container-top-flag"
                  :src="require(`../../../assets/img/flags/${language.flag_iso}.png`)"
                >
              </div>
              <p 
                class="languages-grid-item-container-name"
                :class="{ 'active' : language.id == languageSubtitleSelected }"
              >
                {{ language.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="language-show-action" v-show="!languageGridOpened" @click="languageGridOpened = !languageGridOpened">
          <i class="plus square outline orange large icon"></i>
          <p>{{ t('project.new_project.new_video.see_more') }}</p>
        </div>
        <div class="language-show-action" v-show="languageGridOpened" @click="languageGridOpened = !languageGridOpened">
          <i class="minus square outline orange large icon"></i>
          <p>{{ t('project.new_project.new_video.see_less') }}</p>
        </div>
      </div>
      <div class="actions">
        <div></div>
        <button class="green authot ui button" @click="getSelectedLanguage()">{{ t('layout.shared.actions.valid') }}</button>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["languageSelected"],
  data() {
    return {
      languageGridOpened: false,
      languageSubtitleSelected: null
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
    languages() {
      return this.$store.getters['newProjects/languages']
    },
  },
  methods: {
    openModal() {
      $("#uploader-languages-modal").modal('show')
      this.$nextTick(() => {
        this.languageSubtitleSelected = this.languageSelected
      });
    },
    closeModal() {
      $("#uploader-languages-modal").modal('hide')
      this.languageGridOpened = false
    },
    getSelectedLanguage() {
      this.$emit("emitSubDocLanguage", this.languageSubtitleSelected)
    }
  },
  mounted() {
    $("#uploader-languages-modal").modal({
      inverted: true,
      closable: false,
      autofocus: false,
    })
  }
}
</script>

<style scoped></style>