const maxTitleLength = 25
const maxTitleLengthRatio = 10 

export function convertKeyToCamelCase(key) {
  return key.replace(/([-_][a-z])/ig, ($1) => {
    return $1.toUpperCase()
      .replace('_', '');
  });
}

export function truncateKey(keyToTruncate) {
  return keyToTruncate.length > maxTitleLength ? 
  keyToTruncate.substr(0, maxTitleLengthRatio) + '...' + keyToTruncate.substr(keyToTruncate.length-maxTitleLengthRatio, keyToTruncate.length) : 
  keyToTruncate;
}

export function convertQuantityToTime(quantity, unit) {
  if(unit == "hours") {
    if(parseInt((quantity/3600)) < 10) {
      return '0' + parseInt((quantity/3600)); 
    }
    return (parseInt((quantity/3600)));
  } 
  if(unit == "minutes") return ('0' + parseInt((quantity/60)%60)).slice(-2);    
  if(unit == "seconds") return ('0' + parseInt(quantity%60)).slice(-2);  
  if(!unit) return `${('0' + parseInt((quantity/3600))).slice(-2)} : ${('0' + parseInt((quantity/60)%60)).slice(-2)} : ${('0' + parseInt((quantity%60))).slice(-2)}`;
  
}

export function displayToast(type, message) {
  type === 'success' ?
    $('body').toast({ showIcon: 'check circle outline icon big green', message: message, class : 'ui toast authot'}) :
    $('body').toast({ showIcon: 'times circle outline icon big red', message: message, class : 'ui toast authot'});
}

export function getCalendarMinimumDate(daysToAdd) {
  const today = new Date();
  const a = today.getDate() + daysToAdd
  const b = today.setDate(a)
  const wishedDay = new Date(b)
  return wishedDay.toISOString().split('T')[0]
}

export function splitStringInArray(stringToSplit, separator) {
  let arrayFromString = stringToSplit.split(separator);
  arrayFromString.map((string, i) => { if(!string) arrayFromString.splice(i, 1) })
  return arrayFromString;
}

export function checkIfValidUrl(url) {
  const regEx1 = /^http:/gm
  const regEx2 = /^https:/gm
  return regEx1.test(url) || regEx2.test(url) ? true : false
}

export function isFilled(value) {
  return Array.isArray(value) ?
    value.length > 0 :
    value != null && value != "" && value != undefined
} 