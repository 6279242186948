import axiosClient from "../axios-config";

export default {
  getIncrustationsSearch(dateData, titleData, languageId, subtitleId, projectId, perPage, numberPage) {
    if(dateData == null) dateData = ""
    return axiosClient.get('/incrustation', {
      params: {
        search: {
          videos: {
            created_at: dateData,
            title: titleData,
            language_id: languageId,
          },
          subtitles: {
            id: subtitleId
          },
          projects: {
            name: projectId,
          }
        },
        per_page: perPage,
        number_page: numberPage,
      }
    });
  },
  getIncrustations(perPage, numberPage) {
    return axiosClient.get('/incrustation', {
      params: {
        per_page: perPage,
        number_page: numberPage,
      }
    });
  },
  exportIncrustation(inscrustationId) {
    return axiosClient.get('/incrustation/' + inscrustationId);
  },
  postIncrustation(videoId, subtitlesArray) {
    return axiosClient.post('/incrustation', { incrustation: { video_id: videoId, subtitles: subtitlesArray }});
  },
  destroyIncrustation(inscrustationId) {
    return axiosClient.delete('/incrustation/' + inscrustationId);
  }
}