<template>
  <div :class="`authot uploader`">
    <div v-show="documents.length == 0"  :id="dropId" class="drop-area">
      <i class="grey big file outline icon"></i>
      <p v-html="t('authot_elements.uploader.description')"></p>
      <button type="button" :id="browseId" class="ui green authot button">{{ t('authot_elements.uploader.btn_select') }}</button>
    </div>
    <div v-show="documents.length > 0" class="file-area" v-for="(document, i) in documents" :key="i">
      <div class="file-infos">
        <i class="grey large file outline icon"></i>
        <p>{{ document.fileName }}</p>
      </div>
      <i class="red large link times circle outline icon" @click="removeFile(document.uniqueIdentifier, i)"></i>
    </div>
  </div>
</template>

<script>
import Resumable from 'resumablejs';
import { EventBus } from '../../packs/application';

export default {
  props: ["dropId", "browseId", "fileAccepted"],
  data() {
    return {
      r: {},
      documents: [],
      state: "unuploaded"
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    removeFile(identifier, index) {
      const fileRemoved = this.r.getFromUniqueIdentifier(identifier);
      this.r.removeFile(fileRemoved);
      this.documents.splice(index, 1);
      this.$emit("emitFileDestroy");
    },
    pushFile(file) {
      this.documents.push({
        fileName: file.fileName,
        uniqueIdentifier: file.uniqueIdentifier,
      });
      this.$emit("emitFilename", file.fileName);
    },
    async upload() {
      this.r.upload();
      return new Promise(resolve => {
        this.r.on('complete', () => {
          return resolve()
        })
      })
    },
  },
  mounted() {
    this.r = new Resumable({
      testChunks: true,
      fileType: this.fileAccepted,
      target: '/upload',
      simultaneousUploads: 1,
      headers: { 
        'X-CSRF-Token' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
    });
    this.r.assignBrowse($('#' + this.browseId));
    this.r.assignDrop($('#' + this.dropId));
    this.r.on('fileAdded', (file) => {
      this.r.files.length === 1 ? this.pushFile(file) : this.removeFile(file.uniqueIdentifier);
    });
    this.r.on('progress', () => {
      EventBus.$emit('file-progress', (this.r.progress()*100));
    });
    this.r.on('fileSuccess', (file, message) => {
      const response = JSON.parse(message);
      this.$emit('emitFilepath', response.file_path);
      this.state = "uploaded";
    });
  }
}
</script>