import { v4 as uuidv4 } from 'uuid';
import { EventBus } from '../../packs/application';
import AxiosProjectsService from '../../services/project/project';
import AxiosLanguagesService from '../../services/languages/languages';
import AxiosNewVideoService from '../../services/video/new-video';

export const newProjectsStore = {
  namespaced: true,
  state: {
    project_step: 1,
    product_selected: null,
    language_selected: null,
    cpl_selected: 40,
    line_selected: 2,
    main_project_selected: null,
    delivery_date_selected: null,
    predefined_instructions: [],
    custom_instructions: null,
    projects: [],
    url_projects: [],
    languages: [],
    user_projects: []
  },
  getters: {
    project_step: state => {
      return state.project_step;
    },
    product_selected: state => {
      return state.product_selected;
    },
    language_selected: state => {
      return state.language_selected;
    },
    cpl_selected: state => {
      return state.cpl_selected
    },
    line_selected: state => {
      return state.line_selected
    },
    main_project_selected: state => {
      return state.main_project_selected
    },
    delivery_date_selected: state => {
      return state.delivery_date_selected
    },
    predefined_instructions: state => {
      return state.predefined_instructions
    },
    custom_instructions: state => {
      return state.custom_instructions
    },
    projects: state => {
      return state.projects;
    },
    url_projects: state => {
      return state.url_projects;
    },
    languages: state => {
      return state.languages;
    },
    user_projects: state => {
      return state.user_projects;
    }
  },
  mutations: {
    SET_LANGUAGES(state, languagesData) {
      state.languages = [];
      languagesData.map(language => { state.languages.push(language) });
    },
    SET_USER_PROJECTS(state, projectsData) {
      if(projectsData) {
        state.user_projects = [];
        projectsData.map(project => state.user_projects.push(project));
      }
    },
    SET_PRODUCT(state, payload) {
      state.product_selected = { key: payload.product_key, id: payload.product_id, name: payload.product_name };
    },
    SET_PROJECT_STEP(state, payload) {
      state.project_step = payload.step
    },
    SET_DATE(state, payload) {
      state.delivery_date_selected = payload;
    },
    SET_CPL(state, payload) {
      state.cpl_selected = payload;
    },
    SET_LINE(state, payload) {
      state.line_selected = payload;
    },
    SET_PREDEFINED_INSTRUCTION (state, payload){
      state.predefined_instructions.push(payload);
    },
    REMOVE_PREDEFINED_INSTRUCTION (state, payload){
      state.predefined_instructions.map((proofreadingInstruction, i) => { 
        if(payload == proofreadingInstruction) state.predefined_instructions.splice(i, 1)
      })
    },
    SET_CUSTOM_INSTRUCTION(state, payload){
      state.custom_instructions = payload;
    },
    SET_SELECTED_LANGUAGE(state, payload){
      state.language_selected = payload
    },
    SET_MAIN_PROJECT_SELECTED(state, payload){
      state.main_project_selected = payload
    },
    RESET_NEW_PROJECT(state) {
      state.project_step = 1;
      state.product_selected = null;
      state.language_selected = null;
      state.cpl_selected = 40;
      state.line_selected = 2;
      state.main_project_selected = null;
      state.delivery_date_selected = null;
      state.predefined_instructions = [];
      state.custom_instructions = null;
      state.projects = [];
      state.url_projects = [];
      state.languages = [];
      state.user_projects = [];
      state.is_add_product_available = false;
    },
    CREATE_PROJECT(state, payload) {
      state.projects.push({
        id: payload.uniqueIdentifier,
        title: payload.fileName,
        subtitles_attributes: []
      });
    },
    REMOVE_PROJECT(state, payload) {
      state.projects.map((project, i) => {
        if(project.id === payload.project_id) state.projects.splice(i, 1);
      });
    },
    REMOVE_SUBTITLE_PROJECT(state, payload) {
      const project = state.projects.find((project) => project.id === payload.parentFileId);
      project.subtitles_attributes.map((subtitle, i) => {
        if(subtitle.id == payload.uniqueIdentifier) project.subtitles_attributes.splice(i, 1);
      });
    },
    CREATE_URL_PROJECT(state, payload) {
      state.url_projects.push({
        id: payload.url,
        url: payload.url,
        title: payload.url,
        subtitles_attributes: []
      });
    },
    REMOVE_URL_PROJECT(state, payload) {
      state.url_projects.map((project, i) => {
        if(project.id == payload.url) {
          state.url_projects.splice(i, 1)
        };
      });
    },
    REMOVE_SUBTITLE_URL(state, payload) {
      const project = state.url_projects.find((project) => project.id === payload.parentFileId);
      project.subtitles_attributes.map((subtitle, i) => {
        if(subtitle.id == payload.uniqueIdentifier) project.subtitles_attributes.splice(i, 1);
      });
    },
    ADD_SUBTITLE_TO_PROJECT(state, payload) {
      state.projects.map(project => {
        if(project.id === payload.parentFileId) {
          project.subtitles_attributes.push({ id: payload.uniqueIdentifier, title: payload.fileName, language_id: state.language_selected })
          // project.subtitles_attributes ?
          //   project.subtitles_attributes.push({ id: payload.uniqueIdentifier, title: payload.fileName, language_id: state.language_selected }) :
          //   project.subtitles_attributes = [{ id: payload.uniqueIdentifier, title: payload.fileName, language_id: state.language_selected }];
        }
      })     
    },
    ADD_SUBTITLE_TO_URL(state, payload) {
      state.url_projects.map(project => {
        if(project.id === payload.parentFileId) {
          project.subtitles_attributes.push({ id: payload.uniqueIdentifier, title: payload.fileName, language_id: state.language_selected })
          // project.subtitles_attributes ?
          //   project.subtitles_attributes.push({ id: payload.uniqueIdentifier, title: payload.fileName, language_id: state.language_selected }) :
          //   project.subtitles_attributes = [{ id: payload.uniqueIdentifier, title: payload.fileName, language_id: state.language_selected }];
        }
      })
    },
    UPDATE_URL_SUBTITLE_LANGUAGE(state, payload) {
      state.url_projects.map(project => {
        if(project.id === payload.parentFileId) {
          project.subtitles_attributes.map(subtitle => {
            if(subtitle.id === payload.uniqueIdentifier) subtitle.language_id = payload.languageId;
          })
        }
      })
    },
    UPDATE_PROJECT_SUBTITLE_LANGUAGE(state, payload) {
      state.projects.map(project => {
        if(project.id === payload.parentFileId) {
          project.subtitles_attributes.map(subtitle => {
            if(subtitle.id === payload.uniqueIdentifier) subtitle.language_id = payload.languageId;
          })
        }
      })
    },
    UPDATE_SUBTITLES_LANGUAGE(state, payload) {
      state.projects.map(project => {
        if(project.subtitles_attributes) {
          project.subtitles_attributes.map(subtitle => {
            if(!subtitle.language_id) subtitle.language_id = payload;
          })
        }          
      })
      
      state.url_projects.map(project => {
        if(project.subtitles_attributes) {
          project.subtitles_attributes.map(subtitle => {
            if(!subtitle.language_id) subtitle.language_id = payload;
          })
        }    
      })
    },
    UPDATE_FILEPATH(state, payload) {
      payload.project_to_update.project_type == "project" ?
        state.projects.find((project) => project.id === payload.project_to_update.project_id).filepath = payload.info_file.file_path :
        payload.project_to_update.project_type == "project-subtitle" ?
          state.projects.find((project) => project.id === payload.project_to_update.project_id).subtitles_attributes.find((subtitle) => subtitle.id == payload.info_file.file_id).filepath = payload.info_file.file_path :
          state.url_projects.find((project) => project.id === payload.project_to_update.project_id).subtitles_attributes.find((subtitle) => subtitle.id == payload.info_file.file_id).filepath = payload.info_file.file_path 
    }
  },
  actions: {
    getAllLanguages({ commit }) {
      AxiosLanguagesService.getLanguages()
      .then(response => {
        commit('SET_LANGUAGES', response.data.languages);
      }).catch(error => {
        console.log(error);
      });
    },
    setAllUserProjects({ commit }) {
      AxiosProjectsService.getProjects()
      .then(response => {
        commit('SET_USER_PROJECTS', response.data);
      }).catch(error => {
        console.log(error);
      });
    },
    addNewProject({ commit }) {
      commit('ADD_NEW_PROJECT');
    },
    createProjectObject({ state }, payload) {
      if(!state.product_selected.key.includes('manual')) {
        Object.assign(payload.project_to_post, {
          original_file_path: payload.info_file.file_path ? payload.info_file.file_path : null,
          url: payload.info_file.file_path ? null : payload.info_file.url,
          language_id: state.language_selected,
          project_name: state.main_project_selected,
          subtitles_attributes: [{
            title: payload.project_to_post.title,
            product_id: state.product_selected.id,
            language_id: state.language_selected,
            proofreading_instruction: state.predefined_instructions,
            additional_proofreading_instruction: state.custom_instructions,
            subtitle_setting_attributes: {
              desired_date: state.delivery_date_selected,
              cpl: state.cpl_selected,
              number_lines: state.line_selected,
            }
          }]
        })
        return payload.project_to_post
      } else {
        Object.assign(payload.project_to_post, {
          original_file_path: payload.project_to_post.filepath ? payload.project_to_post.filepath : null,
          url: payload.project_to_post.id && !payload.project_to_post.filepath ? payload.project_to_post.id : null,
          language_id: state.language_selected,
          project_name: state.main_project_selected
        })
        payload.project_to_post.subtitles_attributes.map(subtitle => {
          Object.assign(subtitle, {
            original_file_path: subtitle.filepath,
            proofreading_instruction: [],
            additional_proofreading_instruction: null,
            subtitle_setting_attributes: {
              desired_date: null,
              cpl: null,
              number_lines: null,
            }
          })
        })
        return payload.project_to_post
      }
    },
    postProject({ state, dispatch }, payload) {
      let project
      if(payload.url || payload.project_type == "url") {
        project = state.url_projects.find(p => p.id == payload.id || p.id == payload.project_id)
      } else {
        project = state.projects.find(p => p.id == payload.file_id || p.id == payload.project_id);
      }

      dispatch('createProjectObject', { info_file: payload, project_to_post: project }).then((response) => {
        project = response
      });
      console.log("PROJECT TO POST : ", project);
      console.log("================================");
      
      AxiosNewVideoService.postProject(project)
      .then(response => {
        console.log("Project created : ", response.data)
        EventBus.$emit('get-video-id', response.data.id);
      }).catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          //message: t('layout.shared.errors.error'),
          message: 'Une erreur s\'est produite, veuillez réessayer',
          class : 'ui toast authot',
        });
      })
    }
  },
}