<template>
  <div class="player-container">
    <div class="video-container">
      <div class="video-container-media" @click="playPauseVideo()">
        <div class="video-container-media-block" id="videoBox">
          <video id="videoPlayed" :src="filepath" type="video/mp4" @ratechange="managePlaybackRate()">
            {{ t('editor.player.html_support') }}
          </video>
          <div id="subPlayContainer"></div>
        </div>
      </div>
    </div>
    <div class="controls-container">
      <div class="controls-seekbar">
        <input class="custom-seekbar" @click="onSeekBarClick()" ref="videoSeekbar" id="seekbar_videoID" value="0" type="range"/>
      </div>
      <div class="controls-buttons">
        <div class="control-item time">
          <p id="videoTimer" ref="videoTimer">{{ initialVideoTime }}</p>
        </div>
        <div class="control-item buttons">
          <i class="small fast link backward icon" @click="fastBackward()"></i>
          <i class="small play link icon" @click="playPauseVideo()" v-show="videoPaused"></i>
          <i class="small pause link icon" @click="playPauseVideo()" v-show="!videoPaused"></i>
          <i class="small fast link forward icon" @click="fastForward()"></i>
        </div>
        <div class="control-item settings">
          <div class="video-rate-container">
            <div class="video-rate-container-info" @click="diplayVideoRateButtons()">
              x {{ videoPlaybackRate }}
              <div v-if="videoRateButtonsVisible" class="video-rate-container-info-dropdown">
                <button @click="managePlayRate(0.25)">0.25</button>
                <button @click="managePlayRate(0.5)">0.5</button>
                <button @click="managePlayRate(0.75)">0.75</button>
                <button @click="managePlayRate(1)">{{ t('editor.player.normal_rate') }}</button>
                <button @click="managePlayRate(1.25)">1.25</button>
                <button @click="managePlayRate(1.5)">1.5</button>
                <button @click="managePlayRate(1.75)">1.75</button>
                <button @click="managePlayRate(2)">2</button>
              </div>
            </div>
          </div>
          <i class="small volume link off icon" @click="muteSound()" v-show="videoMuted"></i>
          <i class="small volume link up icon" @click="muteSound()" v-show="!videoMuted"></i>
          <div class="video-time">{{ videoDuration }}</div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../packs/application';

export default {
  props: ["filepath"],
  data () {
    return {
      currentVideo: '/authot_vf.mp4',
      video: {},
      initialVideoTime: "00:00:00",
      videoDuration: null,
      videoPlaybackRate: 1,
      videoPaused: true,
      videoMuted: false,
      videoRateButtonsVisible: false
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    convertSecondsToTime(timeInSeconds) {
      const pad = (num, size) => { return ('000' + num).slice(size * -1) };
      const time = parseFloat(timeInSeconds).toFixed(3);
      const hours = Math.floor(time / 60 / 60);
      const minutes = Math.floor(time / 60) % 60;
      const seconds = Math.floor(time - minutes * 60);
      return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
    },
    isVideoHorizontal() {
      if(this.video.clientHeight < this.video.clientWidth) this.video.classList.add("horizontal");
    },
    updateVideoTimer() {
      this.$refs.videoTimer.innerHTML = this.convertSecondsToTime(this.video.currentTime, "video");
    },
    updateSeekBar() {
      if(isNaN(this.video.duration)) {
        this.$refs.videoSeekbar.value = 0;
      } else {
        const updatedTime = this.video.currentTime * (100 / this.video.duration);
        document.getElementById('seekbar_videoID').value = updatedTime;
      };
    },
    onSeekBarClick() {
      const currentPosition = this.video.duration * ($('#seekbar_videoID').val() / 100);
      this.video.currentTime = currentPosition;
      EventBus.$emit('video-clicked');
    },
    playPauseVideo() {
      this.video.paused ? this.video.play() : this.video.pause();
      this.videoPaused = this.video.paused;
    },
    bigBackward() {
      this.video.currentTime -= 2;
      EventBus.$emit('video-clicked');
    },
    bigForward() {
      this.video.currentTime += 2;
      EventBus.$emit('video-clicked');
    },
    fastBackward() {
      this.video.currentTime -= 0.1;
      EventBus.$emit('video-clicked');
    },
    fastForward() {
      this.video.currentTime += 0.1;
      EventBus.$emit('video-clicked');
    },
    increasePlayRate() {
      if(this.video.playbackRate < 3.9) this.$set(this.video, 'playbackRate', this.video.playbackRate + 0.1);
    },
    decreasePlayRate() {
      if(this.video.playbackRate > 0.6) this.$set(this.video, 'playbackRate', this.video.playbackRate - 0.1);
    },
    managePlayRate(rate) {
      this.$set(this.video, 'playbackRate', rate);
    },
    managePlaybackRate() {
      this.videoPlaybackRate = 
        this.video.playbackRate == 1 ||
        this.video.playbackRate == 0.25 ||
        this.video.playbackRate == 0.75 ||
        this.video.playbackRate == 1.25 ||
        this.video.playbackRate == 1.75 ? 
        this.video.playbackRate :
        this.video.playbackRate.toFixed(1);
    },
    diplayVideoRateButtons() {
      this.videoRateButtonsVisible = !this.videoRateButtonsVisible;
    },
    muteSound() {
      this.video.muted = !this.video.muted;
      this.videoMuted = !this.videoMuted;
    },
    playPartVideo(startDuration) {
      this.video.currentTime = startDuration;
      this.video.play();
      setTimeout(() => {
        this.video.pause();
      }, 600);
    },
    manageKey(event, key) {
      switch(key) {
        case 27:
          // Play/Pause video : ESC
          event.preventDefault();
          this.playPauseVideo();
          break;
        case 112:
          // Decrease play rate : F1
          event.preventDefault();
          this.decreasePlayRate();
          break;
        case 113:
          // Increase play rate : F2
          event.preventDefault();
          this.increasePlayRate();
          break;
        case 118:
          // -2s : F7
          event.preventDefault();
          this.bigBackward();
          break;
        case 119:
          // +2s : F8
          event.preventDefault();
          this.bigForward();
          break;
      }
    },
    manageAltKey(event, key) {
      switch(key) {
        case 32:
          // Play subtitle once : Alt + SPACE
          event.preventDefault();
          this.playPauseVideo();
          break;
        case 37:
          // -0.5s : Alt + LEFT ARROW
          event.preventDefault();
          this.fastBackward();
          break;
        case 39:
          // +0.5s : Alt + RIGHT ARROW
          event.preventDefault();
          this.fastForward();
          break;
      } 
    },
    manageAltShiftKey(event, key) {
      switch(key) {
        // Play / Pause with right hand : Alt + Shift + ENTER
        case 13:
          event.preventDefault();
          this.playPauseVideo();
          break;
      }
    }
  },
  mounted() {
    this.video = document.getElementById("videoPlayed");
    this.video.onloadedmetadata = () => this.videoDuration = this.convertSecondsToTime(this.video.duration);
    this.video.ontimeupdate = () => {
      this.updateVideoTimer();
      this.updateSeekBar();
    };
    EventBus.$on('wave-time', (time) => {
      this.video.currentTime = time;
    });
    EventBus.$on('play-part-subtitle', (durationToStart) => {
      this.playPartVideo(durationToStart);
    });
    $(document).keydown(event => {
      if(event.altKey == true && event.ctrlKey == false && event.shiftKey == false) this.manageAltKey(event, event.keyCode);
      if(event.altKey == false && event.ctrlKey == false && event.shiftKey == false) this.manageKey(event, event.keyCode);
      if(event.shiftKey == true && event.altKey == true && event.ctrlKey == false) this.manageAltShiftKey(event, event.keyCode);
    });
  },
  beforeUpdate() {
    this.isVideoHorizontal();
  }
}
</script>

<style scoped>
</style>