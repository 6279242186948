<template>
  <div class="ui tiny multi-incrust modal" id="multi-incrust-modal">
    <i class="close icon" @click="manageClosingModal()"></i>
    <div class="header">
      <i class="big desktop icon"></i>
      {{ t('authot_elements.modal.incrust_subtitles_title') }}
    </div>
    <div class="content">
      <div class="incrustation-form">
        <p>{{ t('authot_elements.modal.incrust_multiple_subtitles_description') }}</p>
        <div v-for="project in projectsSelected" :key="project.id" class="incrustation-form-project">
          <div>
            <h5>{{ project.title }}</h5>
          </div>
          <div v-for="subtitle in project.subtitles" :key="subtitle.id" class="incrustation-form-project-subtitle">
            <div class="ui checkbox multiple-incrustations">
              <input 
                type="checkbox" 
                name="subtitle-to-incrust" 
                :value="subtitle.id" 
                :id="`subtitle-${subtitle.id}`"
                :disabled="subtitle.state != 10 ? true : false"
                :checked="subtitle.state != 10 ? false : true"
                @change="manageSubtitleToIncrust($event, project.id, subtitle.id)"
              >
              <label :for="`subtitle-${subtitle.id}`">
                {{ subtitle.title }}
                <img class="mini round-flag" :src="require(`../../../assets/img/flags/${subtitle.flag_iso}.png`)">
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button class="authot ui button" @click="postIncrustations()">{{ t('layout.shared.actions.incrust') }}</button>
  </div>
</template>

<script>
import { EventBus } from '../../../packs/application'

export default {
  props: [],
  data() {
    return {
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
    projectsSelected() {
      return this.$store.getters['projects/projects_selected']
    },
    subtitlesToIncrust() {
      return this.$store.getters['projects/subtitles_to_incrust']
    }
  },
  methods: {
    manageClosingModal() {
      $('#multi-incrust-modal').modal('hide')
      this.$store.commit('projects/EMPTY_PROJECTS_SELECTED')
      this.$store.commit('projects/EMPTY_SUBTITLES_TO_INCRUST')
      EventBus.$emit('any-modal-closed')
    },
    manageSubtitleToIncrust(e, videoId, subtitleId) {
      this.$store.commit('projects/UPDATE_SUBTITLES_TO_INCRUST', {isToAdd: e.target.checked, video_id: videoId, subtitle_id: subtitleId })
    },
    postIncrustations() {
      if(this.subtitlesToIncrust.length == 0) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('authot_elements.modal.select_subtitle_error'),
          class : 'ui toast authot',
        });
      } else {
        this.subtitlesToIncrust.map(subtitle => this.$store.dispatch('projects/postIncrustation', subtitle))
        this.manageClosingModal()
      }
    }
  },
  mounted() {
    $('#multi-incrust-modal').modal({
      inverted: true,
      closable: false
    });
  }
}
</script>