<template>
  <div class="main-padding ">
    <CDemo/>
  </div>
</template>

<script>
import CDemo from '../../components/ticket/CDemo'

export default {
  components: {
    CDemo,
  }
}
</script>