import AxiosShopService from '../../services/shop/shop-api';

export const productsStore = {
  namespaced: true,
  state: {
    studioProducts: [],
    top_products: 2,
    studio_auto_quantities: [50, 100, 200, 300],
    studio_proofreading_quantities: [5, 15, 30, 50, 100],
    studio_translation_quantities: [50, 100, 200, 300],
    discovery_auto: true,
    discovery_auto_quantity: 50,
    discovery_translation: true,
    discovery_translation_quantity: 50,
    currencies: [
      {
        name: "Euro (€)",
        value: "EUR",
        symbol: "€"
      },
      {
        name: "Dollar ($)",
        value: "USD",
        symbol: "$"
      },
      {
        name: "Pounds (£)",
        value: "GBP",
        symbol: "£"
      },
      {
        name: "Dollar canadien ($CA)",
        value: "CAD",
        symbol: "$CA"
      },
      {
        name: "Franc Suisse (CHF)",
        value: "CHF",
        symbol: "CHF"
      }
    ]
  },
  getters: {
    top_products: state => {
      return state.studioProducts.filter(product => product.position <= state.top_products)
    },
    other_products: state => {
      return state.studioProducts.filter(product => product.position > state.top_products)
    },
    studio_products: state => {
      return state.studioProducts;
    },
    studio_auto_quantities: state => {
      return state.studio_auto_quantities;
    },
    studio_proofreading_quantities: state => {
      return state.studio_proofreading_quantities;
    },
    studio_translation_quantities: state => {
      return state.studio_translation_quantities;
    },
    discovery_auto_product: state => {
      return state.discovery_auto;
    },
    discovery_auto_product_quantity: state => {
      return state.discovery_auto_quantity;
    },
    discovery_translation_product: state => {
      return state.discovery_translation;
    },
    discovery_translation_product_quantity: state => {
      return state.discovery_translation_quantity;
    },
    currencies: state => {
      return state.currencies;
    }
  },
  mutations: {
    SET_STUDIO_PRODUCTS(state, products) {
      state.studioProducts = [];
      products.map(product => state.studioProducts.push(product));
    }
  },
  actions: {
    getAllProducts({ commit }) {
      AxiosShopService.getProducts()
      .then(response => {
        commit('SET_STUDIO_PRODUCTS', response.data.products);
      });
    }
  }
};