<template>
  <div class="auth">
    <div class="auth-form">
      <div class="left-img">
        <div class="img" style="height: 60vh;"></div>
        <div class="shared-links-items">
          <p>{{ t('user.sign_up.already_signup') }}</p>
          <a class="orange light text" href="/users/sign_in">{{ t('user.sign_in.signin') }}</a>
        </div>
      </div>
      <div class="right-form">
        <h2 class="signup-title">{{ t('user.sign_up.title') }}</h2>
        <p class="offer">{{ t('user.sign_up.offer') }}</p>
        <div v-if="errors.length != 0">
          <div class="error-explanation">
            <div v-for="(error, i) in errors" :key="i">
              <i class="exclamation circle icon"></i>
              {{ error }}
            </div>
          </div>  
        </div>
        <form v-on:submit.prevent="verifyCaptchaValidation()" action="/users" method="post" class="ui form" id="userNewForm">
          <input type="hidden" name="authenticity_token" :value="token">
          
          <div class="right-form-required">
            <label for="user[email]" class="form label">{{ t('user.sign_up.email') }}</label>
            <span>*</span>
          </div>
          <div class="ui left icon input">
            <input type="email" name="user[email]" required :placeholder="t('user.sign_up.email_placeholder')">
            <i class="user outline icon orange"></i>
          </div>

          <div class="right-form-required">
            <label for="user[password]" class="form label">{{ t('user.sign_up.password') }}</label>
            <span>*</span>
          </div>
          <em>({{ t('user.sign_up.password_min_length') }})</em>
          <div class="ui left icon input">
            <input type="password" name="user[password]" required :placeholder="t('user.sign_up.password_placeholder')">
            <i class="lock icon orange"></i>
          </div>

          <div class="right-form-required">
            <label for="user[password_confirmation]" class="form label">{{ t('user.sign_up.password_confirmation') }}</label>
            <span>*</span>
          </div>
          <div class="ui left icon input">
            <input type="password" name="user[password_confirmation]" required :placeholder="t('user.sign_up.password_confirmation_placeholder')">
            <i class="lock icon orange"></i>
          </div>
          
          <div class="name-inputs">
            <div>
              <label for="user[first_name]" class="form label">{{ t('user.sign_up.first_name_placeholder') }}</label>
              <input type="text" name="user[first_name]" class="authot-input" :placeholder="t('user.sign_up.first_name_placeholder')">
            </div>
            <div>
              <label for="user[last_name]" class="form label">{{ t('user.sign_up.last_name_placeholder') }}</label>
              <input type="text" name="user[last_name]" class="authot-input" :placeholder="t('user.sign_up.last_name_placeholder')">
            </div>
          </div>
          <label class="form label">{{ t('user.sign_up.phone') }}</label>
          <div class="ui left labeled input">
            <select name="user[phone_code]" class="ui compact selection dropdown label number">
              <option v-for="(code, i) in phoneCode" :key="i" :value="code.value">{{ code.name }}</option>
            </select>
            <input type="text" name="user[phone]" class="ui input fluid" pattern="\d+" :placeholder="t('user.sign_up.phone_placeholder')">
          </div>

          <label for="user[account_attributes[country]]" class="form label">{{ t('user.sign_up.country') }}</label>
          <div class="ui fluid search selection dropdown authot-input" id="countryDropdown">
            <input type="hidden" name="user[account_attributes[country]]">
            <i class="dropdown icon"></i>
            <div class="default text">{{ t('user.sign_up.country_placeholder') }}</div>
            <div class="menu">
              <div v-for="(country, i) in countries" :key="i" class="item" :data-value="country.code">{{ country.name }}</div>
            </div>
          </div>

          <label for="user[account_attributes[company]]" class="form label">{{ t('user.sign_up.company') }}</label>
          <input type="text" name="user[account_attributes[company]]" class="authot-input" :placeholder="t('user.sign_up.company_placeholder')">

          <div>
            <vue-recaptcha
              @verify="verifyCaptcha($event)"
              @expired="expireCaptcha()"
              :language="locale"
              :loadRecaptchaScript="true"
              :sitekey="siteKey">
            </vue-recaptcha>
            <input type="hidden" name="g-recaptcha-response" :value="captchaData">
          </div>

          <div class="right-form-checkboxes">
            <div class="ui checkbox">
              <input type="checkbox" name="user[notif]" id="user[notif]">
              <label for="user[notif]" class="form label">{{ t('user.sign_up.checkbox_notifications') }}</label>
            </div>
            <div class="ui checkbox">
              <input type="checkbox" name="user[newsletter]" id="user[newsletter]">
              <label for="user[newsletter]" class="form label">{{ t('user.sign_up.checkbox_newsletters') }}</label>
            </div>
            <div class="ui checkbox right-form-required">
              <input type="checkbox" name="user[cgu]" id="user[cgu]" required>
              <label for="user[cgu]" class="form label"><a href="https://app.xn--autht-9ta.com/Authot_CGU_fr.pdf" target="_blank">{{ t('user.sign_up.checkbox_cgu') }}</a></label>
              <span>*</span>
            </div>
          </div>
        
          <input type="hidden" name="commit" value="S'inscrire" id="">
          
          <div class="actions">
            <button type="submit" class="ui little authot button">{{ t('user.sign_up.signup') }}</button>
            <div class="right-form-required">
              <span>*</span>
              <p>{{ t('layout.shared.required_field') }}</p>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import jsonCountries from '../../assets/json/countries.json'
// import dotenv from 'dotenv';

export default {
  props: [
    "token",
    "errors",
    "minimum_password_length"
  ],
  data() {
    return {
      // siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      siteKey: "6LczPEUaAAAAALhm0KvS06sqWbbX3hTjZsf9UgBA",
      countries: jsonCountries,
      phoneCode: [
        {
          value: 33,
          name: "+33"
        },
        {
          value: 44,
          name: "+44"
        },
        {
          value: 34,
          name: "+34"
        }

      ],
      captchaData: null
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
    locale() {
      return this.$store.getters['locales/locale']
    }
  },
  methods: {
    verifyCaptcha(response) {
      if(response) this.captchaData = response;
    },
    expireCaptcha() {
      this.captchaData = null;
    },
    verifyCaptchaValidation() {
      const form = document.getElementById("userNewForm");
      if(this.captchaData != null) {
        form.submit();
      } else {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('user.sign_up.recaptcha_fill'),
          class : 'ui toast authot',
        });
      }
    }
  },
  mounted() {
    $('#countryDropdown').dropdown();
  },
  components: {
    VueRecaptcha,
  }
}
</script>