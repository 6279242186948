<template>
  <div class="user-style-container-header">
    <div class="user-style-container-header_left">
      <h3>{{ t('user.edit.user_styles.title') }}</h3>
      <a class="new-style-link" @click="createStyle()"><i class="icon add circle"></i>{{ t('user.edit.user_styles.add_style') }}</a>
    </div>
    <div class="ui small left icon light input">
      <input type="text" id="searchStyleInput" :placeholder="t('user.edit.user_styles.search_placeholder')" @input="filteredStyles($event)">
      <i class="search icon"></i>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../packs/application';

export default {
  props: [
    "current_user",
  ],
  data() {
    return {
      currentUserId: this.current_user.id,
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    createStyle() {
      EventBus.$emit('open-style-form', null, null, false);
    },
    filteredStyles(e) {
      EventBus.$emit('styles-filtered', e.target.value);
    },
    emptySearchInput() {
      document.getElementById('searchStyleInput').value = "";
    }
  },
  mounted() {
    EventBus.$on('back-to-styles', () => this.emptySearchInput());
  }
}
</script>