// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require('jquery');
require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');
global.$ = require('jquery')

// Vue modules needed
import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
// import store from '../store';
import I18n from '../assets/javascript/i18n-js/index.js.erb'
import TurbolinksAdapter from 'vue-turbolinks';
import ElementUI from 'element-ui';
import lang from 'element-ui/lib/locale/lang/fr';
import locale from 'element-ui/lib/locale';
import VueGoodTablePlugin from 'vue-good-table';
import VCalendar from 'v-calendar';
import VueTour from 'vue-tour/src/main';
import 'es6-promise/auto';
import 'fomantic-ui-css/semantic.min.css';
import '../assets/javascript/semantic.min.js';
import '../assets/javascript/fomantic-elements.js';
import '../assets/stylesheets/application.sass';
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-good-table/dist/vue-good-table.css';
import '../assets/javascript/user/user_registration.js';

// Vuex Store modules
import { localesStore } from '../store/modules/locales'
import { newProjectsStore } from '../store/modules/new-project'
import { projectsStore } from '../store/modules/projects'
import { productsStore } from '../store/modules/products'
import { orderStore } from '../store/modules/order'
import { userFontsStore } from '../store/modules/user-fonts'
import { creditStore } from '../store/modules/credit'
import { demonstrationStore } from '../store/modules/demonstration'

// Vue Views
import VVideoEdit from '../views/video/VVideoEdit.vue';
import VVideoIndex from '../views/video/VVideoIndex.vue';
import VVideoNew from '../views/video/VVideoNew.vue';
import VHome from '../views/home/VHome.vue';
import VOrder from '../views/order/VOrder.vue';
import VOrderSuccessful from '../views/order/VOrderSuccessful.vue';
import VTicketNew from '../views/ticket/VTicketNew.vue';
import VUserSignup from '../views/user/VUserSignup.vue';
import VUserCredit from '../views/user/user-account/VUserCredit.vue';
import VUserOrders from '../views/user/user-account/VUserOrders.vue';
import VUserStyle from '../views/style/VUserStyle';
import VUserApi from '../views/user/user-account/VUserApi.vue';
import VLegals from '../views/legals/VLegals';
import VDemo from '../views/demo/VDemo';
import CFooter from '../components/layout/CFooter';

Vue.use(Vuex);
Vue.use(ElementUI);
Vue.use(VCalendar);
Vue.use(VueTour);
Vue.use(VueGoodTablePlugin);
Vue.use(TurbolinksAdapter);
require('vue-tour/dist/vue-tour.css');
locale.use(lang);

// Vue EventBus
export const EventBus = new Vue();

// Vue Store
export const store = new Vuex.Store({
  modules: {
    locales: localesStore,
    newProjects: newProjectsStore,
    projects: projectsStore,
    products: productsStore,
    order: orderStore,
    userFonts: userFontsStore,
    credit: creditStore,
    demonstration: demonstrationStore,
  },
});

export const i18n = I18n;

// Vue components to inject in Rails Views
Vue.component('VVideoEdit', VVideoEdit);
Vue.component('VVideoIndex', VVideoIndex);
Vue.component('VVideoNew', VVideoNew);
Vue.component('VHome', VHome);
Vue.component('VOrder', VOrder);
Vue.component('VOrderSuccessful', VOrderSuccessful);
Vue.component('VTicketNew', VTicketNew);
Vue.component('VUserSignup', VUserSignup);
Vue.component('VUserCredit', VUserCredit);
Vue.component('VUserOrders', VUserOrders);
Vue.component('VUserStyle', VUserStyle);
Vue.component('VUserApi', VUserApi);
Vue.component('VLegals', VLegals);
Vue.component('VDemo', VDemo);
Vue.component('CFooter', CFooter);

document.addEventListener('turbolinks:load', () => {
  const app = new Vue({
    store,
    i18n,
    el: '[data-framework="vue"]'
  })
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../assets/img', true)
// const imagePath = (name) => images(name, true)