<template>
  <div class="ui modal tiny new-video" id="options-project-modal">
    <div class="header">
      <img class="product_img" :src="require(`../../../assets/img/products/${productSelected.key}-selected.svg`)" alt="Studio product">
      <h3>{{ t('project.new_project.options.title') }}</h3>
    </div>

    <div class="content">
      <div class="video-language">
        <div class="required">
          <label>{{ t('project.new_project.new_video.language_description') }}</label>
          <span>*</span>
        </div>
        <div class="languages-grid" :class="{'opened': languageGridOpened}">
          <div v-for="language in languages" :key="language.id" class="languages-grid-item">
            <div class="languages-grid-item-container" @click="getSelectedLanguage(language.id)">
              <div 
                class="languages-grid-item-container-top languages-grid"
                :class="{ 'active' : language.id == languageSelected }"
              >
                <img
                  class="languages-grid-item-container-top-flag"
                  :src="require(`../../../assets/img/flags/${language.flag_iso}.png`)"
                >
              </div>
              <p 
                class="languages-grid-item-container-name"
                :class="{ 'active' : language.id == languageSelected }"
              >
                {{ language.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="language-show-action" v-show="!languageGridOpened" @click="languageGridOpened = !languageGridOpened">
          <i class="plus square outline orange large icon"></i>
          <p>{{ t('project.new_project.new_video.see_more') }}</p>
        </div>
        <div class="language-show-action" v-show="languageGridOpened" @click="languageGridOpened = !languageGridOpened">
          <i class="minus square outline orange large icon"></i>
          <p>{{ t('project.new_project.new_video.see_less') }}</p>
        </div>
      </div>

      <p>{{ t('project.new_project.new_video.fill_project') }}</p>
      <div class="ui fluid search selection clearable dropdown projects" id="user-project-dropdown">
        <input type="hidden" class="project-name-input" v-model="mainProjectSelected">
        <i class="dropdown icon"></i>
        <div class="default text">{{ t('project.new_project.new_video.project_placeholder') }}</div>
        <div class="menu">
          <div class="item" :data-value="project.name" v-for="(project, i) in userProjects" :key="i">
            {{ project.name }}
          </div>
        </div>
      </div>

      <div v-if="!productSelected.key.includes('manual')" class="content studio_product">
        <div class="subtitle-infos">
          <div class="subtitle-infos-details">
            <div class="subtitle-infos-details-labels">
              <p class="dark_grey text">{{ t(`project.new_project.${productSelected.key}.credit_remaining`) }}</p>
              <CTooltip v-if="productSelected.key.includes('auto')" :text="t('project.new_project.studio_manual.estimated_deadline_tooltip')" position="bottom">
                <p class="dark_grey text" style="display: block;">
                  {{ t(`project.new_project.studio_manual.estimated_deadline`) }}
                  <i class="icon link question circle outline"></i>
                </p>
              </CTooltip>
              <p v-else class="dark_grey text" v-html="t(`project.new_project.studio_manual.deadline`)"></p>
              <CTooltip :text="t('project.new_project.studio_manual.cpl_tooltip')" position="bottom">
                <p class="dark_grey text required">
                  {{ t('project.new_project.studio_manual.cpl') }} 
                  <i class="icon link question circle outline"></i>
                  <span>*</span>
                </p>
              </CTooltip>
              <CTooltip :text="t('project.new_project.studio_manual.line_tooltip')" position="bottom">
                <p class="dark_grey text required">
                  {{ t('project.new_project.studio_manual.line') }}
                  <i class="icon link question circle outline"></i>
                  <span>*</span>
                </p>
              </CTooltip>
            </div>
            <div class="subtitle-infos-details-datas">
              <p>{{ creditConverted(productSelected.id) }}</p>
              <p v-if="productSelected.key.includes('auto')" type="date">{{ t('project.new_project.studio_manual.estimated_deadline_result') }}</p>
              <input v-else type="date" :min="minDeadline" v-model="deliveryDateSelected" required>
              <input type="number" min="20" max="120" step="1" v-model="cplSelected" required>
              <input type="number" min="1" max="2" step="1" v-model="lineSelected" required>
            </div>
          </div>
          <div v-if="productSelected.key.includes('proofreading')">
            <p class="dark_grey text">{{ t(`project.new_project.${productSelected.key}.predefined_instructions_label`) }}</p>
            <div class="ui fluid multiple selection dropdown instruction">
              <input type="hidden" name="instruction">
              <div class="default text">{{ t(`project.new_project.${productSelected.key}.predefined_instructions_placeholder`) }}</div>
              <i class="dropdown icon"></i>
              <div class="menu">
                <div 
                  class="item" 
                  v-for="instruction in proofreadingInstructionsList" 
                  :key="instruction.id"
                  v-html="t(`project.new_project.${productSelected.key}.instructions_list.${instruction.id}`)"
                ></div>
              </div>
            </div>
          </div>
          <div v-if="productSelected.key.includes('proofreading')">
            <p class="dark_grey text">{{ t(`project.new_project.${productSelected.key}.custom_instructions_label`) }}</p>
            <textarea
              v-if="productSelected.key.includes('proofreading')"
              v-model="customInstructions"
              :placeholder="t(`project.new_project.${productSelected.key}.custom_instructions_placeholder`)"
              name="" id="" rows="4">
            </textarea>
          </div>
        </div>
      </div>
      <div class="actions">
        <div></div>
        <button
          class="green authot ui button"
          @click="checkOptionsProject()">
            {{ t('layout.shared.actions.valid') }}
        </button>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import CUploader from '../../uploader/CUploader.vue'
import CTooltip from '../../authotElements/CTooltip.vue'
import { truncateKey, convertQuantityToTime, getCalendarMinimumDate, displayToast } from '../../../utils/functions'
import instructionsList from '../../../assets/json/proofreading_instructions_list.json'
import { EventBus } from '../../../packs/application';

export default {
  props: ["current_user"],
  data() {
    return {
      languageGridOpened: false,
      minDeadline: null,
      minCPL: 20,
      maxCPL: 120,
      minLine: 1,
      maxLine: 2,
      proofreadingInstructionsList: instructionsList,
    }
  },
  computed: {
    languages() {
      return this.$store.getters['newProjects/languages']
    },
    t() {
      return this.$store.getters['locales/t']
    },
    credits() {
      return this.$store.getters['credit/all_credits']
    },
    userProjects() {
      return this.$store.getters['newProjects/user_projects']
    },
    productSelected() {
      return this.$store.getters['newProjects/product_selected']
    },
    deliveryDateSelected: {      
      get() {
        return this.$store.getters['newProjects/delivery_date_selected']
      },
      set(value) {
        this.$store.commit('newProjects/SET_DATE', value);
      }
    },
    cplSelected: {      
      get() {
        return this.$store.getters['newProjects/cpl_selected']
      },
      set(value) {
        this.$store.commit('newProjects/SET_CPL', value);
      }
    },
    lineSelected: {
      get() {
        return this.$store.getters['newProjects/line_selected']
      },
      set(value) {
        this.$store.commit('newProjects/SET_LINE', value);
      }
    },
    predefinedInstructions() {
      return this.$store.getters['newProjects/predefined_instructions']
    },
    customInstructions: {
      get() {
        return this.$store.getters['newProjects/custom_instructions']
      },
      set(value) {
        this.$store.commit('newProjects/SET_CUSTOM_INSTRUCTION', value);
      }
    }, 
    languageSelected() {
      return this.$store.getters['newProjects/language_selected']
    }, 
    mainProjectSelected() {      
      return this.$store.getters['newProjects/main_project_selected']
    },
  },
  methods: {
    openModal() {
      $('#options-project-modal').modal('show')
    },
    closeModal() {
      $(`#options-project-modal`).modal('hide')
      this.languageGridOpened = false
    },
    getCredit(productId) {
      return this.credits.find(credit => credit.product_id == productId).quantity
    },
    checkOptionsProject() {
      this.productSelected.key.includes('manual') ? this.closeModal() : this.checkOptionsDatasProject();
    },
    checkOptionsDatasProject() {
      if(this.deliveryDateSelected == "" || this.cplSelected == "" || this.lineSelected == "" ) {
        displayToast('error', this.t('project.new_project.studio_manual.errors.required_field_missing'))
      } else if(!Number.isInteger(+this.cplSelected) || !Number.isInteger(+this.lineSelected)) {
        displayToast('error', this.t('project.new_project.studio_manual.errors.required_field_wrong'))
      } else if(this.cplSelected < this.minCPL || this.cplSelected > this.maxCPL) {
        displayToast('error', this.t('project.new_project.studio_manual.errors.cpl'))
      } else if(this.lineSelected < this.minLine || this.lineSelected > this.maxLine) {
        displayToast('error', this.t('project.new_project.studio_manual.errors.line'))
      } else {
        this.closeModal()
      }
    },
    initManualForm() {
      $('.ui.search.languages.subtitle').dropdown()
    },
    initProductForm() {
      $('.ui.dropdown.instruction').dropdown({
        clearable: true,
        forceSelection: false,
        showOnFocus: false,
        onAdd: (value, text) => {
          this.managePredefinedInstruction(true, text)
        },
        onRemove: (value, text) => {
          this.managePredefinedInstruction(false, text)
        },
      })
      this.minDeadline = getCalendarMinimumDate(1)
    },
    truncateTitle(stringToTruncate) {
      return truncateKey(stringToTruncate)
    },
    creditConverted(productId) {
      const creditQuantity = this.getCredit(productId)
      return convertQuantityToTime(creditQuantity)
    },
    getSelectedLanguage(languageId) {
      this.$store.commit('newProjects/SET_SELECTED_LANGUAGE', languageId)
      if(this.productSelected.key.includes('manual')) {
        this.$store.commit('newProjects/UPDATE_SUBTITLES_LANGUAGE', languageId);
        EventBus.$emit('update-new-project-subtitles-language', languageId);
      }
    },
    managePredefinedInstruction(isAdded, instruction) {
      isAdded ?
        this.$store.commit('newProjects/SET_PREDEFINED_INSTRUCTION', instruction) :
        this.$store.commit('newProjects/REMOVE_PREDEFINED_INSTRUCTION', instruction)
    },
    updateIfAddProjectAvailable() {
      this.$store.commit('newProjects/CHECK_IF_ADD_PROJECT_AVAILABLE')
    },
  },
  mounted() {
    $("#options-project-modal").modal({
      inverted: true,
      closable: false,
      autofocus: false,
    })
    this.productSelected.key.includes('manual') ? this.initManualForm() : this.initProductForm()
    $("#user-project-dropdown").dropdown({
      allowAdditions: true,
      hideAdditions: false,
      clearable: true,
      forceSelection: false,
      message: {
        addResult: this.t('authot_elements.dropdown.click_to_add'),
      },
      onChange: (value) => this.$store.commit('newProjects/SET_MAIN_PROJECT_SELECTED', value)
    })
    if(this.deliveryDateSelected == null) this.deliveryDateSelected = getCalendarMinimumDate(1)
  },
  components: {
    CUploader,
    CTooltip
  }
}
</script>

<style scoped>
</style>