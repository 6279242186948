<template>
  <div :class="`authot uploader multi-uploader`">
    <div v-show="documents.length > 0" class="file-area opened" :id="`file-area-multiple-${document.uniqueIdentifier}`" v-for="(document, i) in documents" :key="document.uniqueIdentifier">
      <div class="file-area-infos">
        <div class="file-infos">
          <i class="grey large file outline icon"></i>
          <div class="required" v-if="acceptSubfile && document.subDocuments.length == 0">
            <label class="form label" style="margin-bottom: 0px;"><p :title="document.fileName">{{ truncateString(document.fileName) }}</p></label>
            <span>*</span>
          </div>
          <p v-else :title="document.fileName">{{ truncateString(document.fileName) }}</p>
        </div>      
        <div class="file-actions">
          <i v-if="acceptSubfile" class="green large link plus circle icon" :title="t('authot_elements.uploader.add_subtitles')" @click="openFileBrowser(document.uniqueIdentifier, false)"></i>
          <i v-if="acceptSubfile" class="green large link chevron up circle icon" :id="`file-area-multiple-chevron-${document.uniqueIdentifier}`" :title="t('authot_elements.uploader.show_subtitles')" @click="showSubfileList(document.uniqueIdentifier)"></i> 
          <i v-if="acceptSubfile" class="red large link times circle icon" :title="t('authot_elements.uploader.delete_video')" @click="removeParentDocument(document.uniqueIdentifier, i)"></i>
          <i v-else class="red large link times circle icon" :title="t('authot_elements.uploader.delete_video')" @click="removeDocument(document.uniqueIdentifier, i)"></i>
        </div>
      </div>

      <div v-show="document.subDocuments" class="file-area subfiles" v-for="subDocument in document.subDocuments" :key="subDocument.uniqueIdentifier">
        <div class="file-area-subfiles">
          <div class="subfile-infos">
            <p :title="subDocument.fileName">
              <i class="grey file outline icon"></i>
              {{ truncateString(subDocument.fileName) }}
            </p>
          </div>
          <span v-if="subDocument.languageId" class="subfile-language" @click="openLanguagesModal(subDocument.languageId, subDocument.uniqueIdentifier, document.uniqueIdentifier, false)">          
            <span v-for="language in languages" :key="language.flag_iso">
              <p v-show="language.id == subDocument.languageId">
                <img class="mini round-flag" :src="require(`../../assets/img/flags/${language.flag_iso}.png`)">
                {{ language.name }}
              </p>
            </span>
          </span>
          <span v-else class="subfile-language" @click="openLanguagesModal(subDocument.languageId, subDocument.uniqueIdentifier, document.uniqueIdentifier, false)">   
            <div class="required">
              <label class="form label">{{ t('authot_elements.uploader.no_language_filled') }}</label>
              <span>*</span>
            </div>
          </span>
          <i class="red link times circle icon" @click="removeSubDocument(subDocument.uniqueIdentifier, document.uniqueIdentifier)"></i>
        </div>
      </div>
      <div v-show="document.subDocuments && document.subDocuments.length == 0 && acceptSubfile" class="file-area subfiles">
        <div class="required" v-if="acceptSubfile && document.subDocuments.length == 0">
          <label class="form label">{{ t('authot_elements.uploader.no_subtitles_filled') }}</label>
          <span>*</span>
        </div>
      </div>
    </div>

    <div v-show="urls.length > 0" class="file-area opened" :id="`file-area-multiple-${i}`" v-for="(url, i) in urls" :key="url.url">
      <div class="file-area-infos">
        <div class="file-infos">
          <i class="grey large file outline icon"></i>
          <div class="required" v-if="acceptSubfile && url.subDocuments.length == 0">
            <label class="form label" style="margin-bottom: 0px;"><p :title="url.url">{{ truncateString(url.url) }}</p></label>
            <span>*</span>
          </div>
          <p v-else :title="url.url">{{ truncateString(url.url) }}</p>
        </div>
        
        <div>
          <i v-if="acceptSubfile" class="green large link plus circle icon" title="Ajouter des sous-titres au lien url"  @click="openFileBrowser(url.url, true)"></i>  
          <i v-if="acceptSubfile" class="green large link chevron up circle icon" title="Afficher les sous-titres du lien url" :id="`file-area-multiple-chevron-${i}`" @click="showSubfileList(i)"></i> 
          <i class="red large link times circle icon" @click="removeUrl(url, i)"></i>
        </div>
      </div>

      <div v-show="url.subDocuments" class="file-area subfiles" v-for="subDocument in url.subDocuments" :key="subDocument.uniqueIdentifier">
        <div class="file-area-subfiles">
          <div class="subfile-infos">
            <p :title="subDocument.fileName">
              <i class="grey file outline icon"></i>
              {{ truncateString(subDocument.fileName) }}
            </p>
          </div>
          
          <span v-if="subDocument.languageId" class="subfile-language" @click="openLanguagesModal(subDocument.languageId, subDocument.uniqueIdentifier, url.url, true)">          
            <span v-for="language in languages" :key="language.flag_iso">
              <p v-show="language.id == subDocument.languageId">
                <img class="mini round-flag" :src="require(`../../assets/img/flags/${language.flag_iso}.png`)">
                {{ language.name }}
              </p>
            </span>
          </span>
          <span v-else class="subfile-language" @click="openLanguagesModal(subDocument.languageId, subDocument.uniqueIdentifier, url.url, true)">    
            <div class="required">
              <label class="form label">{{ t('authot_elements.uploader.no_language_filled') }}</label>
              <span>*</span>
            </div>
          </span>
          <i class="red link times circle icon" @click="removeSubUrl(subDocument.uniqueIdentifier, url.url)"></i>
        </div>
      </div>

      <div v-show="url.subDocuments && url.subDocuments.length == 0 && acceptSubfile" class="file-area subfiles">
        <div class="required" v-if="acceptSubfile && url.subDocuments.length == 0">
          <label class="form label">{{ t('authot_elements.uploader.no_subtitles_filled') }}</label>
          <span>*</span>
        </div>
      </div>
    </div>
  
    <div :id="dropId" class="drop-area">
      <i class="grey big file outline icon"></i>
      <p v-html="t('authot_elements.uploader.description_multiple')"></p>
      <button @click="openFileBrowser()" class="ui green authot button">{{ t('authot_elements.uploader.btn_select') }}</button>
      <button style="visibility: hidden;" type="button" :id="browseId" class="ui green authot button"></button>
      <p>{{ t('editor.shortcuts.or') }}</p>
      <div class="ui action input">
        <div class="ui multiple search selection dropdown" id="url-selector-dropdown">
          <input type="hidden">
          <div class="default text">{{ t('authot_elements.uploader.placeholder_insert_url') }}</div>
        </div>
        <button class="ui green icon button" @click="checkIfUrlsAreRecordable()">
          <i class="plus icon"></i>
        </button>
      </div>
    </div>
    <CUploaderLanguagesModal 
      ref="uploaderLanguagesModal"
      :languageSelected="subDocumentLanguageId"
      @emitSubDocLanguage="updateSubDocLanguage"
    >
    </CUploaderLanguagesModal>
  </div>
</template>

<script>
import Resumable from 'resumablejs'
import CUploaderLanguagesModal from '../video/modals/CUploaderLanguagesModal.vue'
import { truncateKey, displayToast, splitStringInArray, checkIfValidUrl } from '../../utils/functions'
import { EventBus } from '../../packs/application'

export default {
  props: ["dropId", "browseId", "fileAccepted", "additionalFileAccepted", "acceptSubfile", "defaultLanguage","languages"],
  data() {
    return {
      r: {},
      documents: [],
      urls: [],
      parentFileId: null,
      isUrl: false,
      subDocumentLanguageId: null,
      subDocumentId: null,
    }
  },
  computed: {
    productSelected(){
      return this.$store.getters['newProjects/product_selected']
    },
    projects() {
      return this.$store.getters['newProjects/projects']
    },
    t() {
      return this.$store.getters['locales/t']
    },
  },
  methods: {
    truncateString(stringData) {
      return truncateKey(stringData)
    },
    showSubfileList(elementId) {
      let element = $(`#file-area-multiple-${elementId}`)
      let elementClicked = $(`#file-area-multiple-chevron-${elementId}`)
      if(element.hasClass('opened')) {
        element.removeClass('opened')
        elementClicked.removeClass('up')
        element.addClass('closed')
        elementClicked.addClass('down')
      } else {
        element.removeClass('closed')
        elementClicked.removeClass('down')
        element.addClass('opened')
        elementClicked.addClass('up')
      }
    },
    removeParentDocument(identifier, index) {
      this.documents.find(document => document.uniqueIdentifier == identifier).subDocuments.map(subDocument => this.removeDocument(subDocument.uniqueIdentifier, null))
      this.removeDocument(identifier, index)
    },
    removeSubDocument(identifier, parentIdentifier) {
      const parentDocument = this.documents.find(doc => doc.uniqueIdentifier == parentIdentifier)
      parentDocument.subDocuments.map((subDocument, index) => {
        if(subDocument.uniqueIdentifier == identifier) {
          parentDocument.subDocuments.splice(index, 1)
          this.removeDocument(identifier, null)
          this.$emit("emitFileDestroy", { uniqueIdentifier: identifier, parentFileId: parentIdentifier, })
        }
      })
    },
    removeDocument(identifier, index) {
      const fileRemoved = this.r.getFromUniqueIdentifier(identifier)
      this.r.removeFile(fileRemoved)
      if(index != null) {
        this.documents.splice(index, 1)
        this.$emit("emitFileDestroy", identifier)
      }
    },
    pushFile(file) {
      if(this.acceptSubfile && this.parentFileId) {
        this.documents.find(document => document.uniqueIdentifier == this.parentFileId).subDocuments.push({
          fileName: file.fileName,
          uniqueIdentifier: file.uniqueIdentifier,
          languageId: this.defaultLanguage
        })
        this.$emit("emitInfoFile", { fileName: file.fileName, uniqueIdentifier: file.uniqueIdentifier, parentFileId: this.parentFileId })
      } else if(this.acceptSubfile && !this.parentFileId) {
        this.documents.push({
          fileName: file.fileName,
          uniqueIdentifier: file.uniqueIdentifier,
          subDocuments : []
        })
        this.$emit("emitInfoFile", { fileName: file.fileName, uniqueIdentifier: file.uniqueIdentifier })
      } else {
        this.documents.push({
          fileName: file.fileName,
          uniqueIdentifier: file.uniqueIdentifier,
        })
        this.$emit("emitInfoFile", { fileName: file.fileName, uniqueIdentifier: file.uniqueIdentifier })
      }
    },
    removeSubUrl(identifier, parentIdentifier) {
      const parentDocument = this.urls.find(url => url.url == parentIdentifier)
      parentDocument.subDocuments.map((subDocument, index) => {
        if(subDocument.uniqueIdentifier == identifier) {
          parentDocument.subDocuments.splice(index, 1)
          this.removeDocument(identifier, null)
          this.$emit("emitFileDestroy", { uniqueIdentifier: identifier, parentFileId: parentIdentifier, url: parentIdentifier })
        }
      })
    },
    removeUrl(url, index) {
      if(this.acceptSubfile) this.urls.find(url => url.url == url.url).subDocuments.map(subDocument => this.removeDocument(subDocument.uniqueIdentifier, null))
      this.urls.splice(index, 1)
      this.$emit("emitFileDestroy", url)
    },
    pushUrl(file) {
      if(this.acceptSubfile && this.parentFileId) {
        this.urls.find(url => url.url == this.parentFileId).subDocuments.push({
          fileName: file.fileName,
          uniqueIdentifier: file.uniqueIdentifier,
          languageId: this.defaultLanguage
        })
        this.$emit("emitInfoFile", { fileName: file.fileName, uniqueIdentifier: file.uniqueIdentifier, parentFileId: this.parentFileId, url: this.parentFileId })
      } else if (this.acceptSubfile && !this.parentFileId) {
        this.urls.push({
          url: file,
          subDocuments: []
        })
        this.$emit("emitInfoFile", { url: file })
      } else {
        this.urls.push({
          url: file
        })
        this.$emit("emitInfoFile", { url: file })
      }
    }, 
    checkIfUrlAlreadyPushed(url) {
      return this.urls.find(pushedUrl => pushedUrl.url == url)
    },
    checkIfUrlsAreRecordable() {
      this.parentFileId = null
      let urlsSelectedArray = splitStringInArray($('#url-selector-dropdown').dropdown('get value'), ",")
      urlsSelectedArray.map((url, i) => {
        this.checkIfUrlAlreadyPushed(url) ?
          displayToast("error", `${this.t('authot_elements.uploader.error_url_exist')} : "${url}"`) :
          checkIfValidUrl(url) ?
            this.pushUrl(url):
            displayToast("error", `${this.t('authot_elements.uploader.error_url_invalid')} : "${url}"`)
        if(i == urlsSelectedArray.length - 1) $('#url-selector-dropdown').dropdown('clear')
      })
    },
    upload() {
      this.r.upload()
    },
    openFileBrowser(fileId , acceptUrl) {
      fileId ? this.initAdditionalFileTypeParams(fileId, acceptUrl) : this.initInitialFileTypeParams()
      $('#' + this.browseId).click()
    },
    initInitialFileTypeParams() {
      $('#' + this.browseId + " input")[0].accept = this.fileAccepted
      this.r.opts.fileType = this.fileAccepted    
      this.parentFileId = null
      this.isUrl = false
    },
    initAdditionalFileTypeParams(fileId, acceptUrl) {
      $('#' + this.browseId + " input")[0].accept = this.additionalFileAccepted
      this.r.opts.fileType = this.additionalFileAccepted      
      this.parentFileId = fileId
      this.isUrl = acceptUrl ? true : false
    },
    removeSrt(identifier){
      const fileRemoved = this.r.getFromUniqueIdentifier(identifier)
      this.r.removeFile(fileRemoved)
      this.$emit("emitFileDestroy", identifier)
    },
    openLanguagesModal(languageId, subDocumentId, parentId, isUrl) {
      this.subDocumentLanguageId = languageId      
      this.subDocumentId = subDocumentId
      this.parentFileId = parentId
      this.isUrl = isUrl
      this.$refs["uploaderLanguagesModal"].openModal()
    },
    updateSubDocLanguage(languageId) {
      let parentProject = this.isUrl ? this.urls.find(url => url.url == this.parentFileId) : this.documents.find(document => document.uniqueIdentifier == this.parentFileId)
      parentProject.subDocuments.find(subDocument => subDocument.uniqueIdentifier == this.subDocumentId).languageId = languageId
      this.$refs["uploaderLanguagesModal"].closeModal()
      this.$emit("emitUpdateSubDocLanguage", { uniqueIdentifier: this.subDocumentId, languageId: languageId, parentFileId: this.parentFileId, url: this.isUrl })
    },
    updateSubDocsLanguage(languageId){
      this.documents.map(document => {
        if(document.subDocuments) {
          document.subDocuments.map(sub => {
            if(!sub.languageId) sub.languageId = languageId
          })
        }          
      })
      
      this.urls.map(url => {
        if(url.subDocuments) {
          url.subDocuments.map(sub => {
            if(!sub.languageId) sub.languageId = languageId
          })
        }    
      })
    }
  },
  mounted() {
    this.r = new Resumable({
      testChunks: true,
      fileType: this.fileAccepted,
      target: '/upload',
      simultaneousUploads: 1,
      headers: { 
        'X-CSRF-Token' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
    })
    this.r.assignBrowse($('#' + this.browseId))
    this.r.assignDrop($('#' + this.dropId))
    this.r.on('fileAdded', (file) => {
      this.isUrl ? this.pushUrl(file) : this.pushFile(file)
    })
    this.r.on('fileProgress', (file) => {
      EventBus.$emit('file-progress', { fileId: file.uniqueIdentifier , fileProgress: (file.progress()*100) } )
    })
    this.r.on('complete', () => {
      EventBus.$emit('upload-completed')
    })
    this.r.on('fileSuccess', (file, message) => {
      const response = JSON.parse(message)
      this.$emit("emitFilepath", { file_id: file.uniqueIdentifier, file_path: response.file_path })
    })
    $('#' + this.dropId).on('dragover', () => this.initInitialFileTypeParams())
    $('#url-selector-dropdown').dropdown({
      clearable: true,
      allowAdditions: true
    })
    EventBus.$on('update-new-project-subtitles-language', (languageId) => {
      this.updateSubDocsLanguage(languageId)
    }) 
  },
  components: {
    CUploaderLanguagesModal
  }
}
</script>