<template>
  <div class="ticket-form">
    <div class="ticket-form-item">
      <h2>{{ t('layout.main_title.contact_us') }}</h2>
      <p v-html="t('contact.logout_sentence')"></p>
      <form v-on:submit.prevent="checkUser()" class="ui form">
        <div v-if="!current_user">
          <div class="required">
            <label class="form label">{{ t('contact.email') }}</label>
            <span>*</span>
          </div>
          <input type="email" :placeholder="t('contact.email_placeholder')" v-model="ticket.email" class="shadow input" required>
        </div>
        <div v-else>
          <div class="required">
            <label class="form label">{{ t('contact.contact') }}</label>
            <span>*</span>
          </div>
          <select class="dropdown fluid select_service" v-model="ticket.service" required>
            <option value="1">{{ t('contact.contact_commercial') }}</option>
            <option value="2">{{ t('contact.contact_operational') }}</option>
            <option value="3">{{ t('contact.contact_technical') }}</option>
          </select>
        </div>
        <div>
          <div class="required">
            <label class="form label">{{ t('contact.subject') }}</label>
            <span>*</span>
          </div>
          <input type="text" v-model="ticket.subject" :placeholder="t('contact.subject_placeholder')" class="shadow input" required>
        </div>
        <div>
          <div class="required">
            <label class="form label">{{ t('contact.message') }}</label>
            <span>*</span>
          </div>
          <textarea v-model="ticket.message" :placeholder="t('contact.message_placeholder')" class="ticket-textarea" required></textarea>
        </div>
        
        <div v-if="current_user">
          <label class="form label">{{ t('contact.file') }}</label>

          <div class="ui fluid search selection dropdown file">
            <input type="hidden">
            <i class="dropdown icon"></i>
            <div class="default text">{{ t('contact.file_placeholder') }}</div>
            <div class="menu">
              <div class="item" :data-value="video.title" v-for="(video, i) in userVideos" :key="i">
                {{ video.title }}
              </div>
            </div>
          </div>
        </div>
        
        <div class="actions">
          <button type="submit" class="ui little button authot">{{ t('layout.shared.actions.send') }}</button>
          <div class="required">
            <span>*</span>
            <p>{{ t('layout.shared.required_field') }}</p>
          </div>
        </div>
      </form>
    </div>
    <div class="ticket-form-item">
      <img class= "ui image" src="../../assets/img/ticket/illustration-contact.svg" alt="">
    </div>
  </div>
</template>

<script>
import AxiosTicketService from '../../services/ticket/new-ticket'
import AxiosVideoService from '../../services/video/video-index'

export default {
  props: [
    "current_user"
  ],
  data() {
    return {
      userVideos: {},
      ticket: {
        subject: null,
        service: null,
        message: null,
        email: null,
        file: null
      }
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    checkUser() {
      this.current_user == null ? this.sendTicket() : this.checkService();
    },
    checkService() {
      if(this.ticket.service == null || this.ticket.service == "") {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('contact.error_service'),
          class : 'ui toast authot',
        });
      } else {
        this.sendTicket();
      }
    },
    sendTicket() {
      AxiosTicketService.postTicket(this.ticket)
      .then(response => {
        if(response.status == 201) {
          sessionStorage.isSendTicket = true
          sessionStorage.ticketResponse = response.data.message;
          window.location.href = '/';
        }
      }).catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class: 'ui toast authot'
        })
      });
    },
  },
  mounted() {
    $('.select_service').dropdown({
      placeholder: this.t('contact.contact_placeholder')
    });
    
    if(this.current_user) {
      AxiosVideoService.getVideos()
      .then(response => {
        this.userVideos = response.data.videos;
        $('.ui.search.dropdown.file').dropdown({
          allowAdditions: true,
          hideAdditions: false,
          clearable: true,
          forceSelection: false,
          value: this.ticket.file,
          showOnFocus: false,
          message: {
            addResult: this.t('authot_elements.dropdown.click_to_add'),
          },
          onChange: (value) => {
            this.ticket.file = value;
          },
        });
      });
    }
  },
}
</script>