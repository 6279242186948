<template>
  <div class="video-new-product_selector">
    <div class="video-new-product_selector-header">
      <h2>{{ t('project.new_project.products_title') }}</h2>
      <h4>{{ t('project.new_project.products_title_step_1') }}</h4>
    </div>
    <div class="video-new-product_selector-products">
      <CTooltip :text="t('project.new_project.studio_manual.product_description')" :isLargeContent="true">
        <div 
          class="product-card" 
          @click="selectProduct('studio_manual', null, 'Sélection manuelle')"
          :class="{
            'selected' : productSelected != null && productSelected.key == 'studio_manual',
            'hovered' : uploadProductHovered 
          }"
          @mouseover="uploadProductHovered = true"
          @mouseleave="uploadProductHovered = false"
        >
          <img v-if="productSelected != null && productSelected.key == 'studio_manual' ||uploadProductHovered" :src="require(`../../../assets/img/products/studio_manual-selected.svg`)" alt="">
          <img v-else :src="require(`../../../assets/img/products/studio_manual-not-selected.svg`)" alt="">
          {{ t('project.new_project.studio_manual.btn_add_sub') }}
        </div>
      </CTooltip>      
      
      <div v-for="(product, i) in products" :key="`product-${i}`">
        <!-- Auto product -->
        <CTooltip :text="t('project.new_project.studio_auto.product_description')" :isLargeContent="true">
          <div class="product-card" 
            v-if="product.position == 1"
            :class="{ 
              'disabled' : !product.active,
              'selected' : productSelected != null && productSelected.key == product.key,
              'hovered' : autoProductHovered
            }"
            @mouseover="autoProductHovered = true"
            @mouseleave="autoProductHovered = false"
            @click="selectProduct(product.key, product.id, product.name)" 
          >
            <img v-if="!product.active" :src="require(`../../../assets/img/products/${product.key}-unavailable.svg`)" alt="">
            <img v-if="productSelected != null && productSelected.key == product.key || autoProductHovered" :src="require(`../../../assets/img/products/${product.key}-selected.svg`)" alt="">         
            <img v-else :src="require(`../../../assets/img/products/${product.key}-not-selected.svg`)" alt="">
            {{ product.name }}
            <span class="unavailable" v-if="!product.active">{{ t('order.service.product_soon_available') }}</span>
          </div>
        </CTooltip>

        <!-- Proofreading product -->
        <CTooltip :text="t('project.new_project.studio_proofreading.product_description')" :isLargeContent="true">
          <div class="product-card" 
            :class="{ 
              'disabled' : !product.active,
              'selected' : productSelected != null && productSelected.key == product.key,
              'hovered' : proofreadingProductHovered
            }"
            @mouseover="proofreadingProductHovered = true"
            @mouseleave="proofreadingProductHovered = false"
            @click="selectProduct(product.key, product.id, product.name)"
            v-if="product.position == 2"
          >
            <img v-if="!product.active" :src="require(`../../../assets/img/products/${product.key}-unavailable.svg`)" alt="">
            <img v-if="productSelected != null && productSelected.key == product.key || proofreadingProductHovered" :src="require(`../../../assets/img/products/${product.key}-selected.svg`)" alt="">
            <img v-else :src="require(`../../../assets/img/products/${product.key}-not-selected.svg`)" alt="">
            {{ product.name }}
            <span class="unavailable" v-if="!product.active">{{ t('order.service.product_soon_available') }}</span>
          </div>
        </CTooltip>

        <!-- Unilingual product -->
        <!-- <div class="product-card" 
          :class="{
            'selected' : productSelected != null && productSelected.key == product.key,
            'disabled' : !product.active
          }" 
          @click="selectProduct(product.key, product.id, product.name)" 
          v-if="product.position == 3"
        >
          <img v-if="!product.active" :src="require(`../../../assets/img/products/${product.key}-unavailable.svg`)" alt="">
          <img v-else :src="require(`../../../assets/img/products/${product.key}-not-selected.svg`)" alt=""> -->
          <!-- {{ product.name }}
          <span class="unavailable" v-if="!product.active">{{ t('order.service.product_soon_available') }}</span>
        </div> -->

        <!-- Europe product -->
        <!-- <div class="product-card" 
          :class="{
            'selected' : productSelected != null && productSelected.key == product.key,
            'disabled' : !product.active 
          }" 
          @click="selectProduct(product.key, product.id, product.name)"
          v-if="product.position == 4"
        > -->
          <!-- <img v-if="!product.active" :src="require(`../../../assets/img/products/${product.key}-unavailable.svg`)" alt="">  -->
          <!-- <img v-else :src="require(`../../../assets/img/products/${product.key}-not-selected.svg`)" alt=""> -->
          <!-- {{ product.name }}
          <span class="unavailable" v-if="!product.active">{{ t('order.service.product_soon_available') }}</span>
        </div> -->

        <!-- International product -->
        <!-- <div class="product-card" 
          :class="{
            'selected' : productSelected != null && productSelected.key == product.key,
            'disabled' : !product.active
          }" 
          @click="selectProduct(product.key, product.id, product.name)"
          v-if="product.position == 5"
        >
          <img v-if="!product.active" :src="require(`../../../assets/img/products/${product.key}-unavailable.svg`)" alt=""> -->
          <!-- <img v-else :src="require(`../../../assets/img/products/${product.key}-not-selected.svg`)" alt=""> -->
          <!-- {{ product.name }}
          <span class="unavailable" v-if="!product.active">{{ t('order.service.product_soon_available') }}</span>
        </div> -->
      </div>
    </div> 
    <button class="ui green button" :class="{ 'disabled' : !productSelected }" @click="nextProjectStep()">{{ t('layout.shared.actions.next') }}</button>
    <CCreditErrorModal
      ref="creditErrorModal"
    ></CCreditErrorModal>
  </div>
</template>

<script>
import CCreditErrorModal from '../modals/CCreditErrorModal.vue'
import CTooltip from '../../authotElements/CTooltip.vue'

export default {
  props: ["current_user"],
  data() {
    return {
      uploadProductHovered: false,
      autoProductHovered: false,
      proofreadingProductHovered: false,
      unilingualProductHovered: false,
      europeProductHovered: false,
      internationalProductHovered: false,
    }
  },
  components: {
    CCreditErrorModal,
    CTooltip
},
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
    products() {
      return this.$store.getters['products/studio_products']
    },
    credits() {
      return this.$store.getters['credit/all_credits']
    }, 
    productSelected() {
      return this.$store.getters['newProjects/product_selected']
    },
  },
  methods: {
    selectProduct(productKey, productId, productName) {
      productKey == 'studio_manual' ?
        this.$store.commit('newProjects/SET_PRODUCT', { product_key: productKey, product_id: productId, product_name: productName }) :
        this.checkCredit(productId) ? 
          this.$store.commit('newProjects/SET_PRODUCT', { product_key: productKey, product_id: productId, product_name: productName }) : 
          this.$refs["creditErrorModal"].openModal()
    },
    checkCredit(productId) {
      return this.credits.find(credit => credit.product_id == productId).quantity > 0 ? true : false
    },
    nextProjectStep() {
      this.$store.commit('newProjects/SET_PROJECT_STEP', { step: 2 })
    }
  },
  mounted() {
    this.$store.dispatch('credit/getAllCredits', this.current_user);
    this.$store.dispatch('products/getAllProducts');
  }
}
</script>

<style scoped>
</style>