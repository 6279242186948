<template>
  <footer class="footer container main-padding">
    <div class="ui vertical menu">
      <a class="item footer authot logo" href="/"></a>
      <p v-html="t('layout.footer.copyright', {year: new Date().getFullYear()})"></p>
      <a href="https://microservices.authot.com/" target="_blank">{{ t('layout.footer.authot_website') }}</a>
      <p>+33 1 58 46 86 88</p>
      <p><a href="mailto:contact@authot.com" class="orange text" target="_blank" ></a></p>
    </div>
    <div v-if="current_user" >
      <div class="ui vertical menu">
        <h4>{{ t('layout.footer.navigation') }}</h4>
        <p><a href="/video">{{ t('layout.main_title.my_projects') }}</a></p>
        <p><a href="/orders/new">{{ t('layout.main_title.order') }}</a></p>
        <p><a href="/ticket/new">{{ t('layout.main_title.contact_us') }}</a></p>
        <p><a href="/users/edit">{{ t('layout.main_title.account') }}</a></p>
      </div>
    </div>
    <div class="ui vertical menu">
      <p><a v-bind:href="t('layout.footer.cgu_link')" target="_blank">{{ t('layout.footer.cgu') }}</a></p> 
      <p><a href="https://authot.app/Authot_CGV_fr.pdf" target="_blank">{{ t('layout.footer.cgv') }}</a></p>
      <p><a href="/legals">{{ t('layout.footer.legal_mentions') }}</a></p>
      <p style="color:#FAFCFE"> V3.51.1 20/06</p>
    </div>
    <CLocalesDropdown></CLocalesDropdown>
  </footer>
</template>

<script>
import CLocalesDropdown from './CLocalesDropdown.vue'

export default {
  props: ["current_user"],
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
  },
  mounted() {
    $('.ui.dropdown.label.number').dropdown()
    $('.menu.edit-account .item').tab()
  },
  components: {
    CLocalesDropdown
  }
}
</script>