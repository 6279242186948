<template>
  <div class="authot-container quantity-container">
    <div>
      <h3>{{ t('order.quantity.title') }}</h3>
      <div v-for="(product, i) in products" :key="`product-${i}`">
        <p v-html="t('order.quantity.quantity_description', { product_choosed: product.name })"></p>
        <div class="quantity-container-product_image">
          <img :src="require(`../../assets/img/products/${product.key}-selected.svg`)" alt="">
          <span>{{ product.name }}</span>
        </div>
      </div>
    </div>

    <div class="quantity-container-quantities">
      <!-- AUTO PRODUCT -->
      <div 
        v-if="products[0].key == 'studio_auto' && discovery_auto_available"
        class="quantity-card discovery major"
        :class="{ 
          active : quantitySelected === discovery_auto_quantity && discoveryPackageSelected,
          unavailable : !discoveryPackageAvailable
        }"
      >
        <label :for="`quantity-auto-discovery-${discovery_auto_quantity}`"></label>
        <input
          :value="discovery_auto_quantity"
          @change="setQuantity($event, true)"
          type="radio" name="choosedQuantity"
          :id="`quantity-auto-discovery-${discovery_auto_quantity}`">
        <span>{{ t('order.service.studio_discovery_title') }}</span>
        <span class="unavailable-message" v-if="!discoveryPackageAvailable">{{ t('order.quantity.discovery_package_unavailable') }}</span>
      </div>
      
      <div class="quantity-cards">
        <div
          v-if="products[0].key == 'studio_auto'"
          v-for="(autoQuantity, i) in studio_auto_quantities" 
          :key="`autoQuantity-${i}`" 
          class="quantity-card"
          :class="{ active : quantitySelected === autoQuantity && !discoveryPackageSelected }"
        >
          <label :for="`autoQuantity-${autoQuantity}`"></label>
          <input
            :value="autoQuantity"
            @change="setQuantity($event, false)"
            type="radio" name="choosedQuantity"
            :id="`autoQuantity-${autoQuantity}`">
          <span v-if="autoQuantity > 1">{{ autoQuantity }} {{ t('order.quantity.hours') }}</span>
          <span v-else>{{ autoQuantity }} {{ t('order.quantity.hour') }}</span>
        </div>
      </div>

      <!-- PROOFREAD PRODUCT -->
      <div class="quantity-cards" v-if="products[0].key == 'studio_proofreading'">
        <div
          v-for="(proofreadQuantity, i) in studio_proofreading_quantities" 
          :key="`proofreadQuantity-${i}`"
          class="quantity-card"
          :class="{ active : quantitySelected === proofreadQuantity }"
        >
          <label :for="`proofreadQuantity-${proofreadQuantity}`"></label>
          <input
            :value="proofreadQuantity"
            @change="setQuantity($event, false)"
            type="radio" name="choosedQuantity"
            :id="`proofreadQuantity-${proofreadQuantity}`">
          <span v-if="proofreadQuantity > 1">{{ proofreadQuantity }} {{ t('order.quantity.hours') }}</span>
          <span v-else>{{ proofreadQuantity }} {{ t('order.quantity.hour') }}</span>
        </div>
      </div>
      <!-- PROOFREADING OPTIONS -->
      <div class="quantity-options" v-if="products[0].key == 'studio_proofreading'">
        <h4>{{ t(`order.service.${products[0].key }_options_title`) }}</h4>
        <div
          class="quantity-options-option"
          v-for="option in proofreading_options"
          :key="`option-${option.name}`"
        >
          <input 
            type="checkbox" 
            :id="`checkbox-${option.name}`" 
            v-model="option.checked"
            @change="getOrderAmount()"
          >
          <label :for="`checkbox-${option.name}`">{{ t(`order.service.${products[0].key }_options.${option.name}`) }}</label>
        </div>
      </div>
    </div>

    <!-- <h3>{{ t('order.service.studio_translation_title') }}</h3>
    <p v-html="t('order.quantity.quantity_description_translation')"></p>
    <div class="quantity-container-quantities" v-if="products[0].key == 'studio_auto'"> -->
      <!-- TRANSLATION PRODUCT -->
      <!-- <div
        class="quantity-card major"
        :class="{ active : quantityTranslationSelected === 0 }"
      >
        <label :for="`quantity-translation-0`"></label>
        <input
          :value="0"
          @change="setQuantityTranslation($event, false, false)"
          type="radio" name="choosedQuantity"
          :id="`quantity-translation-0`">
        <span>{{ t('order.service.studio_translation_no_translation') }}</span>
      </div>

      <div
        class="quantity-card major"
        :class="{ active : quantityTranslationSelected === discovery_translation_quantity && discoveryTranslationPackageSelected, available : discoveryTranslationAvailable }"
      >
        <label :for="`quantity-translation-discovery-${discovery_translation_quantity}`"></label>
        <input
          :value="discovery_translation_quantity"
          @change="setQuantityTranslation($event, true, true)"
          type="radio" name="choosedQuantity"
          :id="`quantity-translation-discovery-${discovery_translation_quantity}`">
        <span>{{ t('order.service.studio_discovery_title') }}</span>
      </div>
      
      <div class="quantity-cards">
        <div
          v-if="products[0].key == 'studio_auto'"
          v-for="(quantityTranslation, i) in studio_translation_quantities" 
          :key="`quantityTranslation-${i}`" 
          class="quantity-card"
          :class="{ active : quantityTranslationSelected === quantityTranslation && !discoveryTranslationPackageSelected }"
          >
          <label :for="`quantityTranslation-${quantityTranslation}`"></label>
          <input
          :value="quantityTranslation"
          @change="setQuantityTranslation($event, true, false)"
          type="radio" name="choosedQuantity"
          :id="`quantityTranslation-${quantityTranslation}`">
          <span v-if="quantityTranslation > 1">{{ quantityTranslation }} {{ t('order.quantity.hours') }}</span>
          <span v-else>{{ quantityTranslation }} {{ t('order.quantity.hour') }}</span>
        </div>
      </div>
    </div> -->

    <p class="quantity-container-info" v-if="products[0].key == 'studio_auto'">
      <i class="info circle icon"></i> {{ t('order.quantity.more_quantity_300') }} 
      <a href="/ticket/new" target="_blank">{{ t('order.quantity.contact_us') }}</a>
    </p>
    <p class="quantity-container-info" v-else>
      <i class="info circle icon"></i> {{ t('order.quantity.more_quantity_100') }} 
      <a href="/ticket/new" target="_blank">{{ t('order.quantity.contact_us') }}</a>
    </p>
  </div>
</template>

<script>
import { EventBus } from '../../packs/application'

export default {
  computed: {
    products() {
      return this.$store.getters['order/products']
    },
    studio_auto_quantities() {
      return this.$store.getters['products/studio_auto_quantities']
    },
    studio_proofreading_quantities() {
      return this.$store.getters['products/studio_proofreading_quantities']
    },
    // studio_translation_quantities() {
    //   return this.$store.getters['products/studio_translation_quantities']
    // },
    proofreading_options() {
      return this.$store.getters['order/proofreading_options']
    },
    discovery_auto_available() {
      return this.$store.getters['products/discovery_auto_product']
    },
    discovery_auto_quantity() {
      return this.$store.getters['products/discovery_auto_product_quantity']
    },
    discoveryPackageAvailable() {
      return this.$store.getters['order/discovery_package_available']
    },
    discoveryPackageSelected() {
      return this.$store.getters['order/discovery_package_selected']
    },
    // discoveryTranslationAvailable() {
    //   return this.$store.getters['products/discovery_translation_product']
    // },
    // discovery_translation_quantity() {
    //   return this.$store.getters['products/discovery_translation_product_quantity']
    // },
    // discoveryTranslationPackageSelected() {
    //   return this.$store.getters['order/discovery_translation_package_selected']
    // },
    quantitySelected() {
      return this.$store.getters['order/quantity']
    },
    // quantityTranslationSelected() {
    //   return this.$store.getters['order/quantityTranslation']
    // },
    discountCodeStore() {
      return this.$store.getters['order/discount_code'];
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    setQuantity(e, discoverySelected) {
      this.$store.commit('order/SET_DISCOVERY_PACKAGE_SELECTED', discoverySelected)
      this.$store.commit('order/SET_QUANTITY', parseInt(e.target.value))
      if(this.discountCodeStore) return EventBus.$emit('resetPromo')
      this.getOrderAmount();
    },
    // setQuantityTranslation(e, translationSelected, discoverySelected) {
    //   this.$store.commit('order/SET_DISCOVERY_TRANSLATION_PACKAGE_SELECTED', discoverySelected)
    //   this.$store.commit('order/SET_TRANSLATION', translationSelected)
    //   this.$store.commit('order/SET_QUANTITY_TRANSLATION', parseInt(e.target.value))
    //   if(this.discountCodeStore) return EventBus.$emit('resetPromo')
    //   this.getOrderAmount()
    // },
    getOrderAmount() {
      this.$store.dispatch('order/getAmount');
    }
  }
}
</script>