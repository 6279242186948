<template>
  <div>
    <div v-if="current_user">
      <div class="home-signin main-padding">
        <div class="top-title">
          <h1 v-html="t('home.signin_sentence')"></h1>
          <a href="/video/new" class="green ui button" data-v-step="1">
            <i class="plus icon"></i>{{ t('layout.main_title.new_project') }}
          </a>
        </div>
        <div class="tutorials">
          <div class="tutorial">
            <img src="../../assets/img/home/home_signin_left_img.png" alt="">
            <div class="tutorial-text">
              <h3>{{ t('home.work') }}</h3>
              <p>{{ t('home.work_description') }}</p>
              <div class="tutorial-links">
                <div class="tutorial-links-item">
                  <i class="small play icon"></i>
                  <a href="https://youtube.com/playlist?list=PLgngiBfa6UnNe6FVN0wXx_bA4KfG4NdcE" class="" target="_blank">{{ t('home.link_video') }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="tutorial">
            <img src="../../assets/img/home/home_signin_right_img.png" alt="">
            <div class="tutorial-text">
              <h3>{{ t('home.help') }}</h3>
              <p v-html="t('home.help_description')"></p>
              <div class="tutorial-links">
                <div class="tutorial-links-item">
                  <i class="small play icon"></i>
                  <a @click="activeTour()">{{ t('home.guide') }}</a>
                </div> 
                <div class="tutorial-links-item disabled">
                  <i class="small play icon"></i>
                  <a>{{ t('home.tutorial') }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <v-tour name="studioTour" :options="tourOptions" :steps="tourSteps">
        <template slot-scope="tour">
          <transition name="fade">
            <v-step
              v-if="tour.currentStep === index"
              v-for="(step, index) of tour.steps"
              :key="index"
              :step="step"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
            >
              <template v-if="tour.currentStep === 0">
                <div slot="actions">
                  <button @click="deactivateTour()" class="v-step__button">{{ t('tour.button.exit') }}</button>
                  <button @click="tour.nextStep" class="v-step__button">{{ t('tour.button.next') }}</button>
                </div>
              </template>
              <template v-if="tour.currentStep === 1">
                <div slot="actions">
                  <button @click="tour.previousStep" class="v-step__button">{{ t('tour.button.previous') }}</button>
                  <button @click="tour.stop" class="v-step__button">{{ t('tour.button.understood') }}</button>
                </div>
              </template>
            </v-step>
          </transition>
        </template>
      </v-tour>
    </div>
    <div v-else>
      <div class="home-signout main-padding main-height">
        <div class="left-title">
          <h2 v-html="t('home.signout_sentence')"></h2>
          <a class="authot ui button" href="/ticket/demonstration">{{ t('home.ask_us') }}</a>
        </div>
      </div>
      <h4 class="carousel_title">Ils nous utilisent actuellement</h4>
      <div class="h-separator"></div>
      <VueSlickCarousel v-bind="settings">
        <div><img src="../../assets/img/company/logo Arteredi.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/logo INAredi.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/logo openclassroom.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/logo rtbfredi.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/logo totalredi.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company//logo TV5monderedi.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/logo inshea.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/logo ministere education.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/Logo paris 2.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/Logo Skillsoft.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/logo Ubicast.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/logo vinci.png" class="imgCarousel"/></div>
        <div><img src="../../assets/img/company/Logo_Agglo_Basque.png" class="imgCarousel"/></div>        
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel';
export default {
  props: ["current_user"],
  data() {
    return {
      tourOptions: {
        useKeyboardNavigation: false
      },
      tourSteps: [
        {
          content: '',
        },
        {
          target: '[data-v-step="1"]',
          content: ''
        }
      ],
      settings:{
        arrows: false,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 0,
        slidesToShow: 6,
        speed: 5000,
        cssEase: 'linear',
        responsive: [
          {
            breakpoint: 1365,
            settings: {
              slidesToShow: 4
            }
          },
          {
            breakpoint: 1025,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              speed: 5000
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    checkIfSendTicket() {
      if(sessionStorage.isSendTicket) {
        $('body').toast({
          showIcon: 'check circle outline icon big green',
          message: sessionStorage.ticketResponse,
        });
        sessionStorage.removeItem('isSendTicket');
        sessionStorage.removeItem('ticketResponse');
      }
    },
    manageTourMessages() {
      this.tourSteps[0].content = this.t('tour.home.first_step');
      this.tourSteps[1].content = this.t('tour.home.second_step');
    },
    activeTour() {
      localStorage.isActiveTour = true;
      this.$tours['studioTour'].start();
    },
    deactivateTour() {
      this.$tours['studioTour'].stop();
      localStorage.isActiveTour = false;
    }
  },
  mounted() {
    this.checkIfSendTicket();
    this.manageTourMessages();
  },
  components: {
    VueSlickCarousel
  }
}
</script>

<style scoped>
</style>