import axiosClient from "../axios-config";

export default {
  updateUser(userData) {
    return axiosClient.put('/users', { 
      utf8: "✓", 
      orders: "true", 
      user: userData, 
      commit: "Valider" 
    });
  }
}