<template>
  <div class="ui tiny video-index-modal modal edit" id="edit-project-modal">
    <i class="close grey icon" @click="initEditInfos()"></i>
    <div class="header">
      <i class="big cog orange icon"></i>
      <h3>{{ t('project.edit.title') }}</h3>
    </div>
    <div class="content ui form edit-content">
      <div class="edit-content-video" v-show="firstModalVisible" v-if="project != null">
        <label class="form light label">{{ t('project.edit.project_name_label') }}</label>
        <div class="ui fluid search selection dropdown edit-project">
          <input type="hidden" class="project-name-input">
          <i class="dropdown icon"></i>
          <div class="default text">{{ t('project.edit.project_name_placeholder') }}</div>
          <div class="menu">
            <div class="item" 
              :data-value="project.name" 
              v-for="(project) in userProjects" 
              :key="`edit-${project.name}`">
              {{ project.name }}
            </div>
          </div>
        </div>
        
        <div class="required">
          <label class="form light label">{{ t('project.edit.video_title_label') }}</label>
          <span>*</span>
        </div>
        <input v-model="project.title">

        <div class="edit-content-video-actions">
          <button class="ui authot green button" @click="firstModalVisible = false">{{ t('layout.shared.actions.next') }}</button>
        </div>
      </div>
      
      <div class="edit-content-subtitles" v-show="!firstModalVisible" v-if="project != null">
        <label class="form light label">{{ t('project.edit.subtitles_label') }}</label>
        <div class="edit-content-subtitles-container">
          <div class="edit-content-subtitles-container-list">
            <div @click="manageLabelStyle(subtitle.id)" class="edit-content-subtitles-container-list-item" v-for="(subtitle, i) in project.subtitles" :key="i">
              <label :for="subtitle.id" :class="'radio-label-' + subtitle.id">
                <img
                  class="mini round-flag"
                  :src="require(`../../../assets/img/flags/${subtitle.flag_iso}.png`)"
                >
                {{ subtitle.title }}
              </label>
              <input type="radio" :id="subtitle.id" :class="'radio-' + subtitle.id" :value="subtitle.id" v-model="subtitlePicked">
              <span class="checkmark"></span>
            </div>
          </div>
        </div>

        <label class="form light label">{{ t('project.edit.subtitle_new_name') }}</label>
        <div class="edit-content-subtitles-title">
          <input type="text" v-model="titleSubtitlePicked" @input="titleUpdated = false">
          <button v-show="!titleUpdated" class="ui extradark_grey authot button" @click="checkSubtitleTitle()">{{ t('layout.shared.actions.ok') }}</button>
          <button v-show="titleUpdated" class="ui green authot button"><i class="ui check icon"></i></button>
        </div>
        <div class="edit-content-subtitles-actions">
          <button class="ui authot dark_grey button" @click="firstModalVisible = true">{{ t('layout.shared.actions.previous') }}</button>
          <button class="ui authot orange button" @click="manageEditProject()">{{ t('layout.shared.actions.save') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosVideoService from "../../../services/video/video-index"
import AxiosProjectService from '../../../services/project/project'
import { EventBus } from '../../../packs/application'

export default {
  props: ['project'],
  data() {
    return {
      firstModalVisible: true,
      subtitlePicked: null,
      titleSubtitlePicked: "",
      titleUpdated: false,
      userProjects: []
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    manageSubtitleTitle() {
      if(this.project != null) {
        const subtitle = this.project.subtitles.find(s => s.id == this.subtitlePicked);
        this.titleSubtitlePicked = subtitle.title;
        this.titleUpdated = false;
      }
    },
    checkSubtitleTitle() {
      this.titleSubtitlePicked == "" ? this.displayEmptyTitleError() : this.updateSubtitleTitle();
    },
    manageLabelStyle(subtitleId) {
      const labelSelected = $('.radio-label-' + subtitleId)[0];
      if($('.label-active').length != 0) {
        const labelElement = $('.label-active')[0];
        $(labelElement).removeClass('label-active');
      }
      if($('.radio-' + subtitleId)[0].checked == true) $(labelSelected).addClass('label-active');
    },
    displayEmptyTitleError() {
      $('body').toast({
        showIcon: 'times circle outline icon big red',
        message: this.t('project.edit.subtitle_title_error'),
        class : 'ui toast authot',
        className: {
          toast: 'ui toast authot'
        }
      });
    },
    displayVideoTitleError() {
      $('body').toast({
        showIcon: 'times circle outline icon big red',
        message: this.t('project.edit.video_title_error'),
        class : 'ui toast authot',
        className: {
          toast: 'ui toast authot'
        }
      });
    },
    updateSubtitleTitle() {
      this.project.subtitles.map(s => { 
        if(s.id == this.subtitlePicked) s.title = this.titleSubtitlePicked;
      });
      this.titleUpdated = true;
    },
    initEditInfos() {
      if($('.label-active').length != 0) {
        const labelElement = $('.label-active')[0];
        $(labelElement).removeClass('label-active');
      };
      this.firstModalVisible = true;
      this.subtitlePicked = null;
      this.titleSubtitlePicked = "";
      this.titleUpdated = false;
      $('#edit-project-modal').modal('hide');
      EventBus.$emit('any-modal-closed');
      EventBus.$emit('edit-modal-closed');
    },
    manageEditProject() {
      this.project.title == null || this.project.title == "" ? this.displayVideoTitleError() : this.editProject();
    },
    editProject() {
      const submittedProject = {
        id: this.project.id,
        project: this.project.project == "" ? null : this.project.project,
        title: this.project.title,
        language_id: this.project.language_id,
        subtitles: this.project.subtitles.map(s => {
          return { id: s.id, title: s.title }
        })
      }
      AxiosVideoService.putVideo(submittedProject.id, submittedProject)
      .then(response => {
        $('body').toast({
          showIcon: 'check circle outline icon big green',
          message: this.t('project.edit.project_updated'),
          class : 'ui toast authot',
        });
        this.initEditInfos();
      }).catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class : 'ui toast authot',
        });
        this.initEditInfos();
      });
    },
  },
  watch: {
    subtitlePicked: {
      deep: true,
      handler() {
        if(this.project != null) this.manageSubtitleTitle();
      }
    }
  },
  mounted() {
    $('#edit-project-modal').modal({
      inverted: true,
      closable: false
    });
    EventBus.$on('open-edit-modal', () => {
      setTimeout(() => {
        AxiosProjectService.getProjects()
        .then(response => {
          this.userProjects = response.data;
          $('.ui.search.dropdown.edit-project').dropdown({
            allowAdditions: true,
            hideAdditions: false,
            clearable: true,
            forceSelection: false,
            value: this.project.project,
            showOnFocus: false,
            message: {
              addResult: this.t('authot_elements.dropdown.click_to_add'),
            },
            onChange: (value) => {
              this.project.project = value;
            },
          });
          $('.ui.search.dropdown.edit-project').dropdown('set selected', this.project.project)
        });
      }, 1);
    });
  }
}
</script>