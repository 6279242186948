<template>
  <div>
    <vue-good-table
      ref="incrustTable"
      :columns="columns"
      :rows="incrustations"
      :select-options="{ 
        enabled: true, 
        selectOnCheckboxOnly: true, 
        selectionText: t('project.projects.projects_tab.projects_selected'),
        selectionInfoClass: 'head-choices',
      }"
      :pagination-options="{ 
        enabled: true, 
        perPage: 5,
      }"
      styleClass="vgt-table incrustations"
      @on-selected-rows-change="initDomElements(arguments[0])"
    >
      <div slot="selected-row-actions">
        <div class="ui mini selection incrustation actions dropdown">
          <input type="hidden" name="incrustation-actions">
          <i class="dropdown icon"></i>
          <div class="text"></div>
          <div class="menu">
            <div class="item" data-value="download">{{ t('layout.shared.actions.download') }}</div>
          </div>
        </div>
        <button class="mini ui button selected actions" @click="manageIncrustationsSelected()">{{ t('layout.shared.actions.confirm') }}</button>
      </div>
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'created_at'" class="span-date">
          <p>{{ props.row.created_at }}</p>
        </span>
        <span v-else-if="props.column.field == 'video_title'" class="span-video-title">
          <span :title="props.row.video_title">{{ truncateString(props.row.video_title) }}</span>
          <CTooltip :isDynamic="true" :text="t('layout.shared.actions.copy')" :textDynamic="t('layout.shared.actions.copied')">
            <i class="clipboard outline link icon" @click="copyTitle(props.row.video_title)"></i>
          </CTooltip>
        </span>
        <span v-else-if="props.column.field == 'video_flag_iso'" class="span-language">
          <img
            class="mini round-flag"
            :src="require(`../../../assets/img/flags/${props.row.video_flag_iso}.png`)"
          ><br>
          <p style="margin-right:5%;">{{ props.row.video_flag_iso_639 }}</p>
        </span>
        <span v-else-if="props.column.field == 'subtitle_title'" class="span-subtitle-title">
          <img
            class="mini round-flag"
            :src="require(`../../../assets/img/flags/${props.row.subtitle_flag_iso}.png`)"
          >
          <p>{{ truncateString(props.row.subtitle_title) }}</p>
        </span>
        <span v-else-if="props.column.field == 'state'">
          <div v-if="props.row.state == 0">
            <i class="orange tiny circle icon"></i>
            {{ t('layout.shared.states.in_progress') }}
          </div>
          <div v-if="props.row.state == 10">
            <i class="green tiny circle icon"></i>
            {{ t('layout.shared.states.done') }}
          </div>
        </span>
        <span v-else-if="props.column.field == 'actions'">
          <button @click="getIncrustation(props.row.id)" class="small green authot ui button" :class="{'disabled': props.row.state != 10}">{{ t('layout.shared.actions.download') }}</button>
          <a @click="openDeleteIncrustationModal(props.row.id)"><i class="large trash red alternate icon link" :class="{'disabled': props.row.state != 10}"></i></a>
          <div class="ui modal delete tiny" :id="'modal-delete-incrustation-' + props.row.id">
            <div class="header">
              <i class="large trash alternate icon"></i>
              {{ t('authot_elements.modal.delete_incrustation_title') }}
            </div>
            <div class="content">
              <p v-html="t('authot_elements.modal.delete_incrustation_description')"></p>
            </div>
            <div class="actions">
              <button class="dark_grey ui button" @click="closeDeleteIncrustationModal(props.row.id)">{{ t('layout.shared.actions.cancel') }}</button>
              <button class="green authot ui button" @click="deleteIncrustation(props.row.id)">{{ t('layout.shared.actions.confirm') }}</button>
            </div>
          </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template slot="pagination-bottom" slot-scope="props">
        <CVideoPagination
          :total="total_incrustations"
          :page-changed="props.pageChanged"
          :per-page-changed="props.perPageChanged"
          dropdownId="incrustation-pagination"
        >
        </CVideoPagination>
      </template>
      <div slot="emptystate" class="empty-data">
        {{ t('project.projects.incrustations_tab.no_project') }}
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { EventBus } from "../../../packs/application"
import AxiosIncrustationService from '../../../services/incrustation/incrustation'
import CVideoPagination from "./CVideoPagination"
import CTooltip from "../../authotElements/CTooltip.vue"

export default {
  props: ["incrustations", "total_incrustations"],
  data() {
    return{
      maxTitleLength: 25,
      copyActive: false
    }
  },
  computed: {
    columns() {
      return [
        {
          label: this.t('project.projects.incrustations_tab.label_date'),
          field: 'created_at',
          sortable: false
        },
        {
          label: this.t('project.projects.incrustations_tab.label_title'),
          field: 'video_title',
          sortable: false,
        },
        {
          label: this.t('project.projects.incrustations_tab.label_languages'),
          field: 'video_flag_iso',
          sortable: false,
        },
        {
          label: this.t('project.projects.incrustations_tab.label_subtitles'),
          field: 'subtitle_title',
          sortable: false
        },
        {
          label: this.t('project.projects.incrustations_tab.label_projects'),
          field: 'project',
          sortable: false,
        },
        {
          label: this.t('project.projects.incrustations_tab.label_state'),
          field: 'state',
          sortable: false,
        },
        {
          label: this.t('project.projects.incrustations_tab.label_actions'),
          field: 'actions',
          sortable: false,
        }
      ]
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    initDomElements(projectsSelected) {
      if(projectsSelected) projectsSelected.selectedRows.length == 0 || projectsSelected == undefined ? this.removeVgtTableClass() : this.addVgtTableClass();
      if(projectsSelected) this.manageSelectedProjects(projectsSelected);
      setTimeout(() => {
        $('.incrustation.actions.dropdown').dropdown('set selected', 'download');
      }, 1);
    },
    addVgtTableClass() {
      $('.vgt-table.incrustations').addClass("header-choices-active");
    },
    removeVgtTableClass() {
      $('.vgt-table.incrustations').removeClass("header-choices-active");
    },
    manageSelectedProjects(projectsSelected) {
      projectsSelected && projectsSelected.selectedRows.length > 0 ? EventBus.$emit('any-modal-opened') : EventBus.$emit('any-modal-closed');
    },
    getIncrustation(id) {
      AxiosIncrustationService.exportIncrustation(id)
      .then(result => {
        window.open(result.data.url,'_blank');
      }).catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class : 'ui toast authot',
        });
      })
    },
    truncateString(stringData) {
      return stringData.length > this.maxTitleLength ? 
      stringData.substr(0, 10) + '...' + stringData.substr(stringData.length-10, stringData.length) : 
      stringData;
    },
    copyTitle(title) {
      let copyContent = title;
      $('<input>').val(copyContent).appendTo('body').select();
      document.execCommand('copy');
      this.copyActive = true;
    },
    openDeleteIncrustationModal(id) {
      EventBus.$emit('any-modal-opened');
      $('#modal-delete-incrustation-' + id).modal({
        inverted: true,
        closable: false
      });
      $('#modal-delete-incrustation-' + id).modal('show');
    },
    closeDeleteIncrustationModal(id) {
      EventBus.$emit('any-modal-closed');
      $('#modal-delete-incrustation-' + id).modal('hide');
    },
    deleteIncrustation(id) {
      AxiosIncrustationService.destroyIncrustation(id)
      .then(response => {
        // window.location.reload(true)
        this.closeDeleteIncrustationModal(id);
        EventBus.$emit('update-projects');
      }).catch(error => {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('layout.shared.errors.error'),
          class : 'ui toast authot',
        });
      })
    },
    manageIncrustationsSelected() {
      const actionSelected = $('.incrustation.actions.dropdown').dropdown('get value');
      actionSelected == "download" ? this.manageExportMultiIncrust() : true;
    },
    manageExportMultiIncrust() {
      this.$refs['incrustTable'].selectedRows.forEach(incrustation => this.getIncrustation(incrustation.id))
    }
  },
  mounted() {
  },
  components: {
    CVideoPagination,
    CTooltip
  }
}
</script>

<style scoped>
</style>