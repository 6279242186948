<template>
  <div class="vgt-table-pagination">
    <div class="vgt-table-pagination-selector">
      <p>{{ t('authot_elements.pagination.display') }}</p>
      <div class="ui selection dropdown per-page-dropdown" :id="dropdownId">
        <input type="hidden">
        <i class="dropdown icon"></i>
        <div class="default text">5</div>
        <div class="scrollhint menu">
          <div class="item" data-value="5">5</div>
          <div class="item" data-value="10">10</div>
          <div class="item" data-value="20">20</div>
          <div class="item" data-value="30">30</div>
        </div>
      </div>
      <p>{{ t('authot_elements.pagination.elements') }}</p>
    </div>
    <div class="vgt-table-pagination-pages">
      <div class="vgt-table-pagination-pages-start-arrows">
        <button class="vgt-table-pagination-pages-rounded-btn" @click="customPageChange(1)"><i class="angle double left icon"></i></button>
        <button class="vgt-table-pagination-pages-rounded-btn" @click="getPreviousPage()"><i class="angle left icon"></i></button>
      </div>
      <div v-for="(page, i) in pages" :key="i">
        <button class="vgt-table-pagination-pages-rounded-btn" :class="{ 'active': i + 1 == currentPage } " @click="customPageChange(i + 1)">{{ i + 1 }}</button>
      </div>
      <div class="vgt-table-pagination-pages-end-arrows">
        <button class="vgt-table-pagination-pages-rounded-btn" @click="getNextPage()"><i class="angle right icon"></i></button>
        <button class="vgt-table-pagination-pages-rounded-btn" @click="customPageChange(pages)"><i class="angle double right icon"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../packs/application'

export default {
  props: ['total', 'pageChanged', 'perPageChanged', 'dropdownId'],
  data() {
    return {
      perPage: 1,
      currentPage: 1,
      pages: 1
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    getPreviousPage() {
      if(this.currentPage != 1) this.customPageChange(this.currentPage - 1);
    },
    getNextPage() {
      if(this.currentPage + 1 != this.pages + 1) this.customPageChange(this.currentPage + 1);
    },
    customPageChange(customCurrentPage) {
      this.currentPage = customCurrentPage;
      //this.pageChanged({currentPage: customCurrentPage});
      EventBus.$emit('custom-page-change', this.currentPage)
    },
    customPerPageChange(customPerPage) {
      this.perPage = customPerPage;
      this.getTotalPages();
      //this.perPageChanged({currentPerPage: customPerPage});
      EventBus.$emit('custom-per-page-change', this.perPage)
    },
    getTotalPages() {
      if(this.total > this.perPage) {
        this.pages = Math.ceil(this.total/this.perPage);
      } else {
        this.pages = 1;
      }
    },
    emitPageChange() {
      EventBus.$emit('page-change', this.perPage, this.currentPage);
    }
  },
  watch: {
    total: {
      deep: true,
      handler() {
        this.getTotalPages();
      }
    },
    perPage: {
      handler() {
        this.currentPage = 1;
        EventBus.$emit('page-change', this.perPage, this.currentPage);
      }
    },
    currentPage: {
      handler() {
        EventBus.$emit('page-change', this.perPage, this.currentPage);
      }
    },
  },
  mounted() {
    $('#' + this.dropdownId).dropdown({
      onChange: (val) => {
        this.customPerPageChange(val);
      }
    })
    $('#' + this.dropdownId).dropdown('set selected', 5);
    EventBus.$on('projects-page-ready', () => this.emitPageChange())
    EventBus.$on('force-custom-page-change', (params) => this.currentPage = params)
  }
}
</script>