<template>
  <div class="ui modal upload mini" id="upload-status-modal">
    <div class="content">
      <div v-if="modalUploadFirst">
        <h3>{{ t('project.new_project.loading_messages.title_upload') }}</h3>
        <p v-html="t('project.new_project.loading_messages.description_upload')"></p>
        <img src="../../../assets/img/video/upload-img.svg" alt="">
        
        <div class="project-grid" :class="{'opened': projectGridOpened}">
          <div v-for="project in projects" :key="project.id">
            <div class="file-uploading">
              <p>{{ truncateTitle(project.title) }}</p>
              <div :id="project.id" class="ui small orange progress">
                <div class="bar">
                  <div class="progress"></div>
                </div>
              </div>
            </div>
            <div v-for="subtitle in project.subtitles_attributes" :key="subtitle.id" class="file-uploading">
              <p>{{ truncateTitle(subtitle.title) }}</p>
              <div :id="subtitle.id" class="ui small orange progress">
                <div class="bar">
                  <div class="progress"></div>
                </div>
              </div>
            </div>          
          </div> 
  
          <div v-for="url in url_projects" :key="url.url">
            <div v-for="url_sub in url.subtitles_attributes" :key="url_sub.id" class="file-uploading">
              <p>{{ truncateTitle(url_sub.title) }}</p>
              <div :id="url_sub.id" class="ui small orange progress">
                <div class="bar">
                  <div class="progress"></div>
                </div>
              </div>
            </div>
          </div>         
        </div>
        <i 
          class="link chevron down orange large icon"
          v-show="!projectGridOpened && totalProjectFiles > 5"
          @click="projectGridOpened = !projectGridOpened"
        ></i>
        <i 
          class="link chevron up orange large icon"
          v-show="projectGridOpened && totalProjectFiles > 5"
          @click="projectGridOpened = !projectGridOpened"
        ></i>
      </div>

      <div v-if="modalUploadSecond">
        <h3>{{ t('project.new_project.loading_messages.title_encoding') }}</h3>
        <p v-html="t('project.new_project.loading_messages.description_encoding', { project_link: '/video' } )"></p>
        <img src="../../../assets/img/video/encodage-img.svg" alt="">
      </div>
      <div v-if="modalUploadThird">
        <h3>{{ t('project.new_project.loading_messages.title_conversion') }}</h3>
        <p v-html="t('project.new_project.loading_messages.description_conversion')"></p>
        <img src="../../../assets/img/video/conversion-img.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../packs/application'
import { truncateKey } from '../../../utils/functions'

export default {  
  props: ['current_user'],
  data() {
    return {
      projectGridOpened: false,
      modalUploadFirst: true,
      modalUploadSecond: false,
      modalUploadThird: false,
      totalProjectFiles: 0
    }
  },
  computed: {
    projects() {
      return this.$store.getters['newProjects/projects']
    },
    url_projects() {
      return this.$store.getters['newProjects/url_projects']
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    openModal() {
      $('#upload-status-modal').modal('show')
      this.sumTotalProjectFile()
    },
    truncateTitle(stringToTruncate) {
      return truncateKey(stringToTruncate)
    },
    sumTotalProjectFile() {
      this.totalProjectFiles = 0
      this.totalProjectFiles += this.projects.length
      this.projects.map(project => this.totalProjectFiles += project.subtitles_attributes.length)
      this.url_projects.map(url_project => this.totalProjectFiles += url_project.subtitles_attributes.length)
    }
  },
  mounted() {
    $('#upload-status-modal').modal({
      inverted: true,
      closable: false
    })
    EventBus.$on('file-progress', (resumable) => {
      $("#" + resumable.fileId).progress('set percent', resumable.fileProgress)
    })
    EventBus.$on('upload-completed', () => {
      this.modalUploadFirst = false
      this.modalUploadSecond = true
    })
  },
}
</script>
<style scoped>
</style>