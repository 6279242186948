<template>
  <div class="video-index main-padding">
    <CVideoMenu></CVideoMenu>
    <CVideoTab :current_user="current_user"></CVideoTab>
  </div>
</template>

<script>
import CVideoMenu from "../../components/video/index/CVideoMenu"
import CVideoList from "../../components/video/index/CVideoList"
import CVideoTab from "../../components/video/index/CVideoTab"

export default {
  props: ["current_user"],
  components: {
    CVideoMenu,
    CVideoList,
    CVideoTab
  }
}
</script>

<style scoped>
</style>