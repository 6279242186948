<template>
  <div class="info-container">
    <div class="info-container-tabs">
      <div class="info-container-tabs-tab" :class="{ 'active' : personalInfo }">
        <i class="home big icon left-icon"></i>
        <span :class="{ 'dark_grey text' : personalInfo, 'grey light text' : !personalInfo }">{{ t('order.info.info_perso_title') }}</span>
        <label for="personalInfo"></label>
        <input :checked="personalInfo"  @change="togglePersonalInfo()" type="radio" name="personal-info" id="personalInfo">
      </div>
      <div class="info-container-tabs-tab" :class="{ 'active' : !personalInfo }">
        <i class="briefcase big icon left-icon"></i>
        <span :class="{ 'dark_grey text' : !personalInfo, 'grey light text' : personalInfo }">{{ t('order.info.info_company_title') }}</span>
        <label for="companyInfo"></label>
        <input :checked="!personalInfo" @change="togglePersonalInfo()" type="radio" name="personal-info" id="companyInfo">
      </div>
    </div>
    <div class="info-container-forms">
      <form id="edit_user" action="/users" accept-charset="UTF-8" data-remote="true" method="post">
        <input name="utf8" type="hidden" value="✓">
        <input type="hidden" name="_method" value="put">
        <div v-show="personalInfo" class="info-container-forms-form">
          <span class="form-header">{{ t('order.info.info_perso_subtitle') }}</span>
          <div class="authot-form-field">
            <i class="user icon left-icon"></i>
            <label class="authot-label" for="">{{ t('user.sign_up.first_name') }}</label>
            <input @change="updateUserInformations($event)" v-model="userInfo.first_name" :placeholder="t('user.sign_up.first_name_placeholder')" name="user[first_name]" class="authot-input left-icon" type="text">
          </div>
          <div class="authot-form-field">
            <i class="user icon left-icon"></i>
            <label class="authot-label" for="">{{ t('user.sign_up.last_name') }}</label>
            <input @change="updateUserInformations($event)" v-model="userInfo.last_name" :placeholder="t('user.sign_up.last_name_placeholder')" name="user[last_name]" class="authot-input left-icon" type="text">
          </div>
          <div class="authot-form-field">
            <i class="phone alternate icon left-icon"></i>
            <label class="authot-label" for="">{{ t('user.sign_up.phone') }}</label>
            <input @change="updateUserInformations($event)" v-model="userInfo.phone" :placeholder="t('user.sign_up.phone_placeholder')" name="user[phone]" class="authot-input left-icon" type="text">
          </div>
        </div>
        <div v-show="!personalInfo" class="info-container-forms-form">
          <span class="form-header">{{ t('order.info.info_company_subtitle') }}</span>
          <div class="authot-form-field">
            <i class="file alternate outline icon left-icon"></i>
            <label class="authot-label" for="">{{ t('user.sign_up.company') }}</label>
            <input @change="updateUserInformations($event)" v-model="companyInfo.company" :placeholder="t('user.sign_up.company_placeholder')" name="user[account_attributes][company]" class="authot-input left-icon" type="text">
          </div>
          <div class="authot-form-field">
            <i class="calendar minus outline icon left-icon"></i>
            <label class="authot-label" for="">{{ t('user.edit.account.vat_number') }}</label>
            <input @change="updateUserInformations($event)" v-model="companyInfo.vat" :placeholder="t('user.edit.account.vat_number_placeholder')" name="user[account_attributes][vat_number]" class="authot-input left-icon" type="text">
          </div>
          <div class="authot-form-field">
            <i class="map marker alternate icon left-icon"></i>
            <label class="authot-label" for="">{{ t('user.edit.account.address') }}</label>
            <input @change="updateUserInformations($event)" v-model="companyInfo.address" :placeholder="t('user.edit.account.address_placeholder')" name="user[account_attributes][address]" class="authot-input left-icon" type="text">
          </div>
          <div class="address-fields">
            <div class="authot-form-field">
              <label class="authot-label" for="">{{ t('user.edit.account.zip') }}</label>
              <input @change="updateUserInformations($event)" v-model="companyInfo.zip" :placeholder="t('user.edit.account.zip_placeholder')" name="user[account_attributes][zip_code]" class="authot-input" type="text">
            </div>
            <div class="authot-form-field">
              <label class="authot-label" for="">{{ t('user.edit.account.city') }}</label>
              <input @change="updateUserInformations($event)" v-model="companyInfo.city" :placeholder="t('user.edit.account.city')" name="user[account_attributes][city]" class="authot-input" type="text">
            </div>
            <div class="authot-form-field">
              <label class="authot-label" for="">{{ t('user.edit.account.country') }}</label>
              <div class="authot-dropdown ui dropdown search selection" id="countryDropdown">
                <input type="hidden" v-model="companyInfo.country" @change="updateCompanyInformations($event)" name="country">
                <i class="dropdown icon"></i>
                <div class="default text" >{{ t('user.sign_up.country_placeholder') }}</div>
                <div class="menu">
                  <div v-for="(country, i) in countries" :key="i" class="item" :data-value="country.code">{{ country.name }}</div>
                </div>
              </div>
              <!-- <input @change="updateCompanyInformations($event)" v-model="companyInfo.country" :placeholder="t('user.edit.account.country_placeholder')" name="user[account_attributes][country]" class="authot-input" type="text"> -->
            </div>
            <input type="hidden" :value="companyInfo.account_id" name="user[account_attributes][id]" id="user_account_attributes_id">
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import jsonCountries from '../../assets/json/countries.json'

export default {
  props: ["token"],
  data() {
    return {
      personalInfo: true,
      countries: jsonCountries,
    }
  },
  computed: {
    userInfo() {
      return this.$store.getters['order/user_informations'];
    },
    companyInfo() {
      return this.$store.getters['order/company_informations'];
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    updateUserInformations(e) {
      this.$store.commit('order/UPDATE_USER_INFORMATIONS', { 
        field: e.target.name, 
        value: e.target.value
      });
    },
    updateCompanyInformations(e) {
      this.$store.commit('order/UPDATE_COMPANY_INFORMATIONS', { 
        field: e.target.name, 
        value: e.target.value
      });
    },
    togglePersonalInfo() {
      this.personalInfo = !this.personalInfo
    }
  },
  mounted() {
    setTimeout(() => {
      $('#countryDropdown').dropdown('set selected', this.companyInfo.country);
    }, 1);
  }
}
</script>