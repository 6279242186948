<template>
  <div class="ui modal tiny error-credit" id="error-credit-modal">
    <div class="header">
      <i class="money check large icon"></i>
      {{ t('authot_elements.modal.credit_error_title') }}
    </div>
    <div class="content">
      <p>
        <i class="exclamation red circle icon"></i> 
        {{ t('authot_elements.modal.credit_error_description') }}
      </p>
    </div>
    <div class="actions">
      <a href="/orders/new" target="_blank" class="ui green button">{{ t('authot_elements.modal.credit_error_button') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
  },
  methods: {
    openModal() {
      $("#error-credit-modal").modal('show')
    },
    closeModal() {
      $("#error-credit-modal").modal('hide')
    }
  },
  mounted() {
    $("#error-credit-modal").modal({
      inverted: true
    })
  }
}
</script>

<style scoped>
</style>