<template>
  <div>
    <div class="filter-menu" v-show="displayFilter">
      <div class="filter-menu-filters">
        <v-date-picker 
          v-model="filteredRange" 
          color="orange" 
          timezone="Europe/Amsterdam"
          :locale="locale"
          is-range 
          @popoverDidHide="getDatasFiltered()"
        >
          <template v-slot="{ inputEvents, inputValue }">
            <div class="ui white button filter-menu-filters-item date" v-on="inputEvents.start">
              <div>
                <i class="calendar icon"></i>
                <div v-if="filteredRange != null" style="font-size: 10px;">
                  {{ inputValue.start }} - {{ inputValue.end }}
                </div>
                <div v-else>
                  {{ t('project.projects.filter_date') }}
                </div>
              </div>
              <i class="small times icon" @click="initFilteredRange()"></i>
            </div>
          </template>
        </v-date-picker>
        <div class="ui left icon input filter-menu-filters-item" id="filter_title">
          <input class="title" type="text" :placeholder="t('project.projects.filter_video_title')" @input="getDatasFiltered()" id="filter_title_content">
          <i class="search icon"></i>
        </div>
        <div class="ui search compact selection dropdown filter-menu-filters-item" id="filter_languages">
          <input type="hidden" name="filter_languages" @change="getDatasFiltered()">
          <i class="dropdown icon"></i>
          <div class="default text">{{ t('project.projects.filter_languages') }}</div>
          <div class="menu">
            <div class="item" v-for="(l, i) in languagesUsed" :key="i" :data-value="l.id">{{ l.name }}</div>
          </div>
        </div>
        <div class="ui search compact selection dropdown filter-menu-filters-item" id="filter_subtitles">
          <input type="hidden" name="filter_subtitles" @change="getDatasFiltered()">
          <i class="dropdown icon"></i>
          <div class="default text">{{ t('project.projects.filter_subtitles') }}</div>
          <div class="menu">
            <div 
              class="item" v-for="(s, i) in subtitlesList" 
              :key="i" 
              :data-value="s.id"
              :title="s.title">
              {{ truncateString(s.title) }}
            </div>
          </div>
        </div>
        <div class="ui search compact selection dropdown filter-menu-filters-item" id="filter_projects">
          <input type="hidden" name="filter_projects" @change="getDatasFiltered()">
          <i class="dropdown icon"></i>
          <div class="default text">{{ t('project.projects.filter_projects') }}</div>
          <div class="menu">
            <div 
              class="item" v-for="(project) in projectsForFilter" 
              :key="project.name" 
              :data-value="project.name"
            >
              {{ truncateString(project.name) }}
            </div>
          </div>
        </div>
      </div>
      <div class="filter-menu-actions">
        <button class="ui button" @click="initFilters()">
          <i class="icon close"></i>
          {{ t('project.projects.remove_filters') }}
        </button>
      </div>
    </div>
    <div class="ui top attached tabular menu">
      <a class="item active" data-tab="videos">{{ t('project.projects.projects_tab.title') }} <span class="ui circular label">{{ numberOfVideos }}</span></a>
      <a class="item" data-tab="incrustations">{{ t('project.projects.incrustations_tab.title') }} <span class="ui circular label">{{ numberOfIncrustations }}</span></a>
    </div>
    <div>
      <CVideoList 
        class="ui bottom attached tab" 
        data-tab="videos" 
        :videos="videos"
        :total_videos="numberOfVideos"
        :current_user="current_user"
      ></CVideoList>
      <CIncrustList 
        class="ui bottom attached tab" 
        data-tab="incrustations"
        :incrustations="incrustations"
        :total_incrustations="numberOfIncrustations"
      ></CIncrustList>
    </div>
    <v-tour name="studioTour" :options="tourOptions" :steps="tourSteps">
      <template slot-scope="tour">
          <transition name="fade">
            <v-step
              v-if="tour.currentStep === index"
              v-for="(step, index) of tour.steps"
              :key="index"
              :step="step"
              :previous-step="tour.previousStep"
              :next-step="tour.nextStep"
              :stop="tour.stop"
              :is-first="tour.isFirst"
              :is-last="tour.isLast"
              :labels="tour.labels"
            >
              <template v-if="tour.currentStep === 0">
                <div slot="actions">
                  <button @click="deactivateTour()" class="v-step__button">{{ t('tour.button.exit') }}</button>
                  <button @click="tour.stop" class="v-step__button">{{ t('tour.button.understood') }}</button>
                </div>
              </template>
            </v-step>
          </transition>
        </template>
    </v-tour>
  </div>
</template>

<script>
import CVideoList from "./CVideoList"
import CIncrustList from "./CIncrustList"
import AxiosVideoService from "../../../services/video/video-index"
import AxiosIncrustationService from '../../../services/incrustation/incrustation'
import AxiosProjectService from '../../../services/project/project'
import { EventBus } from '../../../packs/application'

export default {
  props: ["current_user"],
  data() {
    return {
      launchGetDatas: null,
      videosData: [],
      incrustationsData: [],
      projectsForFilter: [],
      displayFilter: false,
      subtitlesList: [],
      filteredRange: null,
      anyModalVisible: false,
      maxTitleLength: 12,
      dataPerPage: 0,
      dataCurrentPage: 0,
      totalVideos: 10,
      totalIncrustations: 10,
      tourOptions: {
        useKeyboardNavigation: false,
      },
      tourSteps: [
        {
          content: ''
        },
      ],
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
    locale() {
      return this.$store.getters['locales/locale']
    },
    languages() {
      return this.$store.getters['projects/languages']
    },
    languagesUsed() {
      return this.$store.getters['projects/languages_used']
    },
    videos() {
      return this.$store.getters['projects/videos']
    },
    numberOfVideos() {
      return this.$store.getters['projects/number_of_videos']
    },
    incrustations() {
      return this.$store.getters['projects/incrustations']
    },
    numberOfIncrustations() {
      return this.$store.getters['projects/number_of_incrustations']
    },
  },
  methods: {
    truncateString(stringData) {
      return stringData.length > this.maxTitleLength ? 
      stringData.substr(0, 6) + '...' + stringData.substr(stringData.length-6, stringData.length) : 
      stringData;
    },
    getProjectsForFilter() {
      AxiosProjectService.getProjects()
      .then(response => {
        this.projectsForFilter = response.data;
      })
    },
    manageDisplayFilters() {
      this.displayFilter = !this.displayFilter;
    },
    initFomanticElements() {
      $('.menu .item').tab();
      $('#filter_date').calendar({
        type: 'date',
        monthFirst: false,
        onChange: () => this.getDatasFiltered(),
      });
      $('#filter_languages').dropdown({
        selector: {
          menuIcon: '.chevron.icon'
        },
        clearable: true,
        forceSelection: false
      });
      $('#filter_subtitles').dropdown({
        clearable: true,
        forceSelection: false
      });
      $('#filter_projects').dropdown({
        placeholder: 'Projets',
        minCharacters: 2,
        saveRemoteData: false,
        filterRemoteData: false,
        allowAdditions: false,
        clearable: true,
        forceSelection: false,
        cache: false,
      });
    },
    initFilters() {
      this.filteredRange = null;
      $('#filter_title_content')[0].value = "";
      $('#filter_languages').dropdown('clear');
      $('#filter_subtitles').dropdown('clear');
      $('#filter_projects').dropdown('clear');
      this.getDatasFiltered();
    },
    initFilteredRange() {
      this.filteredRange = null;
    },
    getSubtitlesList() {
      this.subtitlesList = [];
      this.videosData.map(v => v.subtitles.map(s => this.subtitlesList.push(s)));
    },
    launchGetDatasFiltered() {
      this.launchGetDatas = setInterval(() => {
        this.getDatasFiltered(true)
      }, 300000);
    },
    getDatasFiltered(autoRefresh) {
      if (!autoRefresh) {
        EventBus.$emit('force-custom-page-change', 1)
        this.dataCurrentPage = 1
      }
      if(this.anyModalVisible == false) {
        EventBus.$emit("filter-changed");
        const created_at = this.filteredRange;
        const title = $('#filter_title_content')[0].value;
        const language_id = $('#filter_languages').dropdown('get value');
        const subtitle_id = $('#filter_subtitles').dropdown('get value');
        const project_id = $('#filter_projects').dropdown('get value');
        this.getVideosFiltered(created_at, title, language_id, subtitle_id, project_id, this.dataPerPage, this.dataCurrentPage);
        this.getIncrustationsFiltered(created_at, title, language_id, subtitle_id, project_id, this.dataPerPage, this.dataCurrentPage);
      }
    },
    getVideosFiltered(date, title, language, subtitle, project, perPage, currentPage) {
      if(title == "" && language == "" && subtitle == "" && project == "" && date == null){
        this.$store.dispatch('projects/getVideos', {perPage, currentPage});
        this.videosData = this.videos;
        this.totalVideos = this.numberOfVideos;
        this.getSubtitlesList();
        this.checkIfTour();
      } else {
        this.$store.dispatch('projects/getVideosSearch', {date, title, language, subtitle, project, perPage, currentPage});
        this.videosData = this.videos;
        this.totalVideos = this.numberOfVideos;
        this.getSubtitlesList();
        this.checkIfTour();
      }
    },
    getIncrustationsFiltered(date, title, language, subtitle, project, perPage, currentPage) {
      if(title == "" && language == "" && subtitle == "" && project == "" && date == null){
        this.$store.dispatch('projects/getIncrustation', {perPage, currentPage});
      } else {
        this.$store.dispatch('projects/getIncrustationSearch', {date, title, language, subtitle, project, perPage, currentPage});
      }
    },
    manageTourMessages() {
      this.tourSteps[0].content = this.t('tour.projects.first_step');
    },
    checkIfTour() {
      if(localStorage.isActiveTour == "true" && this.videosData.length > 0) this.$tours['studioTour'].start()
    },
    deactivateTour() {
      this.$tours['studioTour'].stop();
      localStorage.isActiveTour = false;
    }
  },
  mounted() {
    this.initFomanticElements();
    EventBus.$on('filter-event', () => this.manageDisplayFilters());
    EventBus.$on('update-projects', () => setTimeout(() => {
      this.getDatasFiltered()
    }, 1));
    EventBus.$on('edit-modal-closed', () => setTimeout(() => {
      this.getDatasFiltered(true);
      this.initFomanticElements();
    }, 1));
    EventBus.$on('any-modal-opened', () => this.anyModalVisible = true);
    EventBus.$on('any-modal-closed', () => this.anyModalVisible = false);
    EventBus.$on('page-change', (perPage, currentPage) => {
      this.dataPerPage = perPage;
      this.dataCurrentPage = currentPage;
      this.getProjectsForFilter();
    });
    this.$store.dispatch('projects/getUsedLanguages');

    EventBus.$emit('projects-page-ready');
    this.launchGetDatasFiltered();
    this.getDatasFiltered();
    this.manageTourMessages();
    EventBus.$on('custom-page-change', (currentPage) => {
      this.dataCurrentPage = currentPage;
      this.getDatasFiltered(true);
    })
    EventBus.$on('custom-per-page-change', (perPage) => {
      this.dataPerPage = perPage;
      this.dataCurrentPage = 1;
      this.getDatasFiltered();
    })
  },
  beforeDestroy() {
    clearInterval(this.launchGetDatas);
  },
  components: {
    CVideoList,
    CIncrustList
  }
}
</script>

<style scoped>
</style>