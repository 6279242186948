import AxiosShopService from '../../services/shop/shop-api'
import AxiosOrderService from '../../services/order/order-api'
import AxiosUserService from '../../services/user/user'

export const orderStore = {
  namespaced: true,
  state: {
    step: 1,
    stripe_session_id: null,
    stripe_key: null,
    order_id: null,
    products: [],
    quantity: 5,
    discovery_package_available: false,
    discovery_package_selected: false,
    // quantity_translation: 0,
    // discovery_translation_package_selected: false,
    translation: null,
    currency: "EUR",
    currency_symbol: "€",
    discount_code: null,
    discount_error: null,
    proofreading_options: [
      { name: 'speakers', checked: false },
      // { name: 'instructions', checked: false },
      // { name: 'layout', checked: false }
    ],
    user_informations: {
      first_name: "",
      last_name: "",
      phone: ""
    },
    company_informations: {
      account_id: "",
      company: "",
      vat: "",
      address: "",
      zip: "",
      city: "",
      country: ""
    },
    prices: {
      auto_unit_price_ht: null,
      // translation_unit_price_ht: null,
      auto_total_price_ht: null,
      // translation_total_price_ht: null,
      total_price_ht: null,
      discount: null,
      total_price_with_discount_ht: null,
      total_price_vat: null,
      total_price_ttc: null,
    },
    is_cgv_valid: false,
    discount_button_value: "Ok !"
  },
  getters: {
    products: state => {
      return state.products
    },
    is_translation: state => {
      return state.translation
    },
    order_step: state => {
      return state.step
    },
    stripe_session_id: state => {
      return state.stripe_session_id
    },
    stripe_key: state => {
      return state.stripe_key
    },
    currency: state => {
      return state.currency
    },
    currency_symbol: state => {
      return state.currency_symbol
    },
    quantity: state => {
      return state.quantity
    },
    discovery_package_available: state => {
      return state.discovery_package_available
    },
    discovery_package_selected: state => {
      return state.discovery_package_selected
    },
    proofreading_options: state => {
      return state.proofreading_options
    },
    // translationSelected: state => {
    //   return state.translation
    // },
    // quantityTranslation: state => {
    //   return state.quantity_translation
    // },
    // discovery_translation_package_selected: state => {
    //   return state.discovery_translation_package_selected
    // },
    discount_code: state => {
      return state.discount_code
    },
    discount_error: state => {
      return state.discount_error
    },
    prices: state => {
      return state.prices
    },
    user_informations: state => {
      return state.user_informations
    },
    company_informations: state => {
      return state.company_informations
    },
    is_cgv_valid: state => {
      return state.is_cgv_valid
    },
    discount_button_value: state => {
      return state.discount_button_value
    }
  },
  mutations: {
    SET_STEP(state, stepNumber) {
      state.step = stepNumber;
    },
    SET_PRODUCT(state, productChoosed) {
      if(state.products.length > 0) state.products.pop();
      state.products.push(productChoosed);
    },
    SET_STRIPE_CHECKOUT(state, isStripeCheckout) {
      state.is_stripe_checkout = isStripeCheckout;
    },
    SET_STRIPE_SESSION_ID(state, sessionId) {
      state.stripe_session_id = sessionId;
    },
    SET_STRIPE_KEY(state, key) {
      state.stripe_key = key;
    },
    SET_ORDER_ID(state, orderId) {
      state.order_id = orderId;
    },
    // SET_TRANSLATION(state, translationSelected) {
    //   state.translation = translationSelected;
    // },
    SET_QUANTITY(state, quantityChoosed) {
      state.quantity = quantityChoosed;
    },
    SET_DISCOVERY_PACKAGE_AVAILABLE(state, discoveryAvailable) {
      state.discovery_package_available = discoveryAvailable
    },
    SET_DISCOVERY_PACKAGE_SELECTED(state, discoverySelected) {
      state.discovery_package_selected = discoverySelected
    },
    // SET_QUANTITY_TRANSLATION(state, quantityChoosed) {
    //   state.quantity_translation = quantityChoosed;
    // },
    // SET_DISCOVERY_TRANSLATION_PACKAGE_SELECTED(state, discoverySelected) {
    //   state.discovery_translation_package_selected = discoverySelected
    // },
    SET_CURRENCY(state, currencyChoosed) {
      state.currency = currencyChoosed;
    },
    SET_CURRENCY_SYMBOL(state, currencySymbol) {
      state.currency_symbol = currencySymbol;
    },
    SET_DISCOUNT_CODE(state, discountCode) {
      state.discount_code = discountCode;
    },
    SET_USER_INFORMATIONS(state, userInformations) {
      state.user_informations.first_name = userInformations.first_name;
      state.user_informations.last_name = userInformations.last_name;
      state.user_informations.phone = userInformations.phone;
    },
    SET_COMPANY_INFORMATIONS(state, userInformations) {
      state.company_informations.account_id = userInformations.id;
      state.company_informations.company = userInformations.company;
      state.company_informations.vat = userInformations.vat_number;
      state.company_informations.address = userInformations.address;
      state.company_informations.zip = userInformations.zip_code;
      state.company_informations.city = userInformations.city;
      state.company_informations.country = userInformations.country;
    },
    SET_CGV(state, isCgv) {
      state.is_cgv_valid = isCgv;
    },
    UPDATE_PRICES(state, payload) {
      state.prices.auto_unit_price_ht = (payload.price_hour.cents / 100).toFixed(2);
      state.prices.translation_unit_price_ht = (payload.price_hour.cents / 100).toFixed(2);
      state.prices.auto_total_price_ht = (payload.price_ht.cents / 100).toFixed(2);
      state.prices.translation_total_price_ht = (payload.price_ht.cents / 100).toFixed(2);
      state.prices.total_price_ht = (payload.price_ht.cents / 100).toFixed(2);
      
      if(payload.hours != state.quantity) state.quantity = payload.hours;
      payload.reduction ? state.prices.discount = payload.reduction.toFixed(2) : state.prices.discount = null;
      payload.reduction ? state.discount_button_value = "Modifier" : state.discount_button_value = "Ok !";
      payload.errors ? state.discount_error = payload.errors : state.discount_error = null;
      
      state.prices.total_price_with_discount_ht = (payload.price_ht_reduction.cents / 100).toFixed(2);
      state.prices.total_price_vat = (payload.price_vat.cents / 100).toFixed(2);
      state.prices.total_price_ttc = (payload.price_ttc.cents / 100).toFixed(2);
    },
    UPDATE_USER_INFORMATIONS(state, payload) {
      state.user_informations[payload.field] = payload.value;
    },
    UPDATE_COMPANY_INFORMATIONS(state, payload) {
      state.company_informations[payload.field] = payload.value;
    },
    INIT_PRODUCTS(state) {
      state.products = [];
    },
    INIT_QUANTITY(state, quantity) {
      state.quantity = quantity;
    },
    INIT_DISCOUNT_CODE(state) {
      state.discount_code = null;
    },
    INCREMENT_STEP(state) {
      state.step += 1;
    },
  },
  actions: {
    getAmount({ state, commit }) {
      const proofreadingKey = "proofreading"
      const proofreadingProductSelected = state.products[0].key.includes(proofreadingKey)
      const options = {
        speakers: state.proofreading_options.find(option => option.name == "speakers").checked, 
        // instructions: state.proofreading_options.find(option => option.name == "instructions").checked, 
        // layout: state.proofreading_options.find(option => option.name == "layout").checked 
      }
      
      return AxiosShopService.getPrice({
        productId: state.products[0].id,
        hours: state.quantity,
        proofreading: proofreadingProductSelected,
        currency: state.currency,
        discount: state.discount_code,
        discovery: state.discovery_package_selected,
        translation: { translation: state.translation, hours: 0 },
        options
      })
      .then(response => {
        console.log(response.data);
        commit('UPDATE_PRICES', response.data);
        commit('SET_DISCOVERY_PACKAGE_AVAILABLE', response.data.discovery_auto_allowed)
      })
    },
    updateUserInformations({ state, commit }) {
      const user = {
        "first_name": state.user_informations.first_name,
        "last_name": state.user_informations.last_name,
        "phone": state.user_informations.phone,
        "account_attributes": {
          "id": state.company_informations.account_id,
          "company": state.company_informations.company,
          "vat_number": state.company_informations.vat,
          "address": state.company_informations.address,
          "zip_code": state.company_informations.zip,
          "city": state.company_informations.city,
          "country": state.company_informations.country,
        }
      } 
      AxiosUserService.updateUser(user)
      .then(response => {
        commit('INCREMENT_STEP');
      }).catch(error => {
        alert('ERROR: Please try again')
      })
    },
    postOrder({ state, commit, dispatch }) {
      const proofreadingKey = "proofreading"
      const proofreadingProductSelected = state.products[0].key.includes(proofreadingKey)
      const options = {
        speakers: state.proofreading_options.find(option => option.name == "speakers").checked, 
        // instructions: state.proofreading_options.find(option => option.name == "instructions").checked, 
        // layout: state.proofreading_options.find(option => option.name == "layout").checked 
      }

      AxiosOrderService.postOrder({
        productId: state.products[0].id,
        quantity: state.quantity,
        proofreading: proofreadingProductSelected,
        discovery: state.discovery_package_selected,
        translation: { translation: state.translation, hours: 0 },
        currency: state.currency,
        discount: state.discount_code,
        options
      }).then(response => {
        commit('SET_ORDER_ID', response.data.order_id);
        dispatch('getStripeSessionId');
      }).catch(error => {
        alert('ERROR: Please try again')
      })
    },
    getStripeSessionId({ state, commit }) {
      AxiosOrderService.postPayment(state.order_id)
      .then(response => {
        commit('SET_STRIPE_SESSION_ID', response.data.stripe_session.id);
        commit('SET_STRIPE_KEY', response.data.stripe_key);
        commit('INCREMENT_STEP');
      }).catch(error => {
        alert('ERROR: Please try again')
      })
    }
  }
};