<template>
  <div class="ui modal delete tiny" id="delete-style-modal">
    <div class="header">
      <i class="large trash alternate icon"></i>
      {{ t('authot_elements.modal.delete_style_title') }}
    </div>
    <div class="content">
      <p v-html="t('authot_elements.modal.delete_style_description', { style_name : styleName })"></p>
    </div>
    <div class="actions">
      <button class="dark_grey ui button" @click="closeDeleteModal()">{{ t('layout.shared.actions.cancel') }}</button>
      <button class="green authot ui button" @click="deleteStyle()">{{ t('layout.shared.actions.confirm') }}</button>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../packs/application';
import AxiosStyleService from '../../services/userStyle/user_style';

export default {
  props: ['current_user_id'],
  data() {
    return {
      currentUserId: this.current_user_id,
      styleId: null,
      styleName: null
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    initStyleToDelete(id, name) {
      this.styleId = id;
      this.styleName = name;
      this.openDeleteModal();
    },
    openDeleteModal() {
      $('#delete-style-modal').modal({
        inverted: true,
        closable: false
      });
      $('#delete-style-modal').modal('show');
    },
    closeDeleteModal() {
      $('#delete-style-modal').modal('hide');
    },
    deleteStyle() {
      AxiosStyleService.destroyStyle(this.currentUserId, this.styleId)
      .then(response => {
        EventBus.$emit('style-deleted');
        this.closeDeleteModal();
      }).catch(error => {

      });
    }
  },
  mounted() {
    EventBus.$on('open-delete-modal', (id, styleName) => this.initStyleToDelete(id, styleName));
  }
}
</script>