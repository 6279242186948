<template>
  <div>
    <div id="wave-timeline"></div>
    <div id="waveform"></div>
  </div>
</template>
<script>
import { EventBus } from '../../packs/application';
import WaveSurfer from 'wavesurfer.js';
import CursorPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.min.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';

export default {
  props: ["subtitles"],
  data () {
    return {
      regions: [],
      wavesurfer: null
    }
  },
  methods: {
    initRegions(subtitlesArray) {
      subtitlesArray.map((subtitle, index) => {
        this.regions.push({
          id: index,
          start: subtitle.start_time,
          end: subtitle.end_time,
          color: 'rgba(140, 139, 139, 0.25)'
        })
      });
    },
    manageUpdatedRegion(subtitlesUpdated) {
      this.regions = [];
      this.initRegions(subtitlesUpdated);
      this.wavesurfer.clearRegions();
      for(let i = 0; i < this.regions.length; i++) {
        this.wavesurfer.addRegion(this.regions[i]);
      }
    },
    manageSubtitleToPlay(subtitleId) {
      this.wavesurfer.regions.list[subtitleId].play();
    },
    managePlayedRegion(subtitleId, action) {
      if(action == 'in') {
        $('.wavesurfer-region[data-id=' + subtitleId + ']').css('background-color', 'rgba(246, 136, 7, 0.1)'); 
        $('.wavesurfer-region[data-id=' + subtitleId + ']').children().first().css({
          'border-top': '2px solid #F68807',
          'border-left': '2px solid #F68807',
          'border-bottom': '2px solid #F68807',
        });
        $('.wavesurfer-region[data-id=' + subtitleId + ']').children().last().css({
          'border-top': '2px solid #F68807',
          'border-right': '2px solid #F68807',
          'border-bottom': '2px solid #F68807',
        });
      } else {
        $('.wavesurfer-region[data-id=' + subtitleId + ']').css('background-color', 'rgba(140, 139, 139, 0.25)');
        $('.wavesurfer-region[data-id=' + subtitleId + ']').children().first().css({
          'border-top': '2px solid #8C8B8B',
          'border-left': '2px solid #8C8B8B',
          'border-bottom': '2px solid #8C8B8B',
        });
        $('.wavesurfer-region[data-id=' + subtitleId + ']').children().last().css({
          'border-top': '2px solid #8C8B8B',
          'border-right': '2px solid #8C8B8B',
          'border-bottom': '2px solid #8C8B8B',
        });
        EventBus.$emit("region-ended");
      }
    },
    manageUndefinedRegion(currentWaveTime) {
      this.regions.map(region => {
        if(region.start < currentWaveTime && region.end > currentWaveTime) {
          $('.wavesurfer-region[data-id=' + region.id + ']').css('background-color', 'rgba(246, 136, 7, 0.1)'); 
          $('.wavesurfer-region[data-id=' + region.id + ']').children().first().css({
            'border-top': '2px solid #F68807',
            'border-left': '2px solid #F68807',
            'border-bottom': '2px solid #F68807',
          });
          $('.wavesurfer-region[data-id=' + region.id + ']').children().last().css({
            'border-top': '2px solid #F68807',
            'border-right': '2px solid #F68807',
            'border-bottom': '2px solid #F68807',
          });
          EventBus.$emit('region-in', region.id);
        } else {
          $('.wavesurfer-region[data-id=' + region.id + ']').css('background-color', 'rgba(140, 139, 139, 0.25)');
          $('.wavesurfer-region[data-id=' + region.id + ']').children().first().css({
            'border-top': '2px solid #8C8B8B',
            'border-left': '2px solid #8C8B8B',
            'border-bottom': '2px solid #8C8B8B',
          });
          $('.wavesurfer-region[data-id=' + region.id + ']').children().last().css({
            'border-top': '2px solid #8C8B8B',
            'border-right': '2px solid #8C8B8B',
            'border-bottom': '2px solid #8C8B8B',
          });
        };
      });
    },
    play() {
      this.wavesurfer.playPause();
    },
    getWaveTime() {
      if(document.getElementsByClassName('subtitlesPlayer')) {
        const subDivsPlayed = Array.from(document.getElementsByClassName('subtitlesPlayer'));
        subDivsPlayed.map(subPlayed => subPlayed.remove());
      }
      EventBus.$emit('wave-time', this.wavesurfer.getCurrentTime());
      setTimeout(() => {
        if(this.wavesurfer.regions.getCurrentRegion() == null) EventBus.$emit("no-region-displayed");
      }, 1);
    },
  },
  mounted() {
    const video = document.querySelector('#videoPlayed');
    this.initRegions(this.subtitles);
    this.wavesurfer = WaveSurfer.create({
      container: document.querySelector('#waveform'),
      waveColor: '#C4C4C4',
      cursorColor: '#1BBC9B',
      cursorWidth: 2,
      progressColor:'#484E52',
      backend: 'MediaElement',
      autoCenterImmediately: true,
      plugins: [
        CursorPlugin.create({
          showTime: true,
          opacity: 1,
          color: '#1BBC9B',
          customShowTimeStyle: {
            'background-color': '#1BBC9B',
            color: '#fff',
            padding: '2px',
            'font-size': '10px'
          }
        }),
        RegionsPlugin.create({
          regions: this.regions
        }),
        TimelinePlugin.create({
          container: "#wave-timeline",
          height: 10,
          labelPadding: 2,
          primaryFontColor: "#8C8B8B",
          secondaryFontColor: "#8C8B8B",
          primaryLabelInterval: (1),
        })
      ]
    });
    this.wavesurfer.zoom(Number(50));
    this.wavesurfer.load(video);
    this.wavesurfer.on('region-update-end', (e) => {
      let regionId = e.id;
      let start = e.start;
      let end = e.end;
      this.regions[regionId].start = start;
      this.regions[regionId].end = end;
      EventBus.$emit('region-time-update', regionId, start, end);
    });
    this.wavesurfer.on('seek', () => {
      this.getWaveTime();
      this.manageUndefinedRegion(this.wavesurfer.getCurrentTime());
    });
    this.wavesurfer.on('region-in', (region) => {
      EventBus.$emit('region-in', region.id);
      this.managePlayedRegion(region.id, 'in');
    });
    this.wavesurfer.on('region-out', (region) => {
      EventBus.$emit('region-out', region.id);
      this.managePlayedRegion(region.id, 'out');
    });
    EventBus.$on('subtitle-update', (subtitlesArray) => {
      this.manageUpdatedRegion(subtitlesArray);
    });
    EventBus.$on('subtitle-to-play', (subtitleId) => {
      this.manageSubtitleToPlay(subtitleId);
    });
    EventBus.$on('video-clicked', () => {
      this.getWaveTime();
      this.manageUndefinedRegion(this.wavesurfer.getCurrentTime() + 0.1);
    });
  }
}
</script>