<template>
  <div class="ui modal tiny shortcuts" id="shortcuts-modal">
    <i class="close icon"></i>
    <div class="header shortcuts-header">
      <i class="briefcase large icon"></i>
      {{ t('editor.shortcuts.title') }}
    </div>
    <div class="content shortcuts-tabs">
      <table>
        <thead>
          <tr>
            <th colspan="2">
              <h4>{{ t('editor.shortcuts.title_player') }}</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ t('editor.shortcuts.play_video') }}</td>
            <td><span class="key">{{ t('editor.shortcuts.play_video_shortcut') }}</span> {{ t('editor.shortcuts.or') }} <span class="key">{{ t('editor.shortcuts.play_video_shortcut_2') }}</span> </td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.increase_rate') }}</td>
            <td class="key">{{ t('editor.shortcuts.increase_rate_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.decrease_rate') }}</td>
            <td class="key">{{ t('editor.shortcuts.decrease_rate_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.more_seconds') }}</td>
            <td class="key">{{ t('editor.shortcuts.more_seconds_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.less_seconds') }}</td>
            <td class="key">{{ t('editor.shortcuts.less_seconds_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.extramore_seconds') }}</td>
            <td class="key">{{ t('editor.shortcuts.extramore_seconds_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.extraless_seconds') }}</td>
            <td class="key">{{ t('editor.shortcuts.extraless_seconds_shortcut') }}</td>
          </tr>
        </tbody>
      </table>
      <table>
        <thead>
          <tr>
            <th colspan="2">
              <h4>{{ t('editor.shortcuts.title_editor') }}</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ t('editor.shortcuts.divide_cursor') }}</td>
            <td class="key">{{ t('editor.shortcuts.divide_cursor_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.previous_subtitle') }}</td>
            <td class="key">{{ t('editor.shortcuts.previous_subtitle_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.next_subtitle') }}</td>
            <td class="key">{{ t('editor.shortcuts.next_subtitle_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.start_timecode') }}</td>
            <td class="key">{{ t('editor.shortcuts.start_timecode_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.end_timecode') }}</td>
            <td class="key">{{ t('editor.shortcuts.end_timecode_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.open_delete_modal') }}</td>
            <td class="key">{{ t('editor.shortcuts.open_delete_modal_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.duplicate_subtitle') }}</td>
            <td class="key">{{ t('editor.shortcuts.duplicate_subtitle_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.play_first_mms') }}</td>
            <td class="key">{{ t('editor.shortcuts.play_first_mms_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.play_last_mms') }}</td>
            <td class="key">{{ t('editor.shortcuts.play_last_mms_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.play_subtitle') }}</td>
            <td class="key">{{ t('editor.shortcuts.play_subtitle_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.goto_start_subtitle') }}</td>
            <td class="key">{{ t('editor.shortcuts.goto_start_subtitle_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.goto_end_subtitle') }}</td>
            <td class="key">{{ t('editor.shortcuts.goto_end_subtitle_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.valid_delete') }}</td>
            <td class="key">{{ t('editor.shortcuts.valid_delete_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.divide_cursor_next') }}</td>
            <td class="key">{{ t('editor.shortcuts.divide_cursor_next_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.add_subtitle_before') }}</td>
            <td class="key">{{ t('editor.shortcuts.add_subtitle_before_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.add_subtitle_after') }}</td>
            <td class="key">{{ t('editor.shortcuts.add_subtitle_after_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.merge_subtitles') }}</td>
            <td class="key">{{ t('editor.shortcuts.merge_subtitles_shortcut') }}</td>
          </tr>
          <tr>
            <td>{{ t('editor.shortcuts.save') }}</td>
            <td class="key">{{ t('editor.shortcuts.save_shortcut') }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    openModal() {
      $('#shortcuts-modal').modal('show');
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  mounted() {
    $('#shortcuts-modal').modal({
      inverted: true,
      closable: false
    });
  }
}
</script>