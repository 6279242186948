<template>
  <div class="validation-container">
    <h2>{{ t('order.payment.title') }}</h2>
    
    <div class="validation-container-part">
      <div class="validation-container-part-header">
        <h5>{{ t('order.payment.subtitle_order') }}</h5>
        <span class="modify-link" @click="redirectToStep(1)">{{ t('order.payment.modify') }}</span>
      </div>
      <div v-for="(product, i) in products" :key="i">
        {{ product.name }} 
        <span class="orange text">
          x {{ quantitySelected }}
          <span v-if="quantitySelected == 1">{{ t('order.quantity.hour') }}</span>
          <span v-else>{{ t('order.quantity.hours') }}</span>
        </span>
      </div>
      <div 
        class="validation-container-part-options"
        v-if="products[0].key.includes('proofreading')"
      >
        <span
          v-for="option in proofreadingOptions"
          :key="`option-${option.name}`"
          v-if="option.checked"
        >
          + {{ t(`order.service.${products[0].key }_options.${option.name}`) }}
      </span>
      </div>
    </div>
    
    <div class="validation-container-part">
      <div class="validation-container-part-header">
        <h5>{{ t('order.payment.subtitle_billing') }}</h5>
        <span class="modify-link" @click="redirectToStep(3)">{{ t('order.payment.modify') }}</span>
      </div>
      <div class="validation-container-part-content">
        <span class="company">{{ companyInfo.company }}</span>
        <span>{{ companyInfo.address }}</span>
        <div>
          <span>{{ companyInfo.zip }}</span>
          <span v-if="companyInfo.zip && companyInfo.city">,</span>
          <span>{{ companyInfo.city }}</span>
        </div>
        <span class="country">{{ companyInfo.country }}</span>
      </div>
    </div>
    
    <div class="validation-container-part">
      <div class="validation-container-part-header">
        <h5>{{ t('order.payment.subtitle_legals') }}</h5>
      </div>
      <div class="ui checkbox">
        <input type="checkbox" id="cgv-input" v-model="isCgvValid">
          <label for="cgv-input" class="form label cgv-label">
            <a class="orange-link" href="https://authot.app/Authot_CGV_fr.pdf" target="_blank">
              {{ t('order.payment.legal_link') }}
            </a> 
            <CTooltip 
              :text="t('order.payment.legal_description')"
              :isLargeContent="true"
            >
              {{ t('order.payment.legal_label') }}
            </CTooltip>
            <div class="required">
              <span>*</span>
            </div>
          </label>
      </div>
    </div>
  </div>
</template>

<script>
import CTooltip from "../authotElements/CTooltip.vue"

export default {
  computed: {
    products() {
      return this.$store.getters['order/products']
    },
    quantitySelected() {
      return this.$store.getters['order/quantity'];
    },
    proofreadingOptions() {
      return this.$store.getters['order/proofreading_options']
    },
    companyInfo() {
      return this.$store.getters['order/company_informations'];
    },
    isCgvValid: {
      get() {
        return this.$store.getters['order/is_cgv_valid'];
      },
      set(value) {
        this.$store.commit('order/SET_CGV', value);
      }
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    redirectToStep(step) {
      this.$store.commit('order/SET_STEP', step);
    }
  },
  components: {
    CTooltip
  }
}
</script>