<template>
  <div class="authot-separator">
    <p class="authot-separator-text">
      {{ text }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    text: { type: String, required: true }
  }
}
</script>