<template>
  <div class="demonstration">    
    <div class="demonstration_container">
      <div class="demonstration_explication">
        <h2>{{t('ticket.demonstration.title')}}</h2>
        <p>
          {{t('ticket.demonstration.brief')}}
        </p>
      </div>      
      <form v-on:submit.prevent="verifyCaptchaValidation()" action="/ticket/demonstration" method="post" class="demonstration_form"  id="userNewForm">
      <!-- <form v-on:submit.prevent="verifyCaptchaValidation()" action="/users" method="post" class="ui form" id="userNewForm"> -->

        <div class="demonstration_form_field">
          <label for="email">Email<span class="redStar"> * </span></label>
          <input type="email" name="email" required :placeholder="t('ticket.demonstration.placeholder.email')" v-model="email"/>
        </div>        
        <div class="demonstration_form_name">
          <div class="demonstration_form_name_field">
            <label for="firstName">{{t('ticket.demonstration.firstname')}}</label>
            <input type="text" name="firstName" :placeholder="t('ticket.demonstration.placeholder.firstname')" v-model="firstName"/>
          </div>
          <div class="demonstration_form_name_field">
            <label for="lastName">{{t('ticket.demonstration.lastname')}}</label>
            <input type="text" name="lastName" :placeholder="t('ticket.demonstration.placeholder.lastname')" v-model="lastName"/>
          </div>          
        </div>  
        <div class="demonstration_form_field">
          <label for="company">{{t('ticket.demonstration.company')}}</label>
          <input type="text" name="company" :placeholder="t('ticket.demonstration.placeholder.company')" v-model="company"/>
        </div>     
        <div class="demonstration_form_field">
          <label for="phone">{{t('ticket.demonstration.phone')}}</label>
          <input type="tel" name="phone" :placeholder="t('ticket.demonstration.placeholder.phone')" v-model="phone"/>
        </div>
        <div class="demonstration_form_field">
          <label for="country">{{t('ticket.demonstration.country')}}</label>
          <select name="country" v-model="country">
            <option value="">{{t('ticket.demonstration.country-value.select')}}</option>
            <option value="FR">{{t('ticket.demonstration.country-value.france')}}</option>
            <option value="GB">{{t('ticket.demonstration.country-value.angleterre')}}</option>
            <option value="US">{{t('ticket.demonstration.country-value.etatsunis')}}</option>
            <option v-for="(country, i) in countries" :key="i" class="item" :value="country.code">{{ country.name }}</option>
          </select>
        </div>
        <div required class="demonstration_form_field">
          <label for="reason">{{t('ticket.demonstration.reason')}}<span class="redStar"> * </span></label>
          <select name="reason" v-model="reason" required>
            <option value="">{{t('ticket.demonstration.reason-value.select')}}</option>
            <option value="J’ai un besoin urgent de sous-titrage pour mes vidéos">{{t('ticket.demonstration.reason-value.value1')}}</option>
            <option value="Je me renseigne sur une solution pour sous-titrer régulièrement des vidéos">{{t('ticket.demonstration.reason-value.value2')}}</option>
            <option value="Je cherche une solution de sous-titrage automatique à intégrer">{{t('ticket.demonstration.reason-value.value3')}}</option>
            <option value="J’ai des besoins de sous-titrage sans erreur (avec de la relecture humaine)">{{t('ticket.demonstration.reason-value.value4')}}</option>
            <option value="Je suis curieux(se) de cette technologie et souhaite voir la qualité de votre système">{{t('ticket.demonstration.reason-value.value5')}}</option>
            <option value="Je cherche un outil complet de sous-titrage (traduction, synchronisation, incrustation…)">{{t('ticket.demonstration.reason-value.value6')}}</option>
          </select>
        </div>
        <div class="demonstration_form_field">
          <label for="message">{{t('ticket.demonstration.message')}}<span class="redStar"> * </span></label>
          <textarea required name="message" :placeholder="t('ticket.demonstration.placeholder.message')" v-model="message"></textarea>
        </div>
        <div>
          <vue-recaptcha
            @verify="verifyCaptcha($event)"
            @expired="expireCaptcha()"
            :language="locale"
            :loadRecaptchaScript="true"
            :sitekey="siteKey">
          </vue-recaptcha>
          <input type="hidden" name="g-recaptcha-response" :value="captchaData">
        </div>
        <div class="">
          <input type="submit" class="ui button centered submitButton"/>
        </div>
        <p><span class="redStar"> * </span>Champ obligatoire</p>
      </form>      
    </div>
    <div class="demonstration_img">
      <img src="../../assets/img/authot_studio_draw.png" class="authot_studio_draw"/>
    </div>
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import jsonCountries from '../../assets/json/countries.json'

export default {
  data(){
    return {
      email: '',
      firstName: '',
      lastName: '',
      company: '',
      phone: '',
      country: '',
      reason: '',
      message: '',
      siteKey: "6LczPEUaAAAAALhm0KvS06sqWbbX3hTjZsf9UgBA",
      countries: jsonCountries,
      captchaData: null
    }
  },
  computed: {
    t() {
      return this.$store.getters["locales/t"]
    },
    locale() {
      return this.$store.getters['locales/locale']
    }
  },
  methods: {
    verifyCaptcha(response) {
      if(response) this.captchaData = response;
    },
    expireCaptcha() {
      this.captchaData = null;
    },
    verifyCaptchaValidation() {
      const form = document.getElementById("userNewForm");
      if(this.captchaData != null) {
        this.submitForm()
      } else {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('user.sign_up.recaptcha_fill'),
          class : 'ui toast authot',
        });
      }
    },
    submitForm(){
      console.log("pass here")
      this.$store.dispatch("demonstration/demonstrationPostForm", {ticket: {
          email: this.email,
          country: this.country,                
          subject: this.reason,
          message: this.message
        },        
        firstName: this.firstName,
        lastName: this.lastName,
        company: this.company,
        phone: this.phone,
        "g-recaptcha-response": this.captchaData
      })
    }
  },
  components: {
    VueRecaptcha
  }
}
</script>

<style>

</style>