var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-view main-padding"},[_c('CProgressBar',{attrs:{"items":[
    { step: 1,
      text: '1',
      label: _vm.t('authot_elements.progress_bar.order_service') }, 
    { step: 2,
      text: '2',
      label: _vm.t('authot_elements.progress_bar.order_quantity') },
    { step: 3,
      text: '3',
      label: _vm.t('authot_elements.progress_bar.order_info') },
    { step: 4,
      text: '4',
      label: _vm.t('authot_elements.progress_bar.order_payment') } ],"activeStep":_vm.orderStep}}),_vm._v(" "),_c('div',{staticClass:"order-view-step"},[_c('div',{staticClass:"order-view-step-left"},[_c('CProductsShop',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderStep == 1),expression:"orderStep == 1"}]}),_vm._v(" "),_c('CProductQuantity',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderStep == 2),expression:"orderStep == 2"}]}),_vm._v(" "),_c('CPaymentConfig',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderStep == 2),expression:"orderStep == 2"}]}),_vm._v(" "),_c('COrderInfo',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderStep == 3),expression:"orderStep == 3"}],attrs:{"token":_vm.token}}),_vm._v(" "),_c('COrderValidation',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderStep == 4),expression:"orderStep == 4"}]})],1),_vm._v(" "),_c('div',{staticClass:"order-view-step-right"},[_c('CProductDescription',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderStep == 1),expression:"orderStep == 1"}]}),_vm._v(" "),_c('COrderSummary',{directives:[{name:"show",rawName:"v-show",value:(_vm.orderStep == 2 || _vm.orderStep == 3 || _vm.orderStep == 4),expression:"orderStep == 2 || orderStep == 3 || orderStep == 4"}]})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }