<template>
  <div class="ui modal delete warning tiny" :id="'warning-modal-' + modalId">
    <div class="header">
      <i class="large exclamation triangle icon"></i>
      {{ modalHeader }}
    </div>
    <div class="content">
      <p v-html="modalContent"></p>
    </div>
    <div class="actions">
      <button class="dark_grey ui button" @click="abortModal()">{{ modalAbort }}</button>
      <button class="green authot ui button" @click="validModal()">{{ modalValid }}</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalId: { type: String, required: true },
    modalHeader: { type: String, required: true },
    modalContent: { type: String, required: true },
    modalAbort: { type: String, required: true },
    modalValid: { type: String, required: true },
  },
  data() {
    return {}
  },
  methods: {
    openModal() {
      $('#warning-modal-' + this.modalId).modal('show');
    },
    closeModal() {
      $('#warning-modal-' + this.modalId).modal('hide');
    },
    abortModal() {
      this.$emit('warning-modal-aborted');
      this.closeModal();
    },
    validModal() {
      this.$emit('warning-modal-validated');
      this.closeModal();
    }
  },
  mounted() {
    $('#warning-modal-' + this.modalId).modal({
      inverted: true,
      closable: false,
    });
  }
}
</script>