<template>
</template>

<script>
import { EventBus } from '../../packs/application';

export default {
  props: [],
  data() {
    return {
      preview: null,
    }
  },
  methods: {
    manageStyle(styleFound, stylePreview) {
      this.preview = document.getElementById("" + stylePreview + "");
      if(this.preview) this.preview.className = "";
      if(this.preview) this.preview.removeAttribute("style");
      this.manageFont(this.preview, styleFound.font_name, styleFound.font_size, styleFound.spacing, styleFound.primary_color, styleFound.bold, styleFound.italic)
      this.manageTextDecoration(this.preview, styleFound.underline, styleFound.strike_out);
      this.manageBorder(this.preview, styleFound.outline_color, styleFound.background_color, styleFound.border_style, styleFound.outline, styleFound.shadow);
      if(styleFound.border_style == "3") this.$nextTick(() => this.manageOpaqueBox(this.preview, styleFound.outline_color));
    },
    manageFont(element, fontName, fontSize, fontSpacing, fontColor, bold, italic) {
      if(element) {
        element.style.fontFamily = fontName;
        element.style.fontSize = fontSize + "pt";
        element.style.letterSpacing = fontSpacing + "px";
        element.style.color = "#" + fontColor;
        if(bold == -1) element.classList.add("text-bold");
        if(italic == -1) element.classList.add("text-italic");
      }
    },
    manageTextDecoration(element, underline, strikeout) {
      if(strikeout == -1 && underline == -1) element.classList.add("text-barred-underline");
      if(strikeout == -1 && underline == 0) {
        element.classList.add("text-barred");
      }
      if(underline == -1 && strikeout == 0) element.classList.add("text-underline");
    },
    manageBorder(element, outlineColor, shadowColor, borderStyle, outlineSize, shadowSize) {
      if(element) {
        element.style.setProperty("--outline-size", + outlineSize);
        element.style.setProperty("--outline-color", "#" + outlineColor);
        element.style.setProperty("--shadow-color", "#" + shadowColor);
        // element.style.setProperty("--shadow-size", shadowSize + "px");
        if(borderStyle == 1) element.classList.add("text-outline-shadow");
      }
    },
    manageOpaqueBox(element, outlineColor) {
      element.classList.add("opaque-box");
    },
  },
  created() {
    EventBus.$on('style-changed', (style, preview) => {
      this.manageStyle(style, preview);
    });
  }
}
</script>

<style lang="scss">
// .sub-span {
//   width: fit-content;
// }
// .position-top-left {
//   top: 0px;
//   left: 0px;
//   align-items: flex-start;
// }
// .position-top-middle {
//   width: 100%;
//   top: 0px;
//   left: 50%;
//   transform: translateX(-50%);
//   align-items: center;
// }
// .position-top-right {
//   top: 0px;
//   right: 0px;
//   align-items: flex-end;
// }
// .position-middle-left {
//   left: 0px;
//   top: 50%;
//   transform: translateY(-50%);
//   align-items: flex-start;
// }
// .position-middle-middle {
//   width: 100%;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   align-items: center;
// }
// .position-middle-right {
//   right: 0px;
//   top: 50%;
//   transform: translateY(-50%);
//   align-items: flex-end;
// }
// .position-bottom-left {
//   bottom: 10px;
//   left: 0px;
//   align-items: flex-start;
// }
// .position-bottom-middle {
//   width: 100%;
//   bottom: 10px;
//   left: 50%;
//   transform: translateX(-50%);
//   align-items: center;
// }
// .position-bottom-right {
//   bottom: 10px;
//   right: 0px;
//   align-items: flex-end;
// }
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.text-barred {
  text-decoration: line-through;
}
.text-underline {
  text-decoration: underline;
}
.text-barred-underline {
  text-decoration: line-through underline;
}

@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke * -1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
    }
  }
  @return $shadow;
}

@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}

$letter-border: var(--outline-size);

.text-outline-shadow {
  @include stroke(1, var(--outline-color));
}
.opaque-box {
  background-color: var(--outline-color);
}
.opaque-shadow-box {
  text-shadow: unset;
  background-color: var(--outline-color);
}
</style>