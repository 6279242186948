import axiosClient from "../axios-config";

export default {
  getOrders(userAccountId) {
    return axiosClient.get('/orders', { params: { account_id: userAccountId }});
  },
  getPdfOrder(orderId) {
    return axiosClient.get(`/orders/${orderId}`);
  },
  postOrder({ productId, quantity, proofreading, discovery, translation, currency, discount, options }) {
    return axiosClient.post('/orders', {
      order : {
        order_detail: {
          product_id: productId,
          quantity,
          proofreading,
          discovery,
          translation_infos: { discovery: translation.translation, hours: translation.hours },
          options_proofreading: options
        }
      },
      currency,
      promotion_code: discount
    });
  },
  postPayment(orderId) {
    return axiosClient.post('/orders/payement', { order_id: orderId });
  }
}