import axiosClient from "../axios-config";

export default {
  getVideosSearch(dateData, titleData, languageId, subtitleId, projectId, perPage, numberPage) {
    if(dateData == null) dateData = ""
    return axiosClient.get('/video', {
      params: {
        search: {
          videos: {
            created_at: dateData,
            title: titleData,
            language_id: languageId,
          },
          subtitles: {
            id: subtitleId
          },
          projects: {
            name: projectId,
          },
        },
        per_page: perPage,
        number_page: numberPage,
      }
    });
  },
  getVideos(perPage, numberPage) {
    return axiosClient.get('/video', {
      params: {
        per_page: perPage,
        number_page: numberPage,
      }
    });
  },
  deleteVideo(videoId) {
    return axiosClient.delete(`/video/${videoId}`);
  },
  deleteSubtitle(videoId, subtitleId) {
    return axiosClient.delete(`/video/${videoId}/subtitle/${subtitleId}`);
  },
  addSubtitle(videoId, subtitleData) {
    return axiosClient.post(`/video/${videoId}/subtitle`, { subtitle : subtitleData });
  },
  putVideo(videoId, videoData) {
    return axiosClient.put(`/video/${videoId}`, { video: videoData });
  },
  getProjects() {
    return axiosClient.get('/project');
  },
  postTranslation(videoId, originalSubtitleId, destinationLangId, cplSelected, lineSelected) {
    return axiosClient.post(`/video/${videoId}/subtitle/${originalSubtitleId}/translate`, { video_id: videoId, dest_lang: destinationLangId, cpl: cplSelected, number_lines: lineSelected })
  }
}