<template>
  <div class="ui segment credits-view">
    <h3>{{ t('user.edit.credit.title') }}</h3>
    <CUserCredit :current_user="current_user"></CUserCredit>
  </div>
</template>

<script>
import CUserCredit from '../../../components/credit/CUserCredit.vue'
export default {
  props: ["current_user"],
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  components: {
    CUserCredit
  }
}
</script>