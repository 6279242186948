<template>
  <div class="menu">
    <div class="left-menu">
      <h2>{{ t('layout.main_title.my_projects') }}</h2>
      <a href="/video/new" class="authot ui button">
        <i class="plus icon"></i>
        {{ t('layout.main_title.new_project') }}
      </a>
    </div>
    <div>
      <a class="filters ui button" @click="filterEvent" v-bind:class="{ active: filterActive }">
        <i class="sliders horizontal icon"></i>
        {{ t('project.projects.filter_title') }}
      </a>
      <i class="link redo icon" @click="updateProjects()" :title="t('project.projects.reload_projects')"></i>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../packs/application';

export default {
  data() {
    return{
      filterActive: false
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    filterEvent() {
      this.filterActive = !this.filterActive;
      EventBus.$emit('filter-event');
    },
    updateProjects() {
      EventBus.$emit('update-projects');
    }
  }
}
</script>

<style scoped>
</style>