<template>
  <div class="credits">
    <p v-html="t('user.edit.credit.description', 
      {
        videos_total: total_videos, 
        hours_total: `${formatCredit(total_duration, 'hours')}:${formatCredit(total_duration, 'minutes')}:${formatCredit(total_duration, 'seconds')}`
      })">
    </p>
    <button class="ui tiny button authot" @click="redirectToOrder()">{{ t('user.edit.credit.btn_credit') }}</button>

    <div class="credits-list">
      <div class="credits-list-card" :class="{ 'disabled' : !credit.active }" v-for="credit in credits" :key="credit.id">
        <h6 class="orange uppercase text">{{ t(`user.edit.credit.${credit.product_key}`) }}</h6>
        <div class="credits-list-card-details" :class="{ 'disabled' : !credit.active }">
          <p class="credit-header">{{ t('user.edit.credit.available_credit') }}</p>
          <div class="credit-quantity">
            <div class="credit-quantity-details">
              <p class="text bold">{{ formatCredit(credit.quantity, "hours") }}</p>
              <p class="text bold">:</p>
              <p class="text bold">{{ formatCredit(credit.quantity, "minutes") }}</p>
              <p class="text bold">:</p>
              <p class="text bold">{{ formatCredit(credit.quantity, "seconds") }}</p>
            </div>
            <div class="credit-quantity-title">
              <p class="text uppercase">{{ t('user.edit.credit.hours') }}</p>
              <p class="text uppercase">{{ t('user.edit.credit.minutes') }}</p>
              <p class="text uppercase">{{ t('user.edit.credit.seconds') }}</p>
            </div>
          </div>
          <button class="ui green tiny authot button" :class="{ 'disabled' : !credit.active }" @click="redirectToOrder()">{{ t('user.edit.credit.btn_credit') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { convertQuantityToTime } from '../../utils/functions'

export default {
  props: ["current_user"],
  computed: {
    t() {
      return this.$store.getters['locales/t']
    },
    credits() {
      return this.$store.getters['credit/all_credits']
    },
    total_videos() {
      return this.$store.getters['credit/total_videos']
    },
    total_duration() {
      return this.$store.getters['credit/total_duration']
    }
  },
  methods: {
    redirectToOrder() {
      window.location.href = "/orders/new"
    },
    formatCredit(quantity, unit) {
      return convertQuantityToTime(quantity, unit)
    }
  },
  mounted() {
    this.$store.dispatch('credit/getAllCredits', this.current_user);
  }
}
</script>