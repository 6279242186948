import I18n from '../../assets/javascript/i18n-js/index.js.erb'

export const localesStore = {
  namespaced: true,
  state: {
    locales: ["fr", "en"],
    i18n: I18n
  },
  getters: {
    t: state => {
      return state.i18n.t;
    },
    available_locales: state => {
      return state.locales;
    },
    locale: state => {
      return state.i18n.locale;
    }
  }
};