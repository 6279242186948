<template>
  <div class="main-padding ">
    <CUserSignupForm 
      :token="token" 
      :errors="errors"
      :minimum_password_length="minimum_password_length"
    ></CUserSignupForm>
  </div>
</template>

<script>
import CUserSignupForm from "../../components/user/CUserSignupForm";

export default {
  props: [
    "token",
    "errors",
    "minimum_password_length"
  ],
  components: {
    CUserSignupForm
  }
}
</script>