<template>
  <div class="summary-container">
    <div>
      <h3>{{ t('order.quantity.recap_title') }}</h3>
      <div v-for="(product, i) in productsSelected" :key="`productSelected-${i}`">
        <div class="summary-container-line">
          <span class="summary-container-line-normal">
            {{ product.name }} {{ prices.auto_unit_price_ht }}{{ selectedCurrencySymbol }} x {{ quantitySelected }}
          </span>
          <span class="summary-container-line-light">
            {{ prices.auto_total_price_ht }} {{ selectedCurrencySymbol }}
          </span>
        </div>
        <div class="summary-container-options" v-if="product.key == 'studio_proofreading'">
          <div
            class="summary-container-options-element"
            v-for="option in proofreadingOptions"
            v-if="option.checked"
          >
            <span class="summary-container-line-light">{{ t(`order.service.${product.key }_options.${option.name}`) }}</span>
            <span class="summary-container-line-light">{{ t(`order.service.${product.key }_options.${option.name}_price`) }}</span>
          </div>
        </div>
      </div>
      <div class="authot-hr"></div>
      <div class="summary-container-line total-ht">
        <span class="summary-container-line-bold">{{ t('order.quantity.total_ht') }}</span>
        <span class="summary-container-line-bold">{{ prices.total_price_ht }} {{ selectedCurrencySymbol }}</span>
      </div>
      <div class="summary-container-line" v-if="discountCode && prices.discount">
        <span class="summary-container-line-light small promo"> {{ t('order.quantity.promo_code', { code: discountCode }) }}</span>
        <span v-if="prices.discount > 0" class="summary-container-line-light small">- {{ prices.discount }} {{ selectedCurrencySymbol }}</span>
        <span v-else class="summary-container-line-light small">+ {{ prices.discount.replace('-', '') }} {{ selectedCurrencySymbol }}</span>
      </div>
      <div class="summary-container-line" v-if="discountCode && prices.discount">
        <span v-if="prices.discount > 0" class="summary-container-line-bold total-promo">{{ t('order.quantity.total_ht_promo') }}</span>
        <span v-else class="summary-container-line-bold total-promo">{{ t('order.quantity.total_ht_maj') }}</span>
        <span class="summary-container-line-bold">{{ prices.total_price_with_discount_ht }} {{ selectedCurrencySymbol }}</span>
      </div>
      <div class="summary-container-line tva">
        <span class="summary-container-line-light small">{{ t('order.quantity.vat') }}</span>
        <span class="summary-container-line-light small">{{ prices.total_price_vat }} {{ selectedCurrencySymbol }}</span>
      </div>
      <div class="summary-container-line total">
        <span class="summary-container-line-bold">{{ t('order.quantity.total_vat') }}</span>
        <span class="summary-container-line-bold">{{ prices.total_price_ttc }} {{ selectedCurrencySymbol }}</span>
      </div>
      <div v-if="orderStep != 1" class="conditions">
        <i class="info circle icon"></i>
        <div class="conditions-list">
          <div class="conditions-list-text">
            <h5>{{ t(('order.service.condition_licence_title'))}}</h5>
            <span>{{ t(('order.service.condition_licence_desc'))}}</span>
          </div>
          <div class="conditions-list-text" v-if="productsSelected[0].key.includes('studio_auto')">
            <h5>{{ t(('order.service.condition_discovery_auto_title'))}}</h5>
            <span>{{ t(('order.service.condition_discovery_auto_desc'))}}</span>
          </div>
          <!-- <div class="conditions-list-text">
            <h5>{{ t(('order.service.condition_discovery_translation_title'))}}</h5>
            <span>{{ t(('order.service.condition_discovery_translation_desc'))}}</span>
          </div> -->
        </div>
      </div>
    </div>
    <button v-if="orderStep == 2" @click="validVolume()" class="authot fluid ui button">{{ t('order.quantity.btn_next') }}</button>
    <button v-if="orderStep == 3" @click="validUserInfo()" class="authot fluid ui button">{{ t('order.info.btn_next') }}</button>
    <div v-if="orderStep == 4">
      <StripeCheckout
        ref="checkoutRef"
        :pk="stripePublishableKey"
        :session-id="stripeSessionId"
      >
      </StripeCheckout>
      <p>{{ t('order.payment.ssl') }}</p>
      <button class="authot fluid ui button" @click="redirectToPayment()" :class="{ disabled : !isCgvValid }"><i class="payment icon"></i>{{ t('order.payment.btn_next') }}</button>
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';

export default {
  computed: {
    orderStep() {
      return this.$store.getters['order/order_step'];
    },
    productsSelected() {
      return this.$store.getters['order/products'];
    },
    quantitySelected() {
      return this.$store.getters['order/quantity'];
    },
    proofreadingOptions() {
      return this.$store.getters['order/proofreading_options']
    },
    currency() {
      return this.$store.getters['order/currency'];
    },
    selectedCurrencySymbol() {
      return this.$store.getters['order/currency_symbol'];
    },
    discountCode() {
      return this.$store.getters['order/discount_code'];
    },
    prices() {
      return this.$store.getters['order/prices'];
    },
    isCgvValid() {
      return this.$store.getters['order/is_cgv_valid'];
    },
    stripeSessionId() {
      return this.$store.getters['order/stripe_session_id'];
    },
    stripePublishableKey() {
      return this.$store.getters['order/stripe_key'];
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    validVolume() {
      this.$store.dispatch('order/postOrder');
    },
    validUserInfo() {
      this.$store.dispatch('order/updateUserInformations');
    },
    redirectToPayment() {
      this.$refs.checkoutRef.redirectToCheckout();
    }
  },
  components: {
    StripeCheckout
  }
}
</script>