<template>
  <div class="authot-container config-container">
    <div class="config-container-discount">
      <h3>{{ t('order.quantity.discount_title') }}</h3>
      <div>
        <input v-if="discountCodeStore && !discountError" class="authot-input" disabled v-model="discountCode" :placeholder="t('order.quantity.discount_placeholder')" type="text">
        <input v-else class="authot-input" v-model="discountCode" :placeholder="t('order.quantity.discount_placeholder')" type="text">
        <button v-if="discountCodeStore && !discountError" id="promo_btn" class="authot ui button" @click="resetDiscountCode()">Modifier</button>
        <button v-else id="promo_btn" class="authot ui button" @click="setDiscountCode()">ok</button>
      </div>
      <span class="red text" v-if="discountCodeStore && discountError" v-html="t('order.quantity.discount_order', {code: discountCodeStore})"></span>
    </div>
    <div class="config-container-currency">
      <h3>{{ t('order.quantity.payment_type') }}</h3>
      <span>{{ t('order.quantity.payment_description') }}</span>
      <select
        v-model="selectedCurrency"
        @change="setCurrencySymbol($event)"
        class="authot-dropdown ui dropdown" 
        id="currency-dropdown"
      >
        <option 
          v-for="(currency, i) in currencies" 
          :key="`currency-${i}`"
          :data-symbol="currency.symbol"
          :value="currency.value">
          {{ currency.name }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../packs/application'

export default {
  data() {
    return {
      discountCode: null,  
      promo_input: null,
      promo_txt: "ok"   
    }
  },
  computed: {
    discountCodeStore() {
      return this.$store.getters['order/discount_code'];
    },
    discountError() {
      return this.$store.getters['order/discount_error'];
    },
    currencies() {
      return this.$store.getters['products/currencies'];
    },
    prices(){
      return this.$store.getters['order/prices']
    },
    selectedCurrency: {
      get() {
        return this.$store.getters['order/currency'];
      },
      set(value) {
        this.$store.commit('order/SET_CURRENCY', value);
        this.getOrderAmount();
      }
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    async setDiscountCode() {
      this.$store.commit('order/SET_DISCOUNT_CODE', this.discountCode);
      await this.getOrderAmount();
      console.log(this.discountError);
      if (this.discountCodeStore && this.discountError != null){
        this.resetDiscountCode("error")      
      }
    },
    resetDiscountCode(error) {
      this.discountCode = null
      if(error == "error"){
        setTimeout(() => {this.setDiscountCode()}, 3000)
      } else{
        this.setDiscountCode()
      }
    },
    async getOrderAmount() {
      await this.$store.dispatch('order/getAmount');
    },
    setCurrencySymbol(e) {
      this.$store.commit('order/SET_CURRENCY_SYMBOL', e.target.selectedOptions[0].dataset.symbol);
    }
  },
  mounted() {
    $('#currency-dropdown').dropdown();
    EventBus.$on('resetPromo', () => this.resetDiscountCode())
  }
}
</script>