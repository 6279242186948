import axiosClient from "../axios-config";

export default {
  getStyles(userId, styleName) {
    return axiosClient.get('/users/' + userId + '/user_styles', { 
      params: {
        name_style: styleName
      }
    });
  },
  postStyle(userId, styleData) {
    return axiosClient.post('/users/' + userId + '/user_styles', { user_styles: styleData });
  },
  putStyle(userId, styleId, styleData) {
    return axiosClient.put('/users/' + userId + '/user_styles/' + styleId, { user_style: styleData });
  },
  destroyStyle(userId, styleId) {
    return axiosClient.delete('/users/' + userId + '/user_styles/' + styleId);
  }
}