import AxiosCreditService from '../../services/user/credit'

export const creditStore = {
  namespaced: true,
  state: {
    credits: [],
    total_videos: null,
    total_duration: null
  },
  getters: {
    all_credits: state => {
      return state.credits;
    },
    studio_auto_credit: state => {
      return state.credits.filter(credit => credit.product_key == "studio_auto").map(({ quantity }) => quantity);
    },
    studio_proofreading_credit: state => {
      return state.credits.filter(credit => credit.product_key == "studio_proofreading").map(({ quantity }) => quantity);
    },
    studio_unilingual_credit: state => {
      return state.credits.filter(credit => credit.product_key == "studio_unilingual").map(({ quantity }) => quantity);
    },
    studio_europe_credit: state => {
      return state.credits.filter(credit => credit.product_key == "studio_europe").map(({ quantity }) => quantity);
    },
    studio_international_credit: state => {
      return state.credits.filter(credit => credit.product_key == "studio_international").map(({ quantity }) => quantity);
    },
    total_videos: state => {
      return state.total_videos;
    },
    total_duration: state => {
      return state.total_duration;
    },
  },
  mutations: {
    SET_CREDITS(state, credits) {
      credits.map(credit => state.credits.push(credit));
    },
    EMPTY_CREDITS(state) {
      state.credits = [];
    },
    SET_TOTAL_VIDEOS(state, total) {
      state.total_videos = total
    },
    SET_TOTAL_DURATION(state, total) {
      state.total_duration = total
    },
  },
  actions: {
    getAllCredits({ commit }, userId) {
      AxiosCreditService.getCredits(userId)
      .then(response => {
        commit('EMPTY_CREDITS');
        commit('SET_CREDITS', response.data.credits);
        commit('SET_TOTAL_VIDEOS', response.data.total_videos_sent);
        commit('SET_TOTAL_DURATION', response.data.total_duration_sent);
      })
    }
  }
};