<template>
  <div class="authot-progressbar-container">
    <div 
      v-for="(item, i) in items" :key="i" 
      :class="{'active-item': item.step == activeStep, 'finished-item' : item.step < activeStep}" 
      class="authot-progressbar-container-item"
    > 
      <span v-if="item.step < activeStep" class="authot-progressbar-container-item-text">
        <i class="check icon"></i>
      </span>
      <span v-else class="authot-progressbar-container-item-text">{{ item.text }}</span>
      <span class="authot-progressbar-container-item-label">{{ item.label }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: { type: Array, required: true },
    activeStep: { type: Number, required: true }
  }
}
</script>