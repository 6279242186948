<template>
  <div>
    <CStyleForm :current_user="current_user" :json_styles="json_styles" :user_styles="user_styles"></CStyleForm>
    <CStyleManager></CStyleManager>
  </div>
</template>

<script>
import CStyleForm from "../../components/editor/CStyleForm"
import CStyleManager from "../../components/style/CStyleManager"

export default {
  props: [
    "current_user",
    "json_styles",
    "user_styles"
  ],
  components: {
    CStyleForm,
    CStyleManager
  }
}
</script>