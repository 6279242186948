import AxiosUserFontsService from '../../services/userStyle/user_font'

export const userFontsStore = {
  namespaced: true,
  state: {
    fonts: [],
    fonts_used: [],
    formatted_fonts: [],
    requested_font_id: null
  },
  getters: {
    all_fonts: state => {
      return state.formatted_fonts;
    }
  },
  mutations: {
    SET_FONTS(state, fonts) {
      fonts.map(font => state.fonts.push(font));
    },
    SET_FORMATTED_FONTS(state, fonts) {
      fonts.map(font => state.formatted_fonts.push({ id: font.id, name: font.name }));
    },
    SET_REQUESTED_FONT(state, fontId) {
      state.requested_font_id = fontId;
    },
    ADD_FONT_FACE(state) {
      let isFontAlreadyUsed = state.fonts_used.some(fontId => fontId == state.requested_font_id)
      if(!isFontAlreadyUsed) {
        let fontFaceFile = document.getElementById('font-face-stylesheet');
        if(!fontFaceFile) {
          fontFaceFile = document.createElement('style');
          document.head.appendChild(fontFaceFile)
          fontFaceFile.id = 'font-face-stylesheet';
        };
        let font = state.fonts.find(font => font.id == state.requested_font_id);
        state.fonts_used.push(font.id);
        fontFaceFile.innerHTML+=`@font-face {font-family: "${font.name}"; src: url(${font.url});}`;
      }
    }
  },
  actions: {
    getFonts({ commit }, userId) {
      AxiosUserFontsService.getFonts(userId)
      .then(response => {
        commit('SET_FONTS', response.data.user_fonts);
        commit('SET_FORMATTED_FONTS', response.data.user_fonts);
      })
    }
  }
};