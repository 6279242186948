<template>
  <div class="sub-selector">
    <div class="sub-selector-header">
      <h3 :title="video_title">{{ truncateString(video_title) }}</h3>
      <CTooltip :isDynamic="true" :text="t('layout.shared.actions.copy')" :textDynamic="t('layout.shared.actions.copied')">
        <i class="clipboard outline link icon" @click="copyTitle(video_title)"></i>
      </CTooltip>
    </div>
    <div class="sub-selector-dropdown">
      <p>{{ t('editor.subtitle_selector.label') }}</p>
      <div class="ui tiny subtitles selection dropdown">
        <input type="hidden" name="gender">
        <i class="dropdown icon"></i>
        <div class="default text"></div>
        <div class="menu">
          <div 
            :class="{ 'disabled' : subtitle.state == 0 }"
            class="item"
            v-for="(subtitle, i) in subtitlesList" :key="i" 
            :data-value="subtitle.id" 
            :title="subtitle.title"
          >
            <img
              class="mini round-flag"
              :src="require(`../../assets/img/flags/${subtitle.flag_iso}.png`)"
            >
            {{ truncateString(subtitle.title) }}
          </div>
        </div>
      </div>
    </div>
    <div class="sub-selector-icons">
      <CTooltip :text="t('editor.tooltips.display_settings')">
        <i class="big link tools icon" @click="displayStyleForm()"></i>
      </CTooltip>
      <CTooltip :text="t('editor.tooltips.keyboard_shortcuts')">
        <i class="big link briefcase icon" @click="showShortcuts()"></i>
      </CTooltip>
    </div>
    <CWarningModal
      ref="warningChangeSub"
      modalId="changeSub"
      :modalHeader="t('editor.subtitle_selector.warning_title')"
      :modalContent="t('editor.subtitle_selector.warning_description')"
      :modalAbort="t('layout.shared.actions.cancel')"
      :modalValid="t('layout.shared.actions.continue')"
      @warning-modal-aborted="initSubDropdown()"
      @warning-modal-validated="redirectToSubSelected()"
    ></CWarningModal>
    <CWarningModal
      ref="warningLeaveEditor"
      modalId="changeLeaveEditor"
      :modalHeader="t('editor.subtitle_selector.warning_title')"
      :modalContent="t('editor.subtitle_selector.warning_description')"
      :modalAbort="t('layout.shared.actions.cancel')"
      :modalValid="t('layout.shared.actions.continue')"
      @warning-modal-validated="redirectToVideos()"
    ></CWarningModal>
    <PShortcuts
      ref="shortcutsModal"
    ></PShortcuts>
  </div>
</template>

<script>
import CTooltip from '../authotElements/CTooltip';
import CWarningModal from '../authotElements/CWarningModal.vue'
import PShortcuts from '../../presenters/editor/PShortcuts.vue'
import { EventBus } from '../../packs/application';

export default {
  props: [ "video", "video_title", "subtitles", "subtitle_id" ],
  data() {
    return {
      subtitlesList: this.subtitles,
      videoId: this.video,
      subtitleSelected: null,
      maxTitleLength: 20,
      copyActive: false
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    displayStyleForm() {
      EventBus.$emit('display-style-form');
    },
    truncateString(stringData) {
      return stringData.length > this.maxTitleLength ? 
      stringData.substr(0, 8) + '...' + stringData.substr(stringData.length-8, stringData.length) : 
      stringData;
    },
    copyTitle(title) {
      let copyContent = title;
      $('<input>').val(copyContent).appendTo('body').select();
      document.execCommand('copy');
      this.copyActive = true;
    },
    initSubDropdown() {
      this.subtitleSelected = null;
      $('.ui.subtitles.selection.dropdown').dropdown('set selected', this.subtitle_id)
    },
    redirectToSubSelected() {
      window.location.href = `/video/${this.videoId}/subtitle/${this.subtitleSelected}/edit`
    },
    redirectToVideos() {
      window.location.href = `/video`
    },
    showWarningLeaveEditor() {
      this.$refs.warningLeaveEditor.openModal();
    },
    showShortcuts() {
      this.$refs.shortcutsModal.openModal();
    }
  },
  mounted() {
    $('.ui.subtitles.selection.dropdown').dropdown('set selected', this.subtitle_id);
    $('.ui.subtitles.selection.dropdown').dropdown({
      action: (text, value) => {
        this.subtitleSelected = value;
        this.$refs.warningChangeSub.openModal();
      },
    });
    document.getElementById('back-videos-link').onclick = this.$refs.warningLeaveEditor.openModal;
  },
  components: {
    CTooltip,
    CWarningModal,
    PShortcuts
  }
}
</script>