<template>
  <div class="video-edit">
    <div class="editor-top">
      <div class="editor-top-elements">
        <div class="editor-top-elements-left">
          <CPlayer :filepath="filepath"></CPlayer>
        </div>
        <div class="editor-top-elements-right">
          <div class="subtitle-update-infos"><span>{{ t('editor.subtitle_selector.last_update') }} : {{ last_update }}</span></div>
          <CSubSelector
          :video="video"
          :video_title="video_title"
          :subtitles="subtitles"
          :subtitle_id="subtitle_id"
          >
          </CSubSelector>
          <div>
            <CStyle :json_styles="json_subtitles.styles"></CStyle>
            <PStyle :current_user="current_user" :json_styles="json_subtitles.styles" :user_styles="user_styles"></PStyle>
          </div>
          <CWave :subtitles="json_subtitles.subtitles" :filepath="filepath"></CWave>
        </div>
      </div>
    </div>
    <div class="editor-bottom">
      <CEditor
        :video_id="video"
        :video_title="video_title"
        :subtitle="subtitle"
        :subtitle_id="subtitle_id"
        :subtitle_title="subtitle_title"
        :json_subtitles="json_subtitles.subtitles"
        :user_styles="user_styles"
        :json_styles="json_subtitles.styles"
        :subtitles_list="subtitles"
      >
      </CEditor>
    </div>
    <v-tour name="studioTour" :options="tourOptions" :steps="tourSteps">
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-if="tour.currentStep === index"
            v-for="(step, index) of tour.steps"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <template v-if="tour.currentStep === 0">
              <div slot="actions">
                <button @click="deactivateTour()" class="v-step__button">{{ t('tour.button.end') }}</button>
              </div>
            </template>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import CPlayer from '../../components/editor/CPlayer'
import CEditor from '../../components/editor/CEditor'
import CStyle from '../../components/editor/CStyle'
import PStyle from '../../presenters/editor/PStyles'
import CWave from '../../components/editor/CWave'
import CSubSelector from '../../components/editor/CSubSelector'

export default {
  props: [
    "current_user",
    "json_subtitles",
    "video",
    "video_title",
    "subtitles",
    "subtitle",
    "subtitle_id",
    "subtitle_title",
    "last_update",
    "filepath",
    "user_styles"
  ],
  data() {
    return {
      tourOptions: {
        useKeyboardNavigation: false,
      },
      tourSteps: [
        {
          content: ''
        },
      ],
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    manageTourMessages() {
      this.tourSteps[0].content = this.t('tour.editor.first_step');
    },
    checkIfTour() {
      if(localStorage.isActiveTour == "true") this.$tours['studioTour'].start();
    },
    deactivateTour() {
      this.$tours['studioTour'].stop();
      localStorage.isActiveTour = false;
    }
  },
  mounted() {
    this.checkIfTour();
    this.manageTourMessages();
  },
  components: {
    CPlayer,
    CEditor,
    CStyle,
    PStyle,
    CWave,
    CSubSelector
  }
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>