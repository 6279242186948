<template>
  <div class="authot-tooltip-container" @click="displayDynamicText()">
    <div @mouseenter="toggleIsVisible" @mouseleave="toggleIsVisible">
      <slot />
    </div>
    <div v-show="isVisible && !isDynamicTextDisplayed" :class="`authot-tooltip-container-content ${position} ${isLargeContent ? 'large-content' : ''}` " v-html="text"></div>
    <div v-show="isVisible && isDynamicTextDisplayed" :class="`authot-tooltip-container-content ${position} ${isLargeContent ? 'large-content' : ''}`" v-html="textDynamic"></div>
  </div>
</template>

<script>
export default {
  props: {
    isDynamic: { type: Boolean, default: false },
    position: { type: String, default: "top" },
    text: { type: String, required: true},
    textDynamic: { type: String, required: false},
    isLargeContent: { type: Boolean, default: false }
  },
  data() {
    return {
      isVisible: false,
      isDynamicTextDisplayed: false
    }
  },
  methods: {
    toggleIsVisible() {
      this.isVisible = !this.isVisible;
      if(this.isVisible == false) this.isDynamicTextDisplayed = false;
    },
    displayDynamicText() {
      if(this.isDynamic) this.isDynamicTextDisplayed = true;
    }
  }
}
</script>