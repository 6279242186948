import { render, staticRenderFns } from "./VHome.vue?vue&type=template&id=f8fa06a0&scoped=true&"
import script from "./VHome.vue?vue&type=script&lang=js&"
export * from "./VHome.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8fa06a0",
  null
  
)

export default component.exports