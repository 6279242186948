<template>
  <div class="order-view main-padding">
    <CProgressBar
      :items="[
      { step: 1,
        text: '1',
        label: t('authot_elements.progress_bar.order_service') }, 
      { step: 2,
        text: '2',
        label: t('authot_elements.progress_bar.order_quantity') },
      { step: 3,
        text: '3',
        label: t('authot_elements.progress_bar.order_info') },
      { step: 4,
        text: '4',
        label: t('authot_elements.progress_bar.order_payment') }, 
      ]"
      :activeStep="orderStep"
    ></CProgressBar>
    <div class="order-view-step">
      <div class="order-view-step-left">
        <CProductsShop v-show="orderStep == 1"></CProductsShop>
        <CProductQuantity v-show="orderStep == 2"></CProductQuantity>
        <CPaymentConfig v-show="orderStep == 2"></CPaymentConfig>
        <COrderInfo v-show="orderStep == 3" :token="token"></COrderInfo>
        <COrderValidation v-show="orderStep == 4"></COrderValidation>
      </div>
      <div class="order-view-step-right">
        <CProductDescription v-show="orderStep == 1"></CProductDescription>
        <COrderSummary v-show="orderStep == 2 || orderStep == 3 || orderStep == 4"></COrderSummary>
      </div>
    </div>
  </div>
</template>

<script>
import CProgressBar from '../../components/authotElements/CProgressBar.vue'
import CProductsShop from '../../components/order/CProductsShop.vue'
import CProductDescription from '../../components/order/CProductDescription.vue'
import CProductQuantity from '../../components/order/CProductQuantity.vue'
import CPaymentConfig from '../../components/order/CPaymentConfig.vue'
import COrderSummary from '../../components/order/COrderSummary.vue'
import COrderInfo from '../../components/order/COrderInfo.vue'
import COrderValidation from '../../components/order/COrderValidation.vue'

export default {
  props: ["current_user", "current_user_account", "token"],
  computed: {
    orderStep() {
      return this.$store.getters['order/order_step']
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  mounted() {
    this.$store.commit('order/SET_USER_INFORMATIONS', this.current_user, );
    this.$store.commit('order/SET_COMPANY_INFORMATIONS', this.current_user_account);
  },
  destroyed() {
    this.$store.commit('order/SET_STEP', 1);
    this.$store.commit('order/INIT_PRODUCTS');
    this.$store.commit('order/INIT_QUANTITY', 5);
    this.$store.commit('order/INIT_DISCOUNT_CODE');
  },
  components: {
    CProgressBar,
    CProductsShop,
    CProductDescription,
    CProductQuantity,
    CPaymentConfig,
    COrderSummary,
    COrderInfo,
    COrderValidation
  }
}
</script>