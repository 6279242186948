import axiosClient from "../axios-config";

export default {
  putVideo(videoId, subtitleId, videoData) {
    return axiosClient.put('/video/' + videoId + '/subtitle/' + subtitleId, { subtitle: videoData } );
  },
  export(videoId, subtitleId) {
    return axiosClient.get('/video/' + videoId + '/subtitle/' + subtitleId + '.ass');
  },
  validSubtitle(videoId, subtitleId) {
    return axiosClient.patch(`/video/${videoId}/subtitle/${subtitleId}/valid`, { subtitle_id : subtitleId });
  }
}