<template>
  <div class="description">
    <div class="description-content" v-if="products.length === 0">
      <h3>{{ t('order.service.title_service_not_selected') }}</h3>
      <p>{{ t('order.service.description_service_not_selected') }}</p>
    </div>
    <div class="description-content" v-for="product,i in products" :key="i">
      <h3>{{ product.name }}</h3>
      <span 
        class="description-content-text"
        v-html="t(`order.service.${product.key}_description`)"
      >
      </span>
    </div>
    <button class="authot ui fluid button" @click="validProduct()" :class="{ disabled : products.length === 0 }">
      {{ t('order.service.btn_next') }}
    </button>
  </div>
</template>

<script>
export default {
  computed: {
    products() {
      return this.$store.getters['order/products']
    },
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    validProduct() {
      this.$store.commit('order/INCREMENT_STEP');
    }
  }
}
</script>