<template>
  <div class="user-style-container">
    <div v-show="mainStylePage">
      <CStylesHeader :current_user="current_user"></CStylesHeader>
      <CStylesList :current_user="current_user"></CStylesList>
    </div>
    <div v-show="editStylePage" class="ui segment style">
      <CUserStyleForm :current_user="current_user.id" :isVisible="editStylePage"></CUserStyleForm>
      <CStyleManager></CStyleManager>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../packs/application'

import CStylesList from '../../components/style/CStylesList'
import CStylesHeader from '../../components/style/CStylesHeader'
import CUserStyleForm from '../../components/style/CUserStyleForm'
import CStyleManager from '../../components/style/CStyleManager'

export default {
  props: [
    "current_user"
  ],
  data() {
    return {
      mainStylePage: true,
      editStylePage: false
    }
  },
  methods: {
  },
  mounted() {
    EventBus.$on('open-style-form', () => {
      this.mainStylePage = false;
      this.editStylePage = true;
    });
    EventBus.$on('back-to-styles', () => {
      this.mainStylePage = true;
      this.editStylePage = false;
    })
  },
  components: {
    CStylesList,
    CStylesHeader,
    CUserStyleForm,
    CStyleManager
  }
}
</script>