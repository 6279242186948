<template>
  <div class="main-padding legals-container">
    <h2>{{ t('legals.title') }}</h2>
    
    <h4>{{ t('legals.owner_title') }}</h4>
    <p v-html="t('legals.owner_description')"></p>
    
    <h4>{{ t('legals.host_title') }}</h4>
    <p v-html="t('legals.host_description')"></p>
    
    <h4>{{ t('legals.intelectual_property_title') }}</h4>
    <p v-html="t('legals.intelectual_property_description')"></p>

    <h4>{{ t('legals.rgpd.title') }}</h4>
    <span v-html="t('legals.rgpd.content')" class="legal"></span><a v-html="t('legals.rgpd.link_text')" v-bind:href="t('legals.rgpd.link')" class="legalLink"></a>
  </div>
</template>

<script>
export default {
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
}
</script>