<template>
  <div class="ui tiny modal incrustation">
    <i class="close icon" @click="closeIncrustModal()"></i>
    <div class="header">
      <i class="big desktop icon"></i>
      {{ t('authot_elements.modal.incrust_subtitles_title') }}
    </div>
    <div class="content">
      <div v-show="incrustModalFirst" class="content-info">
        <div class="incrustation-form">
          <p>{{ t('authot_elements.modal.incrust_subtitles_description') }}</p>
          <div class="incrustation-form-subtitles-list">
            <div class="incrustation-form-subtitles-list-header" @click="displaySubtitlesDropdown()">
              <div class="incrustation-form-subtitles-list-header-container">
                <img
                  class="mini round-flag"
                  :src="require(`../../assets/img/flags/${subtitles_list[0].flag_iso}.png`)"
                >
                {{ truncateString(subtitles_list[0].title) }}
              </div>
              <i class="small chevron down icon"></i>
            </div>
            <div :class="'incrustation-form-subtitles-list-items incrustation subtitles list dropdown'">
              <div 
                class="subtitle-option"
                :class="{'selected': subtitles_list[0].id == s.id}"
                :id="'subtitle-option-' + s.id"
                v-for="(s, i) in subtitles_list"
                v-bind:key="i"
                :data-value="s.id"
                :title="s.title"
                @click="selectSubtitleItem(s.id)">
                <div class="subtitle-option-left">
                  <img
                    class="mini round-flag"
                    :src="require(`../../assets/img/flags/${s.flag_iso}.png`)"
                  >
                  {{ truncateString(s.title) }}
                </div>
                <div class="ui checkbox small-border">
                  <input type="checkbox" :checked="subtitles_list[0].id == s.id">
                  <label></label>
                </div>
              </div>
            </div>
          </div>
          
          <p>{{ t('authot_elements.modal.incrust_subtitles_description_video') }}</p>
          <p>{{ video_title }}</p>
        </div>
        <button @click="sendIncrustation()" class="authot ui button">{{ t('layout.shared.actions.incrust') }}</button>
      </div>
      <div v-show="!incrustModalFirst" class="content-info">
        <div class="incrustation-info">
          <p v-html="t('authot_elements.modal.incrust_subtitles_confirmation_part1', { video_title: video_title })"></p>
          <p v-html="t('authot_elements.modal.incrust_subtitles_confirmation_part2')"></p>
          <p v-html="t('authot_elements.modal.incrust_subtitles_confirmation_part3')"></p>
        </div>
        <div class="actions">
          <button @click="redirectToVideo()" class="authot ghost ui button" >{{ t('layout.main_title.my_projects') }}</button>
          <button @click="closeIncrustModal()" class="authot ui button">{{ t('layout.shared.actions.ok') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../packs/application';
import AxiosIncrustationService from "../../services/incrustation/incrustation";

export default {
  props: [
    "subtitles_list",
    "video_title",
    "video_id"
  ],
  data() {
    return {
      incrustModalFirst: true,
      maxTitleLength: 20
    }
  },
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    openIncrustModal() {
      $('.ui.modal.incrustation').modal({
        inverted: true,
        closable: false
      });
      $('.ui.modal.incrustation').modal('show');
      $('.ui.dropdown.incrustation.subtitles').dropdown({
        useLabels: false,
      });
    },
    closeIncrustModal() {
      $('.ui.modal.incrustation').modal('hide');
      this.incrustModalFirst = true;
    },
    truncateString(stringData) {
      return stringData.length > this.maxTitleLength ? 
      stringData.substr(0, 10) + '...' + stringData.substr(stringData.length-10, stringData.length) : 
      stringData;
    },
    displaySubtitlesDropdown() {
      const element = $('.incrustation.subtitles.list.dropdown');
      if(element.hasClass('visible')) {
        element.removeClass('visible')
      } else if($('.incrustation.subtitles.list.dropdown.visible').length != 0) {
        const dropdownElement = $('.incrustation.subtitles.list.dropdown.visible')[0];
        $(dropdownElement).removeClass('visible');
        element.addClass('visible');
      } else {
        element.addClass('visible')
      };
    },
    selectSubtitleItem(subtitleId) {
      const subtitleOption = $("#subtitle-option-" + subtitleId)[0];
      subtitleOption.className == "subtitle-option" ? subtitleOption.className = "subtitle-option selected" : subtitleOption.className = "subtitle-option";
      subtitleOption.className == "subtitle-option" ? subtitleOption.children[1].children[0].checked = false : subtitleOption.children[1].children[0].checked = true;
      this.displaySelectedSub();
    },
    displaySelectedSub() {
      const selectedSubtitle = Array.from($('.incrustation.subtitles.list.dropdown')[0].children).find(c => c.className == "subtitle-option selected");
      const displayContainer = $('.incrustation-form-subtitles-list-header-container');
      if(selectedSubtitle) {
        const subToDisplay = this.subtitles_list.find(subtitle => subtitle.id == selectedSubtitle.dataset.value);
        displayContainer.html($(`#subtitle-option-${subToDisplay.id}`).children('.subtitle-option-left').html());
      } else {
        displayContainer.html(this.t('authot_elements.dropdown.no_file'));
      }
    },
    sendIncrustation() {
      const filteredSubtitles = Array.from($('.incrustation.subtitles.list.dropdown')[0].children).filter(c => c.className == "subtitle-option selected");
      const subtitlesSelected = filteredSubtitles.map(s => s.dataset.value);
      if(subtitlesSelected.length == 0) {
        $('body').toast({
          showIcon: 'times circle outline icon big red',
          message: this.t('authot_elements.modal.select_subtitle_error'),
          class : 'ui toast authot',
        });
      } else {
        EventBus.$emit('save-subtitles');
        AxiosIncrustationService.postIncrustation(this.video_id, subtitlesSelected)
        .then(response => {
          this.incrustModalFirst = false;
        })
      }
    },
    redirectToVideo() {
      window.location.href = "/video";
    }
  },
  mounted() {
    EventBus.$on('open-incrust-modal', () => this.openIncrustModal())
  }
}
</script>