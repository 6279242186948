<template>
  <div class="successful-order-container" v-if="order_success">
    <div class="successful-order-container-recap_img">
      <img src="../../assets/img/order/successful_order.svg" alt="thank_you">
    </div>
    <div class="successful-order-container-recap">
      <h2>{{ t('order.payment_success.title') }}</h2>
      <p 
        v-html="t('order.payment_success.subtitle', 
          { quantity : convertInteger(order_success.quantity), 
            hour: order_success.quantity == 1 ? t('order.quantity.small_hour') : t('order.quantity.small_hours'), 
            product : order_success.product 
          })"
      ></p>
      <a class="dark_grey text" @click="redirectToInvoices()">{{ t('order.payment_success.see_invoices') }}</a>
      <button @click="redirectToHome()" class="authot ui button">{{ t('order.payment_success.back_authot') }}</button>
    </div>
  </div>
</template>

<script>

export default {
  props: ['order_success'],
  computed: {
    t() {
      return this.$store.getters['locales/t']
    }
  },
  methods: {
    redirectToHome() {
      window.location.href = "/"
    },
    redirectToInvoices() {
      sessionStorage.redirectToInvoices = true;
      window.location.href = "/users/edit";
    },
    convertInteger(number) {
      return number.replace(".0", "");
    }
  },
  mounted() {
  }
}
</script>