import axiosClient from "../axios-config";

export default {
  getProducts() {
    return axiosClient.get('/products');
  },
  getPrice({ productId, hours, proofreading, currency, discount, discovery, translation, options }) {
    return axiosClient.get('/orders/prices', {
      params: {
        product_id: productId,
        hours,
        proofreading,
        currency,
        promotion_code: discount,
        discovery,
        translations_infos: { discovery: translation.translation, hours: translation.hours },
        proofreading_options: options
      }
    })
  }
}