<template>
</template>

<script>
import { EventBus } from '../../packs/application';

export default {
  props: [
    "json_styles",
    "user_styles"
  ],
  data() {
    return {
      jsonStyles: this.json_styles,
      userStyles: this.user_styles,
      video: null,
      videoBox: null,
      alignmentTopRight: "9",
      alignmentTopMid: "8",
      alignmentTopLeft: "7",
      alignmentMiddleRight: "6",
      alignmentMiddleMid: "5",
      alignmentMiddleLeft: "4",
      alignmentBottomRight: "3",
      alignmentBottomMid: "2",
      alignmentBottomLeft: "1",
    }
  },
  computed: {
    fontsList() {
      return this.$store.getters['userFonts/all_fonts']
    }
  },
  methods: {
    getStyle(nameStyle, subBox) {
      this.jsonStyles.find(style => {
        if(style.name_style == nameStyle) this.manageStyle(style, subBox);
      })
    },
    manageStyle(styleFound, subBox) {
      subBox.className = "subtitlesPlayer";
      subBox.removeAttribute("style");

      subBox.setAttribute("data-position", styleFound.alignment)
      let size = this.getSubtitleSize(styleFound.font_size, subBox);
      
      this.manageSize(subBox);
      this.manageFont(subBox, styleFound.font_name, size, styleFound.spacing, styleFound.primary_color, styleFound.bold, styleFound.italic);
      this.managePosition(subBox, styleFound.alignment, styleFound.margin_l, styleFound.margin_r, styleFound.margin_v);
      this.manageTextDecoration(subBox, styleFound.underline, styleFound.strike_out);
      this.manageBorder(subBox, styleFound.outline_color, styleFound.background_color, styleFound.border_style, styleFound.outline, styleFound.shadow);
      // console.log("BORDER STYLE - ", subBox, " ", styleFound.border_style)
      if(styleFound.border_style == 3) this.$nextTick(() => this.manageOpaqueBox(subBox));
      this.$nextTick(() => this.manageSubBox(subBox, styleFound.alignment));

    },
    manageSize(element) {
      element.style.setProperty("max-width", this.video.clientWidth + "px");
      element.style.setProperty("max-height", this.video.clientHeight + "px");
    },
    manageFont(element, fontName, fontSize, fontSpacing, fontColor, bold, italic) {
      const fontChoosed = this.fontsList.find(font => font.name === fontName);
      if(fontChoosed) {
        this.$store.commit('userFonts/SET_REQUESTED_FONT', fontChoosed.id);
        this.$store.commit('userFonts/ADD_FONT_FACE');
      }

      element.style.fontFamily = fontName;
      element.style.fontSize = fontSize + "pt";
      element.style.letterSpacing = fontSpacing + "px";
      element.style.color = "#" + fontColor;
      if(bold == -1) element.classList.add("text-bold");
      if(italic == -1) element.classList.add("text-italic");
    },
    managePosition(element, position, marginLeft, marginRight, marginVertical) {
      switch(position) {
        case this.alignmentTopRight:
          element.classList.add("position-top-right");
          element.style.setProperty("padding", `${+((+this.videoBox.clientHeight - +this.video.clientHeight) / 2) + +marginVertical}px ${marginRight}px 0px ${marginLeft}px`);
          // element.style.setProperty("padding", (((this.videoBox.clientHeight - this.video.clientHeight) / 2) + marginVertical + "px") + " 10px 0px 10px");
          break;
        case this.alignmentTopMid:
          element.classList.add("position-top-middle");
          element.style.setProperty("padding", `${+((+this.videoBox.clientHeight - +this.video.clientHeight) / 2) + +marginVertical}px ${marginRight}px 0px ${marginLeft}px`);
          // element.style.setProperty("padding", (((this.videoBox.clientHeight - this.video.clientHeight) / 2) + marginVertical + "px") + " 10px 0px 10px");
          break;
        case this.alignmentTopLeft:
          element.classList.add("position-top-left");
          element.style.setProperty("padding", `${+((+this.videoBox.clientHeight - +this.video.clientHeight) / 2) + +marginVertical}px ${marginRight}px 0px ${marginLeft}px`);
          // element.style.setProperty("padding", (((this.videoBox.clientHeight - this.video.clientHeight) / 2) + marginVertical + "px") + " 10px 0px 10px");
          break;
        case this.alignmentMiddleRight:
          element.classList.add("position-middle-right");
          break;
        case this.alignmentMiddleMid:
          element.classList.add("position-middle-middle");
          break;
        case this.alignmentMiddleLeft:
          element.classList.add("position-middle-left");
          break;
        case this.alignmentBottomRight:
          element.classList.add("position-bottom-right");
          element.style.setProperty("padding", `0px ${marginRight}px ${+((+this.videoBox.clientHeight - +this.video.clientHeight) / 2) + +marginVertical}px ${marginLeft}px`);
          // element.style.setProperty("padding", "0px 10px " + (((this.videoBox.clientHeight - this.video.clientHeight) / 2) + marginVertical) + "px" + " 10px");
          break;
        case this.alignmentBottomMid:
          element.classList.add("position-bottom-middle");
          element.style.setProperty("padding", `0px ${marginRight}px ${+((+this.videoBox.clientHeight - +this.video.clientHeight) / 2) + +marginVertical}px ${marginLeft}px`);
          // element.style.setProperty("padding", "0px 10px " + (((this.videoBox.clientHeight - this.video.clientHeight) / 2) + marginVertical) + "px");
          break;
        case this.alignmentBottomLeft:
          element.classList.add("position-bottom-left");
          element.style.setProperty("padding", `0px ${marginRight}px ${+((+this.videoBox.clientHeight - +this.video.clientHeight) / 2) + +marginVertical}px ${marginLeft}px`);
          // element.style.setProperty("padding", "0px 10px " + (((this.videoBox.clientHeight - this.video.clientHeight) / 2) + marginVertical) + "px" + " 10px");
          break;
      }
    },
    manageTextDecoration(element, underline, strikeout) {
      if(strikeout == -1 && underline == -1) element.classList.add("text-barred-underline");
      if(strikeout == -1 && underline == 0) element.classList.add("text-barred");
      if(underline == -1 && strikeout == 0) element.classList.add("text-underline");
    },
    manageBorder(element, outlineColor, shadowColor, borderStyle, outlineSize, shadowSize) {
      element.style.setProperty("--outline-size", +outlineSize);
      element.style.setProperty("--outline-color", "#" + outlineColor);
      element.style.setProperty("--shadow-color", "#" + shadowColor);
      // element.style.setProperty("--shadow-size", shadowSize + "px");
      if(borderStyle == 1) element.classList.add("text-outline-shadow");
    },
    manageOpaqueBox(subBox) {
      const subSpan = Array.from(subBox.children)
      subSpan.forEach(span => span.classList.add("opaque-box"));
    },
    manageSubBox(subBox, alignment) {
      const subBoxes = Array.from(document.getElementsByClassName("subtitlesPlayer"));
      const subBoxesToCompare = subBoxes.filter(sb => sb.id != subBox.id);
      subBoxesToCompare.map(sb => {
        if(this.isElsCollide(subBox, sb) && subBox.dataset.index > sb.dataset.index) {
          let mainBoxSize = sb.getBoundingClientRect();
          alignment == this.alignmentTopRight || alignment == this.alignmentTopMid || alignment == this.alignmentTopLeft ?
            subBox.style.setProperty("padding-top", (mainBoxSize.height + 10) + "px") :
            subBox.style.setProperty("padding-bottom", (mainBoxSize.height + 10) + "px");
        };
      });
    },
    isSubPlayedActive() {
      const subBoxes = Array.from(document.getElementsByClassName("subtitlesPlayer"));
      subBoxes.forEach(subBox => {
        subBox.padding = "0px";
        this.managePosition(subBox, subBox.dataset.position);
        this.manageSubBox(subBox, subBox.dataset.position);
      });
    },
    isElsCollide(elFirst, elSecond) {
      elFirst.offsetBottom = elFirst.offsetTop + elFirst.offsetHeight;
      elFirst.offsetRight = elFirst.offsetLeft + elFirst.offsetWidth;
      elSecond.offsetBottom = elSecond.offsetTop + elSecond.offsetHeight;
      elSecond.offsetRight = elSecond.offsetLeft + elSecond.offsetWidth;
      
      return !((elFirst.offsetBottom < elSecond.offsetTop) ||
              (elFirst.offsetTop > elSecond.offsetBottom) ||
              (elFirst.offsetRight < elSecond.offsetLeft) ||
              (elFirst.offsetLeft > elSecond.offsetRight))
    },
    getSubtitleSize(subtitleFontSize, subBox) {
      const browserWidth = document.body.clientWidth;
      const videoWidth = this.video.clientWidth;
      const browserHeight = document.body.clientHeight;
      const videoHeight = this.video.clientHeight;
      subBox.style.setProperty('width', videoWidth + 'px');
      const ratio = videoWidth < videoHeight ? videoHeight / browserHeight : videoWidth / browserWidth ;
      return this.getFontSizeRatio(browserWidth, videoWidth, videoHeight, subtitleFontSize, ratio)
    },
    getFontSizeRatio(browserWidth, videoWidth, videoHeight, subtitleFontSize, ratio) {
      if(browserWidth < 1240 && videoWidth != videoHeight) {
        return subtitleFontSize * (ratio * 1.5);
      } else if (browserWidth < 1281 && videoWidth != videoHeight) {
        return subtitleFontSize * (ratio * 1.7);
      } else if(browserWidth < 1281 && videoWidth == videoHeight) {
        return subtitleFontSize * (ratio * 2.5);
      } else if(browserWidth > 1281 && videoWidth < videoHeight) {
        return subtitleFontSize * (ratio * 2);
      } else if(browserWidth > 1281 && videoWidth > videoHeight) {
        return subtitleFontSize * (ratio * 2.6);
      } else if(browserWidth > 1281 && videoWidth == videoHeight) {
        return subtitleFontSize * (ratio * 4);
      }
    }
  },
  mounted() {
    this.video = document.getElementById('videoPlayed');
    this.videoBox = document.getElementById('videoBox');
    EventBus.$on('subtitles-played', (subStyle, subBox, isMainBox) => {
      this.getStyle(subStyle, subBox);
    });
    EventBus.$on("region-ended", () => this.isSubPlayedActive());
  }
}
</script>

<style lang="scss">
.sub-span {
  width: fit-content;
  margin: 0px;
  font-size: inherit;
  color: inherit;
  line-height: normal;
}
.position-top-left {
  top: 0px;
  left: 0px;
  align-items: flex-start;
}
.position-top-middle {
  width: 100%;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  text-align: center;
}
.position-top-right {
  top: 0px;
  right: 0px;
  align-items: flex-end;
  text-align: end;
}
.position-middle-left {
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0px 10px;
  align-items: flex-start;
}
.position-middle-middle {
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 10px;
  align-items: center;
  text-align: center;
}
.position-middle-right {
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
  padding: 0px 10px;
  align-items: flex-end;
  text-align: end;
}
.position-bottom-left {
  bottom: 0px;
  left: 0px;
  align-items: flex-start;
}
.position-bottom-middle {
  width: 100%;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  align-items: center;
  text-align: center;
}
.position-bottom-right {
  bottom: 0px;
  right: 0px;
  padding: 0px 10px 10px 10px;
  align-items: flex-end;
  text-align: end;
}
.text-bold {
  font-weight: bold;
}
.text-italic {
  font-style: italic;
}
.text-barred {
  text-decoration: line-through;
}
.text-underline {
  text-decoration: underline;
}
.text-barred-underline {
  text-decoration: line-through underline;
}

@function stroke($stroke, $color) {
  $shadow: ();
  $from: $stroke * -1;
  @for $i from $from through $stroke {
    @for $j from $from through $stroke {
      $shadow: append($shadow, $i*1px $j*1px 0 $color, comma);
    }
  }
  @return $shadow;
}

@mixin stroke($stroke, $color) {
  text-shadow: stroke($stroke, $color);
}

$letter-border: var(--outline-size);

.text-outline-shadow {
  @include stroke(1, var(--outline-color));
}
.opaque-box {
  background-color: var(--outline-color);
}
.opaque-shadow-box {
  text-shadow: unset;
  background-color: var(--outline-color);
}
</style>