var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"video-new-product_selector"},[_c('div',{staticClass:"video-new-product_selector-header"},[_c('h2',[_vm._v(_vm._s(_vm.t('project.new_project.products_title')))]),_vm._v(" "),_c('h4',[_vm._v(_vm._s(_vm.t('project.new_project.products_title_step_1')))])]),_vm._v(" "),_c('div',{staticClass:"video-new-product_selector-products"},[_c('CTooltip',{attrs:{"text":_vm.t('project.new_project.studio_manual.product_description'),"isLargeContent":true}},[_c('div',{staticClass:"product-card",class:{
          'selected' : _vm.productSelected != null && _vm.productSelected.key == 'studio_manual',
          'hovered' : _vm.uploadProductHovered 
        },on:{"click":function($event){return _vm.selectProduct('studio_manual', null, 'Sélection manuelle')},"mouseover":function($event){_vm.uploadProductHovered = true},"mouseleave":function($event){_vm.uploadProductHovered = false}}},[(_vm.productSelected != null && _vm.productSelected.key == 'studio_manual' ||_vm.uploadProductHovered)?_c('img',{attrs:{"src":require("../../../assets/img/products/studio_manual-selected.svg"),"alt":""}}):_c('img',{attrs:{"src":require("../../../assets/img/products/studio_manual-not-selected.svg"),"alt":""}}),_vm._v("\n        "+_vm._s(_vm.t('project.new_project.studio_manual.btn_add_sub'))+"\n      ")])]),_vm._v(" "),_vm._l((_vm.products),function(product,i){return _c('div',{key:("product-" + i)},[_c('CTooltip',{attrs:{"text":_vm.t('project.new_project.studio_auto.product_description'),"isLargeContent":true}},[(product.position == 1)?_c('div',{staticClass:"product-card",class:{ 
            'disabled' : !product.active,
            'selected' : _vm.productSelected != null && _vm.productSelected.key == product.key,
            'hovered' : _vm.autoProductHovered
          },on:{"mouseover":function($event){_vm.autoProductHovered = true},"mouseleave":function($event){_vm.autoProductHovered = false},"click":function($event){return _vm.selectProduct(product.key, product.id, product.name)}}},[(!product.active)?_c('img',{attrs:{"src":require(("../../../assets/img/products/" + (product.key) + "-unavailable.svg")),"alt":""}}):_vm._e(),_vm._v(" "),(_vm.productSelected != null && _vm.productSelected.key == product.key || _vm.autoProductHovered)?_c('img',{attrs:{"src":require(("../../../assets/img/products/" + (product.key) + "-selected.svg")),"alt":""}}):_c('img',{attrs:{"src":require(("../../../assets/img/products/" + (product.key) + "-not-selected.svg")),"alt":""}}),_vm._v("\n          "+_vm._s(product.name)+"\n          "),(!product.active)?_c('span',{staticClass:"unavailable"},[_vm._v(_vm._s(_vm.t('order.service.product_soon_available')))]):_vm._e()]):_vm._e()]),_vm._v(" "),_c('CTooltip',{attrs:{"text":_vm.t('project.new_project.studio_proofreading.product_description'),"isLargeContent":true}},[(product.position == 2)?_c('div',{staticClass:"product-card",class:{ 
            'disabled' : !product.active,
            'selected' : _vm.productSelected != null && _vm.productSelected.key == product.key,
            'hovered' : _vm.proofreadingProductHovered
          },on:{"mouseover":function($event){_vm.proofreadingProductHovered = true},"mouseleave":function($event){_vm.proofreadingProductHovered = false},"click":function($event){return _vm.selectProduct(product.key, product.id, product.name)}}},[(!product.active)?_c('img',{attrs:{"src":require(("../../../assets/img/products/" + (product.key) + "-unavailable.svg")),"alt":""}}):_vm._e(),_vm._v(" "),(_vm.productSelected != null && _vm.productSelected.key == product.key || _vm.proofreadingProductHovered)?_c('img',{attrs:{"src":require(("../../../assets/img/products/" + (product.key) + "-selected.svg")),"alt":""}}):_c('img',{attrs:{"src":require(("../../../assets/img/products/" + (product.key) + "-not-selected.svg")),"alt":""}}),_vm._v("\n          "+_vm._s(product.name)+"\n          "),(!product.active)?_c('span',{staticClass:"unavailable"},[_vm._v(_vm._s(_vm.t('order.service.product_soon_available')))]):_vm._e()]):_vm._e()])],1)})],2),_vm._v(" "),_c('button',{staticClass:"ui green button",class:{ 'disabled' : !_vm.productSelected },on:{"click":function($event){return _vm.nextProjectStep()}}},[_vm._v(_vm._s(_vm.t('layout.shared.actions.next')))]),_vm._v(" "),_c('CCreditErrorModal',{ref:"creditErrorModal"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }